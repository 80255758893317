/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import m from 'mithril';
import apirequest from '../../../utils/api-service';
import { getItemLocalStorage, setToLocalStorage } from '../../../utils/helper';

export default {
  oninit({ state, attrs }) {
    const stations = getItemLocalStorage('stations');
    state.data = stations || [];
    state.mapId = Math.random()
      .toString()
      .split('.')[1];
  },
  oncreate({ state, attrs }) {
    $('#mySelect2').select2();
    state.infoWindow = undefined;
    state.loading = true;
    state.markerMap = {};
    state.updateState = () => {
      const points = state.data
        // .sort((a, b) => Number(b.DISTANCE) - Number(a.DISTANCE))
        .map(station => [station, station.STSN_COORDINATES])
        .map(
          (
            [meta, [{ LATI: lat, LONG: long }]],
          ) => ({ lat, long, meta }),
        );

      state.selectedStation = {};

      attrs.gpsData.map(x => points.push(...x));

      state.map = new window.google.maps.Map(document.getElementById(state.mapId), {
        zoom: 7,
        center: points[points.length - 1]
          ? new google.maps.LatLng(
            points[points.length - 1].lat,
            points[points.length - 1].long,
          )
          : null,
      });

      points.map((point) => {
        const { lat, long: lng, meta } = point;
        // Create a custom marker icon
        const icon = {
          url: 'assets/media/illustrations/rubis-markers.png', // replace with your custom icon URL
          scaledSize: new google.maps.Size(25, 35), // adjust the size to fit your icon
        };

        // Create the marker
        state.markerMap[meta.STATION_ID] = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          map: state.map,
          icon,
          title: meta.STATION_NAME,
        });

        // Create the info window content
        const contentString = `
                <div style='width:160px;height:125px;'>
                ${meta.STSN_AMENITIES.map((amenity) => {
          if (amenity.AMENI_NAME.toLowerCase().includes('accept') || amenity.AMENI_NAME.toLowerCase().includes('test')) {
            return `<br><div style="width: 160px;"><strong>${amenity.AMENI_NAME}</strong></div>`;
          }
          const price = Math.floor(Math.random() * 800) + 200; // generate a random price above 200
          return `<div style="width: 160px;"><strong>${amenity.AMENI_NAME}</strong></div>`;
        }).join('')
          }
            <p>
            <br>
              <a href="https://maps.google.com/maps?saddr=&daddr=${lat},${lng}" target="_blank">
                Get Directions ->
              </a>
            </p>
          </div>`;

        // Add a click event listener to the marker
        state.markerMap[meta.STATION_ID].addListener('click', () => {
          // Clear the previous info window content
          if (state.infoWindow) {
            state.infoWindow.close();
          }

          const headerContent = document.createElement('div');
          headerContent.innerHTML = `<h5>${meta.STATION_NAME}</h5>`;

          // Create a new info window for this marker
          state.infoWindow = new google.maps.InfoWindow({
            content: contentString,
            headerContent,
          });


          // Open the new info window
          state.infoWindow.open(state.map, state.markerMap[meta.STATION_ID]);

          // Pan the map to the current marker position
          state.map.panTo(state.markerMap[meta.STATION_ID].getPosition());
          state.map.setZoom(12);

          // Call the markerSelected callback if defined
          if (attrs.markerSelected) {
            attrs.markerSelected(meta);
          }
        });
      });
      state.loading = false;
      m.redraw();
    }
    state.fetchstations = async () => {
      try {
        state.loading = true;
        const {
          respbody,
          respcode
        } = await apirequest(`/RubisMobileApiController/getStations`, 'POST', { "CLIENT_ID": "SDFGRETRE" });
        if (respcode === 0) {
          const data = respbody?.STATIONS
          .filter(station => !!station.STSN_COORDINATES && station.STSN_COORDINATES.length > 0);
          state.data = data;
          setToLocalStorage('stations', data);
        }
        state.updateState();
        state.loading = false;
        m.redraw()
        return;
      } catch (error) {
        state.loading = false;
        m.redraw()
        console.log(error);
      }
    };

    state.handelSelection = () => {
      // Clear the previous info window content
      if (state.infoWindow) {
        state.infoWindow.close();
      }
      const meta = state.data.find(station => station.STATION_ID === state.selectedStation.id)
      const lat = meta.STSN_COORDINATES[0].LATI;
      const lng = meta.STSN_COORDINATES[0].LONG;

      const contentString = `
        <div style='width:160px;height:125px;'>
        ${meta.STSN_AMENITIES.map((amenity) => {
        if (amenity.AMENI_NAME.toLowerCase().includes('accept') || amenity.AMENI_NAME.toLowerCase().includes('test')) {
          return `<br><div style="width: 160px;"><strong>${amenity.AMENI_NAME}</strong></div>`;
        }
        return `<div style="width: 160px;"><strong>${amenity.AMENI_NAME}</strong></div>`;
      }).join('')
        }
          <p>
          <br>
            <a href="https://maps.google.com/maps?saddr=&daddr=${lat},${lng}" target="_blank">
              Get Directions ->
            </a>
          </p>
        </div>`;

      const headerContent = document.createElement('div');
      headerContent.innerHTML = `<h5>${state.selectedStation.text}</h5>`;

      // Create a new info window for this marker
      state.infoWindow = new google.maps.InfoWindow({
        content: contentString,
        headerContent,
      });


      // Open the new info window
      state.infoWindow.open(state.map, state.markerMap[state.selectedStation.id]);

      // Pan the map to the current marker position
      state.map.panTo(state.markerMap[state.selectedStation.id].getPosition());
      state.map.setZoom(12);

      // Call the markerSelected callback if defined
      if (attrs.markerSelected) {
        attrs.markerSelected(meta);
      }
    }
    if (state.data && state.data.length){
      state.updateState();
    } else {
      state.fetchstations();
    }
  },
  view({ state, attrs }) {
    return m('div', {}, [
      m('div', { class: 'rounded-0', style:{
        "padding-bottom":'20px'
      } }, [

        m('select',
          {
            class: 'form-select rounded-0',
            'data-control': 'select2', id: 'mySelect2',
            'data-placeholder': 'Search station',
            onchange: (e) => { state.selectedStation = $('#mySelect2').select2('data')[0]; state.handelSelection() },
          }, [
          m('option'),
          state.data?.map(station => m('option', { value: station.STATION_ID }, station.STATION_NAME)),
          // m('option', { value: '2' }, 'Option 2'),
        ]),

      ]),
      state.loading
        && m('div', { class: 'py-3 text-center text-success' }, [
          'Fetching stations... ',
          m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2 text-success' }),
        ]),
      m(
        'div',
        {
          class: 'embed-responsive embed-responsive-16by9',
          style: {
            height: attrs.long !== true ? '' : 'calc(300px - 34px)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // added inline background color
            borderRadius: '10px', // add rounded edges
            boxShadow: '0 0 30px rgba(0, 0, 0, 0.2)', // add a subtle shadow
            padding: '6px'
          },
        },
        m('div', {
          class: 'embed-responsive-item',
          id: state.mapId,
          style: {
            width: '100%',
            height: '50vh', // add this to make the map fit 100% to the provided space
            borderRadius: '10px', // add rounded edges to the inner div as well
          },
        }),
      ),
    ]);
  },
};
