/* eslint-disable no-param-reassign */
import m from 'mithril';
import modalCloseButton from '../../../components/modalCloseButton';
import { isEmailValid, getItemLocalStorage } from '../../../utils/helper';
import apirequest from '../../../utils/api-service';

const modal = {
  key: 'USER_AUTH_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state, attrs }) {
    const accountData = getItemLocalStorage('accountData') || {};
    const globalType = getItemLocalStorage('globalCardType');
    state.loading = false;
    state.menulist = [];
    console.log('rrrr', state.menulist);
    state.form_data = {
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      menulist: [],
    };
    state.form_errors = {
      firstname: false,
      lastname: false,
      username: false,
      email: false,
      resources: false,
    };
    state.form_valid = false;
    state.success = false;
    state.successMessage = '';
    state.error = false;
    state.errorMessage = '';
    state.handleInputChange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      state.handleInputValidations();
    };
    state.handleInputValidations = () => {
      state.form_errors.firstname = state.form_data.firstname === '';
      state.form_errors.lastname = state.form_data.lastname === '';
      state.form_errors.username = state.form_data.username === '';
      state.form_errors.email = !isEmailValid(state.form_data.email);
      state.form_errors.resources = state.form_data.menulist.length === 0;
      state.form_valid = !state.form_errors.firstname && !state.form_errors.lastname
       && !state.form_errors.username && !state.form_errors.email && !state.form_data.resources;
    };
    state.handleSelect = (e) => {
      const { id, checked } = e.target;
      if (id === 'all') {
        state.form_data.menulist = checked ? [...state.menulist] : [];
      } else {
        state.form_data.menulist = checked
        ? [...state.form_data.menulist, state.menulist.find(item => item.MENUID === id)]
         : [...state.form_data.menulist.filter(item => item.MENUID !== id)];
      }
      state.handleInputValidations();
    };

    state.handleSubmit = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/ClientController/userCreateAction`, 'POST', {
          LASTNAME: state.form_data.lastname,
          USERNAME: accountData.USERNAME,
          FIRSTNAME: state.form_data.firstname,
          EMAIL: state.form_data.email,
          CLIUSERNAME: state.form_data.username,
          MENULIST: state.form_data.menulist,
        });
        state.loading = false;

        if (respcode === 0) {
          state.success = true;
          state.successMessage = 'User created successfully';
          state.error = false;
          attrs.refresh();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to create a user';
      }
    };

    state.setResources = () => {
      let list = [];
      if (globalType === '@POST') {
          const menus = ['CLI_INVOICE_RPT', 'CLI_CARD_BAL', 'CLI_CARD_LIMIT', 'CLI_CARD_TOPUP', 'CLI_CARD_TO_CARD', 'CLI_CARD_TOPUP_MTN', 'CLI_CARD_LIMIT', 'CLI_CARD_BAL'];
          list = accountData.MENULIST.filter(({ MENUID }) => !menus.includes(MENUID));
      } else {
        const menus = ['CLI_INVOICE_RPT', 'CLI_CARD_BAL', 'CLI_CARD_LIMIT', 'CLI_CARD_LIMIT', 'CLI_CARD_BAL'];
        list = accountData.MENULIST.filter(({ MENUID }) => !menus.includes(MENUID));
      }
      console.log('list', list);

      state.menulist = list;
    };

    state.setResources();
  },
  view({ state }) {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog.modal-dialog-centered[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', 'Add New User'),
                    m('div', { class: 'btn btn-icon btn-sm btn-active-light-primary ms-2', 'data-bs-dismiss': 'modal', 'aria-label': 'Close' },
                      m(modalCloseButton)),
                  ]),
                m('.modal-body',
                  [
                    state.success && m('div', { class: 'text-success fs-5' }, state.successMessage),
                    m('form', [
                      m('.row', [
                        m('.col-md-6.col-sm-12 py-2', [
                          m('div.form-group.mb-2', [
                            m('label', 'First Name:'),
                            m('input.form-control[type="text"]', {
                              placeholder: 'Enter first name',
                              name: 'firstname',
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.firstname && m('div', { class: 'text-danger fs-6' }, 'First name is required'),
                          ]),
                          m('div.form-group', [
                            m('label', 'Username:'),
                            m('input.form-control[type="text"]', {
                              name: 'username',
                              placeholder: 'Enter username',
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.username && m('div', { class: 'text-danger fs-6' }, 'Username is required'),
                          ]),
                        ]),
                        m('.col-md-6.col-sm-12 py-2', [
                          m('div.form-group.mb-2', [
                            m('label', 'Last Name:'),
                            m('input.form-control[type="text"]', {
                              name: 'lastname',
                              placeholder: 'Enter last name',
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.lastname && m('div', { class: 'text-danger fs-6' }, 'Last name is required'),
                          ]),
                          m('div.form-group', [
                            m('label', 'Email:'),
                            m('input.form-control[type="email"]', {
                              name: 'email',
                              placeholder: 'Enter email',
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.email && m('div', { class: 'text-danger fs-6' }, 'Enter valid email'),
                          ]),
                        ]),
                        m('.col-md-12.col-sm-12 py-2', [
                          m('div.form-group.mb-2', [
                            m('label', 'Assign Resources:'),
                            m('.form-check', [
                              m('input.form-check-inpu[type="checkbox"]', {
                                class: 'mr-2 mt-2',
                                id: 'all',
                                onchange: e => state.handleSelect(e),
                                checked: state.form_data.menulist.length === state.menulist.length,
                              }),
                              m('label.form-check-label.px-2', { for: 'all' }, 'Select All'),
                            ]),
                          ]),
                          state.form_errors.resources && m('div', { class: 'text-danger fs-6' }, 'Assign some resources'),
                        ]),
                        state.menulist.map(menu => m('.col-md-6.col-sm-12.row py-2', [
                          m('div.form-group.mb-2.d-flex.flew-row.align-items-center', [
                            m('.form-check.d-flex.flex-row', [
                              m('input.form-check-inpu[type="checkbox"]', {
                                class: 'mr-2',
                                id: menu.MENUID,
                                onchange: e => state.handleSelect(e),
                                checked: state.form_data.menulist.some(item => item.MENUID === menu.MENUID),
                              }),
                              m('label.form-check-label.px-2', { for: menu.MENUID }, menu.MENUNAME),
                            ]),
                          ]),
                        ])),
                      ]),
                    ]),
                    state.error && m('div', { class: 'text-danger fs-5' }, state.errorMessage),
                  ]),
                  m('.modal-footer',
                  [
                    m('button.btn.btn-secondary[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Close'),
                    m(`button.btn.btn-primary${state.loading === true ? '.k-spinner.k-spinner--v2.k-spinner--right.k-spinner--lg.k-spinner--success' : ''}`, {
                      disabled: !state.form_valid || state.loading,
                      async onclick() {
                        state.handleSubmit();
                      },
                    },
                    state.loading ? [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])] : 'Submit'),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
