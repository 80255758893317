/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import m from 'mithril';
import PubSub from 'pubsub-js';
import { runQuery, runMutation } from '../utils/fetchData';
import dataMap from '../utils/data';

import actions from '../utils/actionsMap';


const brandLogo = {
  async oninit() {
    const token = localStorage.getItem('token');

    if (!token) {
      return window.location.replace('/login');
    }
  },
  view() {
    return m(".k-aside__brand.k-grid__item.[id='k_aside_brand']",
      [
        m('.k-aside__brand-logo',
          m('a', {
            href: '/',
          },
            m("img[alt='Logo'][src='/assets/media/logos/all-white.png']", {
              style: {
                height: '18px',
              },
            }))),
        m('.k-aside__brand-tools',
          m("button.k-aside__brand-aside-toggler.k-aside__brand-aside-toggler--left[id='k_aside_toggler']",
            m('span'))),
      ]);
  },
};

const subMenuItem = {
  view({
    attrs,
  }) {
    const {
      onclick,
      title,
      active, icon, href,
      oncreate,
      onupdate,
      target,
    } = attrs;
    return m('li.k-menu__item', {
      class: `${active ? 'k-menu__item--active' : ''}`,
      'aria-haspopup': 'true',

    },
      m('a.k-menu__link', {
        href,
        oncreate,
        onupdate,
        onclick,
        target,
        'data-intro': attrs['data-intro'],
        'data-position': attrs['data-position'],
        'data-step': attrs['data-step'],
      },
        [
          !icon
            ? m('i.k-menu__link-bullet.k-menu__link-bullet--dot',
              m('span'))
            : m(`i.k-menu__link-icon.${icon}`),
          m('span.k-menu__link-text',
            title),
        ]));
  },
};

const subMenuHeader = {
  view({ attrs: { title, icon } }) {
    return m("a.k-menu__link.k-menu__toggle[href='javascript:;']",
      [
        m(`i.k-menu__link-icon.${icon}`),
        m('span.k-menu__link-text',
          title),
        m('i.k-menu__ver-arrow.la.la-angle-right'),
      ]);
  },
};

const menuItem = {
  view({
    attrs: {
      active, title, icon, href, oncreate, onupdate, target,
    },
  }) {
    return m("li.k-menu__item.[aria-haspopup='true']", {
      class: `${active ? 'k-menu__item--active' : ''}`,
      'aria-haspopup': 'true',
    },
      m('a.k-menu__link', {
        href,
        oncreate,
        onupdate,
        target,
      },
        [
          m(`i.k-menu__link-icon.${icon}`),
          m('span.k-menu__link-text',
            title),
        ]));
  },
};

const sectionHeader = {
  view({ attrs: { title } }) {
    return m('li.k-menu__section.',
      [
        m('h4.k-menu__section-text',
          title),
        m('i.k-menu__section-icon.flaticon-more-v2'),
      ]);
  },
};

export default {
  async oninit() {},
  // oncreate() {
  //   KLayout.init();
  // },
  view({ attrs }) {
    return [
      m('div', { class: 'app-navbar-item d-lg-none me-n2', style: { position: 'fixed', left: '0', top: '230PX' }, title: 'Show header menu' },
        m('button', { class: 'btn d-flex align-items-center justify-content-center bg-secondary w-20px h-30px shadow-lg rounded-0', id: 'kt_inbox_aside_toggle' },
          m('i', { class: 'bi bi-list' }))),
    m('div', {
      class: 'd-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-200px drawer',
      'data-kt-drawer': 'true',
      'data-kt-drawer-name': 'inbox-aside',
      'data-kt-drawer-activate': '{default: true, lg: false}',
      'data-kt-drawer-overlay': 'true',
      'data-kt-drawer-width': '225px',
      'data-kt-drawer-direction': 'start',
      'data-kt-drawer-toggle': '#kt_inbox_aside_toggle',
      style: {
        '.drawer': {
          position: 'relative', /* add this to allow for absolute positioning of the line */
        },
        '.drawer::after': {
          content: "''",
          position: 'absolute',
          top: 0,
          right: 0,
          width: '30rem', /* adjust the width to your liking */
          height: '100vh', /* make it go all the way to the page bottom */
          'background-color': '#333', /* adjust the color to your liking */
        },
      },
    },
      m('div', {
 class: 'cardx cardx-flush mb-0', 'data-kt-sticky': 'false', 'data-kt-sticky-name': 'inbox-aside-sticky', 'data-kt-sticky-offset': "{default: false, xl: '100px'}", 'data-kt-sticky-width': "{lg: '275px'}", 'data-kt-sticky-left': 'auto', 'data-kt-sticky-top': '100px', 'data-kt-sticky-animation': 'false', 'data-kt-sticky-zindex': '95',
},
        m('div', { class: 'cardx-body' }, [
          m('div', { class: 'menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10' },
            [
              m('div', {
                class: 'menu-item d-flex d-lg-none align-items-center justify-content-end',
              },
                m('button', {
                  class: `btn btn-light`, id: 'kt_inbox_aside_toggle',
                },
                  [
                    m('i', { class: `bi bi-chevron-left` }),
                  ])),
              m('div', { class: 'separator my-3 d-lg-none' }),
              attrs.sideBarItems.map(({
 label, icon, route, routesToActivate,
}) => {
                const currentRoute = m.route.get();
                const isActive = Array.isArray(routesToActivate) ? routesToActivate.includes(currentRoute) : currentRoute === route;
                return [
                  m('div', {
                    class: 'menu-item mb-3',
                    style: {
                      cursor: 'pointer',
                    },
                    'data-kt-drawer-dismiss': 'true',
                  },
                    m('span', {
                      class: `menu-link ${isActive ? 'active' : ''}`,
                      onclick: () => {
                        m.route.set(route);
                        // window.location.reload();
                      },
                    },
                      [
                        m('span', { class: 'menu-icon' },
                          m('i', { class: `ki-duotone ${icon} fs-2 me-3` },
                            [
                              m('span', { class: 'path1' }),
                              m('span', { class: 'path2' }),
                            ])),
                        m('span', { class: 'menu-title fw-bold' },
                          label),
                      ])),
                  m('div', { class: 'separator my-3' }),
                ];
              }),

            ])])))];
  },
};
