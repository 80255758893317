/* eslint-disable radix */
/* eslint-disable no-param-reassign */
import m from 'mithril';

import { t, setSelectedLanguage } from '../translation-files/translator';
// import userAuthModal from "./auth"
import { getItemLocalStorage } from '../utils/helper';
import toolbar from './toolbar';
import resetModal from './reset-password';

export default {
  oncreate() {
    window.KTComponents.init();

    // if (!getItemLocalStorage('hasAuthenticated')) {
    //   m.route.set('/auth');
    //   location.reload();
    // }
  },

  oninit: ({ state }) => {
    state.logout = () => {
      localStorage.removeItem('cardStatements');
      localStorage.removeItem('thresholdData');
      localStorage.removeItem('topupStatements');
      localStorage.removeItem('hasAuthenticated');
      localStorage.removeItem('accountData');
      localStorage.removeItem('globalCardType');
      localStorage.removeItem('companyName');
      localStorage.removeItem('statements');
      localStorage.removeItem('miniStatements');
      localStorage.removeItem('cardListData');
      localStorage.removeItem('stationList');
      localStorage.removeItem('stations');
      localStorage.removeItem('globalLimit');
      localStorage.removeItem('dealerMinistatement');
      localStorage.removeItem('paymentMethods');
      m.route.set('/');
      location.reload();
    };
    const accountData = getItemLocalStorage('accountData');
    const authTime = getItemLocalStorage('authenticationTime');
    if (!getItemLocalStorage('hasAuthenticated') || (new Date().getTime() - parseInt(authTime)) > 7200000) {
      state.logout();
    }
    state.accountData = accountData || {};
    // const intervalH = setInterval(() => {
    //   companyName = getItemLocalStorage('companyName');
    //   state.company = companyName || '';
    //   if (companyName) {
    //     clearInterval(intervalH);
    //   }
    //   m.redraw();
    // }, 2000);

    // m.redraw();
  },

  view({ state, attrs }) {
    return [
      m(resetModal),
      m('div', {
        class: 'app-header shadow-lg flex-column justify-content-between',
        id: 'kt_app_header',
      }, [
        m('div', { class: 'app-container container-xxl d-flex align-items-stretch justify-content-between  shadow-lg', id: 'kt_app_header_container' },
          [
            // m(userAuthModal),
            m('div', { class: 'd-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15' },
              m('a', { href: 'index.html' },
                [
                  m('img', { class: 'h-80px h-lg-80px app-sidebar-logo-default theme-light-show', alt: 'Logo', src: attrs.logos.light }),
                  m('img', { class: 'h-60px h-lg-60px app-sidebar-logo-default theme-dark-show', alt: 'Logo', src: attrs.logos.dark }),
                ])),
            m('div', { class: 'd-flex align-items-stretch justify-content-center flex-lg-grow-1', id: 'kt_app_header_wrapper' },
              m('div', {
                class: 'app-header-menu app-header-mobile-drawer align-items-stretch',
                 'data-kt-drawer': 'true',
                'data-kt-drawer-name': 'app-header-menu',
                'data-kt-drawer-activate': '{default: true, lg: false}',
                'data-kt-drawer-overlay': 'true',
                'data-kt-drawer-width': '225px',
                'data-kt-drawer-direction': 'end',
                'data-kt-drawer-toggle': '#kt_app_header_menu_toggle',
                // 'data-kt-drawer-permanent': 'true',
                // 'data-kt-swapper': 'true',
                // 'data-kt-swapper-mode': "{default: 'append', lg: 'prepend'}",
                // 'data-kt-swapper-parent': "{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}",
              },
                m('div', { class: 'menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0', id: 'kt_app_header_menu', 'data-kt-menu': 'true' },
                  [
                    [{
                      label: t().home_acc_info.navbar.reports,
                      route: '/reports',
                    }, ...(state.accountData.MENULIST.some(menu => menu.MENUID === 'CLI_USER_CRT') ? [{
                      label: t().home_acc_info.navbar.user_management,
                      route: '/userManagement',
                    }] : [])].map(({
                      label, route, routesToActivate = [],
                    }) => {
                      const currentRoute = m.route.get();
                      const isActive = currentRoute === route || routesToActivate.includes(currentRoute);
                      return m('div', {
                        class: `menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2 ${isActive ? 'here' : ''}`,
                        'data-kt-menu-trigger': "{default: 'click', lg: 'hover'}",
                        'data-kt-menu-placement': 'bottom-start',
                        'data-kt-drawer-dismiss': 'true',
                        style: {
                          cursor: 'pointer',
                        },
                        onclick: (e) => {
                          e.preventDefault();
                          m.route.set(route);
                          location.reload();
                        },
                      },
                        [
                          m('span', { class: 'menu-link', style: { fontWeight: 600, fontSize: '1.1rem' } },
                            [
                              m('span', { class: 'menu-title' },
                                label),
                              m('span', { class: 'menu-arrow d-lg-none' }),
                            ]),
                        ]);
                    }),
                  ]))),
            m('div', { class: 'd-flex align-items-stretch justify-content-end', id: 'kt_app_header_wrapper' },
              m('div', { class: 'app-navbar flex-shrink-0' },
                [

                  m('div', { class: 'app-navbar-item ms-2 ms-1 ms-lg-3', id: 'kt_header_user_menu_toggle' },
                    [
                      m('div', { class: 'app-navbar-item ms-1 ms-lg-3' },
                        [
                          m('div', {
                            class: 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px', 'data-kt-menu-trigger': "{default: 'click', lg: 'hover'}", 'data-kt-menu-attach': 'parent', 'data-kt-menu-placement': 'bottom-end',
                          },
                            m('span', { class: 'symbol symbol-60px' },
                              // m("img", {
                              //   "class": "rounded",
                              //   "src": localStorage.getItem('langFlag') || "assets/media/flags/united-states.svg",
                              //   "alt": ""
                              // }),
                              // m("span", { "class": "ms-2" },
                              //   localStorage.getItem('langCode') === 'en' ? 'English' : localStorage.getItem('langCode') === 'fr' ? 'French' : ''
                              // )
                              [m('i', { class: 'bi bi-translate fs-1' })]),
                            m('div', { class: 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-175px', 'data-kt-menu': 'true' },
                              [
                                m('div', { class: 'menu-item px-3' },
                                  m('a', { class: `menu-link d-flex px-5 ${localStorage.getItem('langCode') === 'en' ? 'active' : ''}`, href: 'account/settings.html', onclick: (e) => { e.preventDefault(); setSelectedLanguage('en'); localStorage.setItem('langCode', 'en'); localStorage.setItem('langFlag', 'assets/media/flags/united-states.svg'); } },
                                    [
                                      // m("span", { "class": "symbol symbol-20px me-4" },
                                      //   m("img", { "class": "rounded-1", "src": "assets/media/flags/united-states.svg", "alt": "" })
                                      // ),
                                      'English',
                                    ])),
                                // m("div", { "class": "menu-item px-3" },
                                //   m("a", { "class": "menu-link d-flex px-5 " + (localStorage.getItem('langCode') === 'fr' ? 'active' : ''), "href": "account/settings.html", "onclick": (e) => { e.preventDefault(); setSelectedLanguage('fr'); localStorage.setItem('langCode', 'fr'); localStorage.setItem('langFlag', 'assets/media/flags/france.svg'); } },
                                //     [
                                //       // m("span", { "class": "symbol symbol-20px me-4" },
                                //       //   m("img", { "class": "rounded-1", "src": "assets/media/flags/france.svg", "alt": "" })
                                //       // ),
                                //       "French"
                                //     ]
                                //   )
                                // )
                              ])),
                        ]),
                        m('div', { class: 'app-navbar-item ms-1 ms-lg-3' },
                          [
                            m('a', {
 class: 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px', href: '#', 'data-kt-menu-trigger': "{default:'click', lg: 'hover'}", 'data-kt-menu-attach': 'parent', 'data-kt-menu-placement': 'bottom-end',
},
                              [
                                m('i', { class: 'ki-duotone ki-night-day theme-light-show fs-1' },
                                  [
                                    m('span', { class: 'path1' }),
                                    m('span', { class: 'path2' }),
                                    m('span', { class: 'path3' }),
                                    m('span', { class: 'path4' }),
                                    m('span', { class: 'path5' }),
                                    m('span', { class: 'path6' }),
                                    m('span', { class: 'path7' }),
                                    m('span', { class: 'path8' }),
                                    m('span', { class: 'path9' }),
                                    m('span', { class: 'path10' }),
                                  ]),
                                m('i', { class: 'ki-duotone ki-moon theme-dark-show fs-1' },
                                  [
                                    m('span', { class: 'path1' }),
                                    m('span', { class: 'path2' }),
                                  ]),
                              ]),
                            m('div', { class: 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px', 'data-kt-menu': 'true', 'data-kt-element': 'theme-mode-menu' },
                              [
                                m('div', { class: 'menu-item px-3 my-0' },
                                  m('a', {
                                    class: 'menu-link px-3 py-2',
                                    onclick() {
                                      localStorage.setItem('theme', 'light');
                                      document.documentElement.setAttribute('data-theme', 'light');
                                      location.reload();
                                    },
                                    'data-kt-element': 'mode',
'data-kt-value': 'light',
                                  },
                                    [
                                      // m("span", { "class": "menu-icon", "data-kt-element": "icon" },
                                      //   m("i", { "class": "ki-duotone ki-night-day fs-2" },
                                      //     [
                                      //       m("span", { "class": "path1" }),
                                      //       m("span", { "class": "path2" }),
                                      //       m("span", { "class": "path3" }),
                                      //       m("span", { "class": "path4" }),
                                      //       m("span", { "class": "path5" }),
                                      //       m("span", { "class": "path6" }),
                                      //       m("span", { "class": "path7" }),
                                      //       m("span", { "class": "path8" }),
                                      //       m("span", { "class": "path9" }),
                                      //       m("span", { "class": "path10" })
                                      //     ]
                                      //   )
                                      // ),
                                      m('span', { class: 'menu-title' },
                                        'Light'),
                                    ])),
                                m('div', { class: 'menu-item px-3 my-0' },
                                  m('a', {
                                    class: 'menu-link px-3 py-2',
                                    onclick() {
                                      localStorage.setItem('theme', 'dark');
                                      document.documentElement.setAttribute('data-theme', 'dark');
                                      location.reload();
                                    },
'data-kt-element': 'mode',
'data-kt-value': 'dark',
                                  },
                                    [
                                      // m("span", { "class": "menu-icon", "data-kt-element": "icon" },
                                      //   m("i", { "class": "ki-duotone ki-moon fs-2" },
                                      //     [
                                      //       m("span", { "class": "path1" }),
                                      //       m("span", { "class": "path2" })
                                      //     ]
                                      //   )
                                      // ),
                                      m('span', { class: 'menu-title' },
                                        'Dark'),
                                    ])),
                                // m("div", { "class": "menu-item px-3 my-0" },
                                //   m("a", {
                                //     "class": "menu-link px-3 py-2", onclick() {
                                //       localStorage.setItem('theme', 'system');
                                //       document.documentElement.setAttribute('data-theme', 'dark');
                                //     }, "data-kt-element": "mode", "data-kt-value": "system"
                                //   },
                                //     [
                                //       m("span", { "class": "menu-icon", "data-kt-element": "icon" },
                                //         m("i", { "class": "ki-duotone ki-screen fs-2" },
                                //           [
                                //             m("span", { "class": "path1" }),
                                //             m("span", { "class": "path2" }),
                                //             m("span", { "class": "path3" }),
                                //             m("span", { "class": "path4" })
                                //           ]
                                //         )
                                //       ),
                                //       m("span", { "class": "menu-title" },
                                //         "System"
                                //       )
                                //     ]
                                //   )
                                // )
                              ]),
                          ]),

                      m('div', {
                        class: 'cursor-pointer symbol symbol-35px symbol-md-40px', 'data-kt-menu-trigger': "{default: 'click', lg: 'hover'}", 'data-kt-menu-attach': 'parent', 'data-kt-menu-placement': 'bottom-end',
                      },
                        m('div', { class: 'menu-content d-flex align-items-center px-3' },
                          [
                            // m("div", { "class": "symbol symbol-40px me-5" },
                            //   m("img", { "alt": "user", "src": "https://picsum.photos/40" })
                            // ),
                            m('div', { class: 'd-flex flex-column' },
                              [
                                m('div', { class: 'fw-bold d-flex align-items-center fs-5' },
                                  state.accountData.USERNAME),
                                // m("a", { "class": "fw-semibold text-muted text-hover-primary fs-7", "href": "#" },
                                //   "max@kt.com"
                                // ),
                                // m("span", { "class": "badge badge-light-success fw-bold" },
                                //   "Corporate"
                                // )
                              ]),
                          ])),
                      m('div', { class: 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px', 'data-kt-menu': 'true' },
                        [
                          m('div', { class: 'menu-item px-3' },
                            m('div', { class: 'menu-content d-flex align-items-center px-3' },
                              [
                                // m("div", { "class": "symbol symbol-50px me-5" },
                                //   m("img", { "alt": "Logo", "src": "assets/media/avatars/300-3.jpg" })
                                // ),
                                m('div', { class: 'd-flex flex-column' },
                                  [
                                    m('div', { class: 'fw-bold d-flex align-items-center fs-5' },
                                      [
                                        state.accountData.CUST_NAME || '',

                                      ]),

                                  ]),
                              ])),
                          m('div', { class: 'menu-item px-5' },
                            m('a', {
                              class: 'menu-link px-5',
                              onclick(e) {
                                e.preventDefault();
                                resetModal.show();
                              },
                            },
                              t().home_acc_info.navbar.change_password)),
                          m('div', { class: 'menu-item px-5' },
                            m('a', {
                              class: 'menu-link px-5',
                              onclick(e) {
                                e.preventDefault();
                                state.logout();
                              },
                            },
                              t().home_acc_info.navbar.log_out)),
                        ]),
                    ]),
                  m('div', { class: 'app-navbar-item d-lg-none ms-2 me-n2', title: 'Show header menu' },
                    m('div', { class: 'btn btn-flex btn-icon btn-active-color-primary w-40px h-40px', id: 'kt_app_header_menu_toggle' },
                      m('i', { class: 'fa-solid fa-bars' }))),
                ])),
          ]),
        m(toolbar),
      ])];
  },
};
