import m from 'mithril';
import demoModal from './modals/demo';
import validationDemo from './modals/validationDemo';

export default {
  oninit: ({ state, attrs }) => {
    const currentPosition = document.body.scrollHeight <= window.innerHeight ? 'fixed' : 'relative';
    if (state.position !== currentPosition) {
      state.position = currentPosition;
    }

    setInterval(() => {
      const newPosition = document.body.scrollHeight <= window.innerHeight ? 'fixed' : 'relative';
      if (state.position !== newPosition) {
        state.position = newPosition;
          // m.redraw();
      }
    }, 50);
  },
  view({ state }) {
    return m('div', {
      class: 'app-footer',
      id: 'kt_app_footer',
      style: {
        bottom: 0,
        width: '100%',
        height: 'auto',
        position: state.position || 'absolute',
      },
    },
      m('div', { class: 'app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3' },
        // [
        //   m('div', { class: 'text-gray-900 order-2 order-md-1' },
        //     [
        //       m('span', { class: 'text-muted fw-semibold me-1' },
        //         '2024©'),
        //       m('a', { class: 'text-gray-800 text-hover-primary', href: '#', target: '_blank' },
        //         'Volane'),
        //       m('a', { class: 'menu-link px-2', href: '#', target: '_blank' },
        //         'v0.9.1'),
        //       m('a', { class: 'menu-link px-2', href: '#', target: '_blank' },
        //         'QA ENV')
        //     ]),
        // ]
      ));
  },
};
