/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import m from 'mithril';
import modalCloseButton from './modalCloseButton';
import { getItemLocalStorage } from '../utils/helper';
import apirequest from '../utils/api-service';

const modal = {
  key: 'RESET_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state, attrs }) {
    const accountData = getItemLocalStorage('accountData') || {};
    state.loading = false;
    state.form_data = {
      current: '',
      password: '',
      cPassword: '',
    };
    state.form_errors = {
      current: false,
      password: false,
      cPassword: false,
    };
    state.form_valid = false;
    state.success = false;
    state.successMessage = '';
    state.error = false;
    state.errorMessage = '';
    state.handleInputChange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      state.handleInputValidations();
    };
    state.handleInputValidations = () => {
      state.form_errors.current = state.form_data.current === '';
      state.form_errors.password = state.form_data.password.length < 5 || state.form_data.password === state.form_data.current;
      state.form_errors.cPassword = state.form_data.password !== state.form_data.cPassword;
      state.form_valid = !state.form_errors.current && !state.form_errors.password && !state.form_errors.password;
    };
    state.refresh = () => {
        state.form_valid = false;
        state.success = false;
        state.successMessage = '';
        state.error = false;
        state.errorMessage = '';
        state.form_data = {
          current: '',
          password: '',
          cPassword: '',
        };
        localStorage.removeItem('hasAuthenticated');
        localStorage.removeItem('accountData');
        localStorage.removeItem('globalCardType');
        localStorage.removeItem('companyName');
        localStorage.removeItem('statements');
        localStorage.removeItem('cardListData');
        localStorage.removeItem('stationList');
        m.route.set('/');
        location.reload();
    };
    state.handleSubmit = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/resetPassword`, 'POST', {
          USERTYPE: accountData.USERTYPE,
          USERNAME: accountData.USERNAME,
          NEWPWD: state.form_data.password,
          OLDPWD: state.form_data.current,
        });
        state.loading = false;

        if (respcode === 0) {
          state.success = true;
          state.successMessage = 'Password updated successfully';
          state.error = false;
          setTimeout(() => {
            state.refresh();
          }, 5000);
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to update password';
      }
    };

    // m.redraw();
  },
  view({ state }) {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog.modal-dialog-centered[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', 'Reset password'),
                    m('div', { class: 'btn btn-icon btn-sm btn-active-light-primary ms-2', 'data-bs-dismiss': 'modal', 'aria-label': 'Close' },
                      m(modalCloseButton)),
                  ]),
                m('.modal-body',
                  [
                    state.success && m('div', { class: 'text-success fs-5' }, state.successMessage),
                    m('form', [
                      m('.row', [
                        m('.col-md-12 py-2', [
                          m('div.form-group.mb-2', [
                            m('label.mr-2', 'Current password:'),
                            m('input.form-control[type="password"]', {
                              placeholder: '',
                              name: 'current',
                              value: state.form_data.current,
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.current && m('div', { class: 'text-danger fs-6' }, 'Current password is required'),
                          ]),
                          m('div.form-group.mb-2', [
                            m('label', 'New password:'),
                            m('input.form-control[type="password"]', {
                              name: 'password',
                              value: state.form_data.password,
                              placeholder: '',
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.password
                             ? (state.form_data.password === state.form_data.current
                              ? m('div', { class: 'text-danger fs-6' }, 'New Password should not be the same as the current password')
                              : m('div', { class: 'text-danger fs-6' }, 'Password should be atleast 5 characters')) : [],
                          ]),
                          m('div.form-group.mb-2', [
                            m('label', 'Confirm new password:'),
                            m('input.form-control[type="password"]', {
                              name: 'cPassword',
                              value: state.form_data.cPassword,
                              placeholder: '',
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.cPassword && m('div', { class: 'text-danger fs-6' }, 'Confirm password should match new password'),
                          ]),
                        ]),
                      ]),
                    ]),
                    state.error && m('div', { class: 'text-danger fs-5' }, state.errorMessage),
                  ]),
                  m('.modal-footer',
                  [
                    m('button.btn.btn-secondary[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Close'),
                    m(`button.btn.btn-primary${state.loading === true ? '.k-spinner.k-spinner--v2.k-spinner--right.k-spinner--lg.k-spinner--success' : ''}`, {
                      disabled: !state.form_valid || state.loading,
                      async onclick() {
                        state.handleSubmit();
                      },
                    },
                    state.loading ? [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])] : 'Submit'),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
