/* eslint-disable no-param-reassign */
import m from 'mithril';
import { isEmailValid, isValidAmount } from '../../../utils/helper';

const modal = {
  key: 'THRESHOLD_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.form_data = {
      email: '',
      amount: '',
    };
    state.form_errors = {
      email: false,
      amount: false,
    };

    state.onchange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      state.validateOnChange();
    };

    state.validateOnChange = () => {
      state.form_errors = {
        email: !isEmailValid(state.form_data.email),
        amount: !isValidAmount(state.form_data.amount),
      };
      state.form_valid = !state.form_errors.email && !state.form_errors.amount;
      m.redraw();
    };
    m.redraw();
  },
  oncreate: ({ state, attrs }) => {
    state.form_data.email = attrs.email;
    state.form_data.amount = attrs.amount;
    m.redraw();
  },
  view({ attrs, state }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', 'Set Threshold'),
                    ]),
                  m('.modal-body',
                    [

                        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                            m('form', {
                                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                              },
                              [
                                m('div', { class: 'mb-10' },
                                  [
                                    m('label', { class: 'required form-label', for: '' },
                                    'Amount'),
                                      m('input', {
                                        class: 'form-control',
                                        type: 'number',
                                        name: 'amount',
                                        min: 0,
                                        required: true,
                                        value: state.form_data.amount,
                                        oninput: e => state.onchange(e),
                                      }),
                                      state.form_errors.amount && [
                                        m('div', { class: 'text-danger fs-6 py-2' }, 'Enter valid amount'),
                                      ],

                                      m('label', { class: 'required form-label' },
                                      'Email'),
                                      m('input', {
                                        class: 'form-control',
                                        type: 'email',
                                        name: 'email',
                                        required: true,
                                        value: state.form_data.email,
                                        oninput: e => state.onchange(e),
                                      }),
                                      state.form_errors.email && m('div', { class: 'text-danger fs-6' }, 'Enter valid email'),

                                  ]),

                              ]),
                            m('br'),
                            m('div', { class: 'd-flex flex-column flex-sm-row' }, [
                              m('button.btn.btn-light flex-fill mx-2', {
                                onclick() {
                                  attrs.refresh();
                                },
                              }, 'Cancel'),
                              m('button.btn.btn-primary flex-fill mx-2', {
                                disabled: !state.form_valid,
                                onclick() {
                                  attrs.handleAction({
                                    amount: state.form_data.amount,
                                    email: state.form_data.email,
                                  });
                                },
                              }, 'Continue'),

                            ]),
                          ]),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
