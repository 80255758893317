const actions = {
  NEW_PROJECT: 'NEW_PROJECT',
  NEW_QUESTION: 'NEW_QUESTION',
  DELETE_PROJECT: 'DELETE_PROJECT',
  NEW_TEAM: 'NEW_TEAM',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_TEAM: 'UPDATE_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  RM_QUESTION: 'RM_QUESTION',
  CANCEL_EDIT_QUESTION: 'CANCEL_EDIT_QUESTION',
  CLIENT_VIEW_CHANGE: 'CLIENT_VIEW_CHANGE'
};

export default actions;
