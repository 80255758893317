/* eslint-disable no-param-reassign */
import m from 'mithril';

const modal = {
  key: 'ODOMETER_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.form_data = {
      odometer: '',
    };
    state.form_errors = {
      odometer: false,
    };
    state.form_valid = false;

    state.onchange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      state.validateOnChange();
    };

    state.validateOnChange = () => {
      const pattern = /^\d+$/;
      state.form_errors = {
        odometer: !pattern.test(state.form_data.odometer),
      };
      state.form_valid = !state.form_errors.odometer;
      m.redraw();
    };
  },
  view({ attrs, state }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', ''),
                    ]),
                  m('.modal-body',
                    [

                        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                            m('form', {
                                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                              },
                              [
                                m('div', { class: 'mb-10' },
                                  [
                                      m('label', { class: 'required form-label' },
                                      'Card Number'),
                                      m('input', {
                                        class: 'form-control',
                                        type: 'text',
                                        name: 'serial',
                                        required: true,
                                        disabled: true,
                                        value: attrs.card.CARDNUMBER,
                                      }),

                                      m('label', { class: 'required form-label', for: '' },
                                      'Odometer'),
                                        m('input', {
                                          class: 'form-control',
                                          type: 'number',
                                          name: 'odometer',
                                          min: '0',
                                          required: true,
                                          value: state.form_data.odometer,
                                          oninput: e => state.onchange(e),
                                        }),
                                        state.form_errors.odometer && [
                                          m('div', { class: 'text-danger fs-6 py-2' }, 'Enter valid odometer'),
                                        ],

                                  ]),

                              ]),
                            m('br'),
                            m('div', { class: 'd-flex flex-column flex-sm-row' }, [
                              m('button.btn.btn-light flex-fill mx-2', {
                                onclick() {
                                  attrs.refresh();
                                },
                              }, 'Cancel'),
                              m('button.btn.btn-primary flex-fill mx-2', {
                                disabled: !state.form_valid,
                                onclick() {
                                  attrs.handleAction({
                                    odometer: state.form_data.odometer,
                                    CARDNUMBER: attrs.card.ENCCARDNUMBER,
                                  });
                                },
                              }, 'Continue'),

                            ]),
                          ]),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
