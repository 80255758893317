import m from 'mithril';

const loader = {
  oninit() {
  },
  view() {
    return m('div', { class: 'position-fixed bg-dark bg-gradient bg-opacity-75 d-flex align-items-center justify-content-center', style: 'z-index: 2000; height: 100vh; width: 100vw;' },
      [
        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
          m('div', { class: 'spinner-grow text-success mt-3', style: 'height: 100px; width: 100px', role: 'status' }, [
            m('div', { class: 'visually-hidden' }, 'Loading...'),
          ]),
        ]),
      ]);
  },
};


export default loader;
