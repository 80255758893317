import m from 'mithril';

const modal = {
  key: 'DELETE_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit() {
  },
  view({ attrs }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', ''),
                    ]),
                  m('.modal-body',
                    [

                        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                            m('h2', { class: 'fw-bold py-2' }, 'You are about to delete a user'),
                            m('div', { class: 'my-3' }, [
                              m('div', { class: 'fw-bold' }, [
                                m('span.fs-3.fw-light', 'First Name:'),
                                m('span.fs-3.fw-medium.px-2', attrs.selectedUser.firstname),
                              ]),
                              m('div', { class: 'fw-bold' }, [
                                m('span.fs-3.fw-light', 'Last Name:'),
                                m('span.fs-3.fw-medium.px-2', attrs.selectedUser.lastname),
                              ]),
                              m('div', { class: 'fw-bold' }, [
                                m('span.fs-3.fw-light', 'Username:'),
                                m('span.fs-3.fw-medium.px-2', attrs.selectedUser.username),
                              ]),
                              m('div', { class: 'fw-bold' }, [
                                m('span.fs-3.fw-light', 'Email:'),
                                m('span.fs-3.fw-medium.px-2', attrs.selectedUser.email),
                              ]),
                            ]),
                            m('br'),
                            m('div', { class: 'd-flex flex-column flex-sm-row' }, [
                              m('button.btn.btn-primary flex-fill mx-2', {
                                onclick() {
                                  attrs.reset();
                                },
                              }, 'Cancel'),
                              m('button.btn.btn-danger flex-fill mx-2', {
                                onclick() {
                                  attrs.handleDelete();
                                },
                              }, 'Delete'),

                            ]),
                          ]),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
