import m from 'mithril';

const modal = {
  key: 'LOADING_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit() {
  },
  view() {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', ''),
                  ]),
                m('.modal-body',
                  [

                    m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                      m('div', { class: 'fs-3 fw-semibold py-2' }, 'Transfer is being processed.'),
                      m('div', { class: 'spinner-grow text-success mt-3', style: 'height: 100px; width: 100px', role: 'status' }, [
                        m('div', { class: 'visually-hidden' }, 'Loading...'),
                      ]),
                    ]),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
