/* eslint-disable no-param-reassign */
import m from 'mithril';
import header from '../../components/header';
import toolbar from '../../components/toolbar';
import sidebar from '../../components/sidebar';
import table_data from '../../components/table_data';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, setToLocalStorage, formatAmount, hasResource, debounce } from '../../utils/helper';

import { t } from '../../translation-files/translator';

import footer from '../../components/footer';
import loader from '../../components/loader';
import globalLimitModal from './modals/updateGlobalLimit';
import confirmModal from './modals/confirm';
import activateCardModal from './modals/activateCard';
import odometerModal from './modals/odometer';
import config from '../../config';
import instituationConfig from '../../instituation-config';

const country = instituationConfig[window.country || 'kenya'];
const INSTID = country.ID;

export default {
  oninit: async ({ state }) => {
    const accountData = getItemLocalStorage('accountData');
    const globalCardType = getItemLocalStorage('globalCardType');
    const globalLimit = getItemLocalStorage('globalLimit');
    const cardListData = getItemLocalStorage('cardListData');
    
    const { CARDLIST, CARD_LIST } = cardListData || {};
    state.accountData = accountData;
    state.globalCardType = globalCardType;
    state.loading = true;
    state.success = false;
    state.error = false;
    state.successMessage = '';
    state.errorMessage = '';
    state.errors = [];
    state.cards = [];
    state.all_cards = [];
    state.in_active_cards = [];
    state.company = '';
    state.selected_cards = [];
    state.selected_card = null;
    state.selected_status = null;
    state.filter_data = {
      search: ''
    }
    state.timer = undefined;
    state.debounce = (func, timeout = 2000) => {
      return function (...args) {
        if(state.timer ) clearTimeout(state.timer);

        state.timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }
    state.handleChange = (e) => {
      state.filter_data[e.target.name] = e.target.value;
      state.debounce(() => state.handleFilter(), 500)();
    };

    state.handleFilter = () => {
      let cards = [];
      const searchTxt = state.filter_data.search.toLowerCase();
      cards = state.all_cards.filter(({
        CARDNAME, CARDNUMBER, CARDTYPE, CARD_SERIAL_NUM, MOBILENUM, STATUSDESC
      }) => CARDTYPE?.toLowerCase().includes(searchTxt)
      || CARDNAME?.toLowerCase().includes(searchTxt)
      || CARD_SERIAL_NUM?.toLowerCase().includes(searchTxt)
      || CARDNUMBER?.toLowerCase().includes(searchTxt)
      || MOBILENUM?.toLowerCase().includes(searchTxt)
        || STATUSDESC?.toLowerCase().includes(searchTxt));

      state.cards = cards;
      m.redraw();
    };
    state.statuses = [
      {
        name: '07',
        title: 'Activate',
      },
      {
        name: '05',
        title: 'De-Activate',
      },
      {
        name: '10',
        title: 'Temporary block',
      },
      {
        name: '14',
        title: 'Re pin',
      },
      {
        name: '20',
        title: 'Force Pin change',
      },
    ];
    state.isActivateBtnActive = false;
    state.globalLimit = globalLimit || 0;
    state.refresh = () => {
      state.fetchData();
    };
    state.resetState = () => {
      state.selected_card = null;
      odometerModal.hide();
    };
    state.handleSelect = (e, data) => {
      if (e.target.checked) {
        const card = state.cards.find(item => data.card_number === item.CARDNUMBER);
        state.selected_cards = [...state.selected_cards, card];
      } else {
        state.selected_cards = [...state.selected_cards.filter(item => data.card_number !== item.CARDNUMBER)];
      }
      state.updateActivateBtnState();
    };
    state.handleSelectAll = (e) => {
      if (e.target.checked) {
        state.selected_cards = [...state.cards];
      } else {
        state.selected_cards = [];
      }
      state.updateActivateBtnState();
    };
    state.updateActivateBtnState = () => {
      const someActive = state.selected_cards.some(card => card.STATUS !== '05');
      state.isActivateBtnActive = !(state.selected_cards.length === 0 || someActive);
    };

    state.resetBulk = () => {
      state.selected_cards = [];
      setTimeout(() => {
        state.errors = [];
        m.redraw();
      }, 30000)
    }

    state.handleBulkActivation = async () => {
      try {
        state.loading = true;
        const cardsToActivate = state.selected_cards;
        await Promise.all(cardsToActivate.map((card) => apirequest(`/UserController/doBulkCardActivation`, 'POST', {
          SLNUMBER: card.CARDNUM,
        }))).then(res => {
          res.forEach(item => {
            state.errors.push({
              type: item.respcode === 0 ? 0 : 1,
              message: item.respcode === 0 ? 'Updated successfully' : item.respmsg
            })
          })
          state.loading = false;
        }).catch(err => {
          console.log('err:==', err)
          state.loading = false;
        })
        state.fetchData();
        state.resetBulk();
      } catch (err) {
        console.log(err);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Something went wrong.';
        m.redraw();
      }
    };

    state.handleActivation = async () => {
      try {
        state.loading = true;
        const cardsToActivate = state.selected_cards;
        const {
          respcode, respmsg,
        } = await apirequest(`/UserController/doBulkCardActivation`, 'POST', {
          SLNUMBER: cardsToActivate[0].CARDNUM,
        });
        state.loading = false;
        if (respcode === 0) {
          state.success = true;
          state.error = false;
          state.successMessage = respmsg || 'Success';
          state.fetchData();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
          state.success = false;
          m.redraw();
        }
        state.resetStatus();
      } catch (err) {
        console.log(err);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Something went wrong.';
        m.redraw();
      }
    };

    state.refreshTable = () => {
      state.selected_card = null;
      state.selected_status = null;
      m.redraw();
      confirmModal.hide();
    }

    state.handleActionSelect = (value, card) => {
      const status = card.ALLOWED_ACTIONS.find(item => item.CARD_STATUS === value); 
      if (status) {
        state.selected_status = {
          name: status.CARD_STATUS,
          title: status.CARD_STATUS_DESC
        }
      }
      state.selected_card = card;
      confirmModal.show();
      m.redraw();
    }

    state.handleConfirm = () => {
      confirmModal.hide();
      if (state.accountData.USERTYPE === '@CARD') {
        state.unblockCard();
        return;
      }
      if (state.selected_status?.name === '07') {
        if (state.selected_card?.STATUSDESC?.toLowerCase() === 'new pending activation') {
          activateCardModal.show();
        } else {
          state.handleUpdateStatus()
        }
      } else if (state.selected_status?.name === '20') {
        activateCardModal.show();
      } else {
        console.log('here')
        state.handleUpdateStatus();
      }
    }

    state.unblockCard = async () => {
      try {

        state.loading = true;
        const {
          respcode, respmsg,
        } = await apirequest(`/CardController/${state.selected_card.STATUS === '07' ? 'blockCard' : 'unblockCard'}`, 'POST', {
          ACCTNO: accountData.ACCTNO,
          CARDNO: accountData.CARDNO,
          MASKEDCARD: state.selected_card.MASKEDCARD,
          USERNAME: accountData.USERNAME,
        });
        state.loading = false;
        state.selected_card = null;
        state.selected_status = null;
        if (respcode === 0) {
          state.success = true;
          state.successMessage = respmsg;
          state.error = false;
          state.fetchIndividualData();
        } else {
          state.errorMessage = respmsg;
          state.error = true;
          state.success = false;
          m.redraw();
        }
        state.resetStatus();
      } catch (err) {
        console.log('err', err)
        state.loading = false;
        state.errorMessage = 'Something went wrong.';
        state.error = true;
        state.success = false;
        m.redraw();
      }
    };

    state.handleOdometerReset = (card) => {
      state.selected_card = card;
      m.redraw();
      odometerModal.show();
    }

    state.handleUpdateOdometer = async ({ odometer, CARDNUMBER }) => {
      try {

        state.loading = true;
        const {
          respcode, respmsg,
        } = await apirequest(`/ClientController/changeOdometer`, 'POST', {
          ACCTNO: accountData.ACCTNO,
          USERCODE: accountData.USERNAME,
          ODO_READING: odometer,
          CUSTID: accountData.USERTYPE === '@CARD' ? accountData.CUSTID : accountData.CARDNO,
          CARDNO: CARDNUMBER,
        });
        state.loading = false;
        state.selected_card = null;
        state.selected_status = null;
        if (respcode === 0) {
          state.success = true;
          state.successMessage = 'Odometer reading updated successfully';
          state.error = false;
          state.resetState();
          m.redraw();
        } else {
          state.errorMessage = 'Odometer has not been set for this card';
          state.error = true;
          state.success = false;
          state.resetState();
          m.redraw();
        }
        state.resetStatus();
      } catch (err) {
        console.log('err', err)
        state.loading = false;
        state.errorMessage = 'Something went wrong.';
        state.error = true;
        state.success = false;
        m.redraw();
      }
    };

    state.handleUpdateStatus = async () => {
      try {

        state.loading = true;
        const {
          respcode, respmsg,
        } = await apirequest(`/ClientController/updateCardStatus`, 'POST', {
          ACCTNO: accountData.ACCTNO,
          CARDNO: accountData.CARDNO,
          USERNAME: accountData.USERNAME,
          CARDDSTSTATUS: state.selected_status.name,
          CARDNUM: state.selected_card.ENCCARDNUMBER,
        });
        let card_num = state.selected_card.ENCCARDNUMBER;
        state.loading = false;
        state.selected_card = null;
        state.selected_status = null;
        if (respcode === 0) {
          state.success = true;
          state.successMessage = respmsg;
          state.error = false;
          state.fetchCardData(card_num);
        } else {
          state.errorMessage = respmsg;
          state.error = true;
          state.success = false;
        }
        state.resetStatus();
        m.redraw();
      } catch (err) {
        console.log('err', err)
        state.loading = false;
        state.errorMessage = 'Something went wrong.';
        state.error = true;
        state.success = false;
        m.redraw();
      }
    };
    state.fetchData = async () => {
      try {
        const {
          respbody: {
            CARDLIST,
            COMPANY,
            GLOBALCARDTYPE,
            GLOBALLIMIT,
          },
          respcode,
          respmsg
        } = await apirequest(`/ClientController/getCardList`, 'POST', {
          ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });

        if (respcode === 0) {
          setToLocalStorage('companyName', COMPANY);
          setToLocalStorage('globalCardType', GLOBALCARDTYPE);
          setToLocalStorage('globalLimit', GLOBALLIMIT);
          setToLocalStorage('cardListData', { CARDLIST });
  
          state.all_cards = CARDLIST.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
          state.handleFilter();
          state.company = COMPANY;
          state.globalLimit = GLOBALLIMIT;

        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        state.loading = false;
        m.redraw();
      } catch (error) {
        state.loading = false;
        m.redraw();
      }
    };
    state.fetchCardData = async (card_num) => {
      try {
        const {
          respcode,
          respmsg,
          respbody,
        } = await apirequest(`/ClientController/getCardDetails`, 'POST', {
          ACCTNO: accountData.ACCTNO, CUST_ID: accountData.CUSTID, CARD_NUM: card_num
        });

        if (respcode === 0) {
          const cards = state.all_cards.map(card => {
            if (card.CARD_SERIAL_NUM === respbody.CARD_SERIAL_NUM) {
              return {...card, ...respbody}
            } 
            return card;
          })
          setToLocalStorage('cardListData', { CARDLIST: cards });
    
          state.all_cards = cards.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
          state.handleFilter();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        state.loading = false;
        state.resetStatus();
        
      } catch (error) {
        console.log('error:', error)
        state.loading = false;
        m.redraw();
      }
    };
    state.fetchIndividualData = async () => {
      try {
        const {
          respbody,
          respcode,
          respmsg,
        } = await apirequest(`/CardController/getCardDetails`, 'POST', {
          ACCTNO: accountData.ACCTNO,
          CARDNO: accountData.CARDNO,
          CUSTID: accountData.CUSTID,
        });
        if (respcode === 0) {
          setToLocalStorage('companyName', 'Individual');
          setToLocalStorage('globalCardType', respbody.CARD_TYPE);
          setToLocalStorage('cardListData', respbody);

          state.cards = respbody.CARD_LIST.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
          state.company = 'Individual';
        } else {
          state.errorMessage = respmsg;
          state.error = true;
        }
        state.resetStatus();
        state.loading = false;
        // m.redraw();
      } catch (error) {
        state.loading = false;
        console.log(error);
        m.redraw();
      }
    };
    state.resetStatus = () => {
      setTimeout(() => {
        state.success = false;
        state.successMessage = '';
        state.error = false;
        state.errorMessage = ''
        m.redraw()
      }, 4000);
    };

    state.formatAmount = (amount) => {
      try {
        if (isNaN(amount)) {
          return amount;
        } else {
          return  formatAmount(amount)
        }
      } catch (error) {
        console.log('Error:', error)
      }
    }

    state.handleCardRefresh = () => {
      state.loading = true;
      if (accountData.USERTYPE === '@CARD') {
        state.fetchIndividualData();
      }
      if (accountData.USERTYPE === '@CLIENT') {
        state.fetchData();
      }
    }
    if (accountData.USERTYPE === '@CLIENT') {
      if (CARDLIST) {
        state.all_cards = CARDLIST.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
        state.loading = false;
        state.handleFilter();
      } else {
        state.fetchData();
      }
    }
    if (accountData.USERTYPE === '@CARD') {
      if (CARD_LIST) {
        state.cards = CARD_LIST.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
        state.company = 'Individual';
        state.loading = false
      } else {
        state.fetchIndividualData();
      }
    }
  },
  // disable due to failures on some browsers
  oncreate() { },
  view({ state }) {
    return [
      state.selected_card && m(odometerModal, { card: state.selected_card, refresh: state.resetState, handleAction: state.handleUpdateOdometer }),
      m(confirmModal, { status: state.selected_status?.title, handleAction: state.handleConfirm, refresh: state.refreshTable }),
      state.selected_card && m(activateCardModal, { card: state.selected_card, refresh: state.refresh }),
      !state.loading && m(globalLimitModal, { amount: state.globalLimit, refresh: state.refresh }),
      // state.loading && m(loader),
      // <!--begin::Header-->
      m(header, {
        logos: {
          dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [{
                    label: t().cards.sidebar_list.cards,
                    icon: 'bi bi-card-list',
                    svg: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5 7.75C11.5 7.33579 11.1642 7 10.75 7H4.75C4.33579 7 4 7.33579 4 7.75C4 8.16421 4.33579 8.5 4.75 8.5H10.75C11.1642 8.5 11.5 8.16421 11.5 7.75Z" fill="#94A3B8"/>
                    <path d="M10.5 10.75C10.5 10.3358 10.1642 10 9.75 10H4.75C4.33579 10 4 10.3358 4 10.75C4 11.1642 4.33579 11.5 4.75 11.5H9.75C10.1642 11.5 10.5 11.1642 10.5 10.75Z" fill="#94A3B8"/>
                    <path d="M10.75 13C11.1642 13 11.5 13.3358 11.5 13.75C11.5 14.1642 11.1642 14.5 10.75 14.5H4.75C4.33579 14.5 4 14.1642 4 13.75C4 13.3358 4.33579 13 4.75 13H10.75Z" fill="#94A3B8"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 19.75H17C18.5188 19.75 19.75 18.5188 19.75 17V11.5C19.75 11.0858 19.4142 10.75 19 10.75H15.75V2.9432C15.75 1.51979 14.1411 0.691828 12.9828 1.51916L12.8078 1.6442C12.0273 2.20169 10.9701 2.19982 10.1859 1.63971C8.88206 0.708375 7.11794 0.708375 5.81407 1.63971C5.02992 2.19982 3.9727 2.20169 3.19221 1.6442L3.01717 1.51916C1.8589 0.691827 0.25 1.5198 0.25 2.9432V16C0.25 18.0711 1.92893 19.75 4 19.75ZM6.68593 2.86031C7.46825 2.30151 8.53175 2.30151 9.31407 2.86031C10.6161 3.79033 12.3739 3.79744 13.6796 2.8648L13.8547 2.73976C14.0202 2.62157 14.25 2.73985 14.25 2.9432V17C14.25 17.4501 14.3581 17.875 14.5499 18.25H4C2.75736 18.25 1.75 17.2426 1.75 16V2.9432C1.75 2.73985 1.97984 2.62157 2.14531 2.73976L2.32036 2.8648C3.62605 3.79744 5.3839 3.79033 6.68593 2.86031ZM15.75 17V12.25H18.25V17C18.25 17.6904 17.6904 18.25 17 18.25C16.3096 18.25 15.75 17.6904 15.75 17Z" fill="#94A3B8"/>
                    </svg>
                    `,
                    route: '/cardList',
                  },
                  {
                    label: t().cards.sidebar_list.drivers,
                    icon: 'bi bi-body-text',
                    svg: `<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6501 2.2629L12.4635 2.3642C14.3408 2.59801 15.75 4.19359 15.75 6.08545V16.5749C15.75 18.4118 14.3817 19.961 12.5589 20.188C9.53134 20.5651 6.46866 20.5651 3.44107 20.188C1.61826 19.961 0.25 18.4118 0.25 16.5749V6.08545C0.25 4.19359 1.6592 2.59801 3.53655 2.3642L4.3499 2.2629C4.67208 1.10206 5.73654 0.25 7 0.25H9C10.2635 0.25 11.3279 1.10206 11.6501 2.2629ZM4.25 3.78693L3.72193 3.8527C2.59552 3.99299 1.75 4.95034 1.75 6.08545V16.5749C1.75 17.655 2.55458 18.566 3.62645 18.6995C6.53093 19.0612 9.46907 19.0612 12.3735 18.6995C13.4454 18.566 14.25 17.655 14.25 16.5749V6.08545C14.25 4.95034 13.4045 3.99299 12.2781 3.8527L11.75 3.78693V5C11.75 5.41421 11.4142 5.75 11 5.75H5C4.58579 5.75 4.25 5.41421 4.25 5V3.78693ZM5.75 3C5.75 2.30964 6.30964 1.75 7 1.75H9C9.69036 1.75 10.25 2.30964 10.25 3V4.25H5.75V3Z" fill="#94A3B8"/>
                    <path d="M11.75 9.75C11.75 9.33579 11.4142 9 11 9H5C4.58579 9 4.25 9.33579 4.25 9.75C4.25 10.1642 4.58579 10.5 5 10.5H11C11.4142 10.5 11.75 10.1642 11.75 9.75Z" fill="#94A3B8"/>
                    <path d="M10.75 12.75C10.75 12.3358 10.4142 12 10 12H5C4.58579 12 4.25 12.3358 4.25 12.75C4.25 13.1642 4.58579 13.5 5 13.5H10C10.4142 13.5 10.75 13.1642 10.75 12.75Z" fill="#94A3B8"/>
                    </svg>
                    `,
                    route: '/driver-codes',
                  },
                    // {
                    //   label: t().cards.sidebar_list.departments,
                    //   icon: 'bi bi-book',
                    //   svg: `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //   <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6023 16.636C10.7302 16.7128 10.8671 16.7468 11 16.7451C11.1329 16.7468 11.2698 16.7128 11.3977 16.636L12.533 15.9548C14.7595 14.619 17.4825 14.4008 19.8933 15.3651C20.7826 15.7208 21.75 15.0659 21.75 14.108V2.44915C21.75 1.8712 21.3981 1.35148 20.8615 1.13683C17.7564 -0.105202 14.2492 0.17582 11.3815 1.89644L11 2.12536L10.6185 1.89644C7.75077 0.17582 4.24357 -0.105202 1.13848 1.13683C0.60187 1.35148 0.25 1.8712 0.25 2.44915V14.108C0.25 15.0659 1.21739 15.7208 2.10672 15.3651C4.51752 14.4008 7.24052 14.619 9.46701 15.9548L10.6023 16.636ZM1.75 2.50798V13.8948C4.54579 12.8467 7.67179 13.1284 10.2388 14.6686L10.25 14.6754V3.42464L9.84673 3.18268C7.39774 1.71329 4.40634 1.46495 1.75 2.50798ZM11.7612 14.6686L11.75 14.6754V3.42464L12.1533 3.18268C14.6023 1.71329 17.5937 1.46495 20.25 2.50798V13.8948C17.4542 12.8467 14.3282 13.1284 11.7612 14.6686Z" fill="black"/>
                    //   <path d="M8.27516 17.0422C6.2513 15.8617 3.7487 15.8617 1.72484 17.0422L1.6221 17.1022C1.26431 17.3109 1.14346 17.7701 1.35217 18.1279C1.56088 18.4857 2.02011 18.6065 2.3779 18.3978L2.48065 18.3379C4.03746 17.4298 5.96254 17.4298 7.51935 18.3379L8.60661 18.9721C10.0856 19.8349 11.9144 19.8349 13.3934 18.9721L14.4806 18.3379C16.0375 17.4298 17.9625 17.4298 19.5194 18.3379L19.6221 18.3978C19.9799 18.6065 20.4391 18.4857 20.6478 18.1279C20.8565 17.7701 20.7357 17.3109 20.3779 17.1022L20.2752 17.0422C18.2513 15.8617 15.7487 15.8617 13.7248 17.0422L12.6376 17.6765C11.6257 18.2668 10.3743 18.2668 9.36242 17.6765L8.27516 17.0422Z" fill="black"/>
                    //   </svg>
                    //   `,
                    //   route: '/departments',
                    // },
                    // {
                    //   label: t().cards.sidebar_list.drivers,
                    //   icon: 'bi bi-body-text',
                    //   svg: `<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //   <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6501 2.2629L12.4635 2.3642C14.3408 2.59801 15.75 4.19359 15.75 6.08545V16.5749C15.75 18.4118 14.3817 19.961 12.5589 20.188C9.53134 20.5651 6.46866 20.5651 3.44107 20.188C1.61826 19.961 0.25 18.4118 0.25 16.5749V6.08545C0.25 4.19359 1.6592 2.59801 3.53655 2.3642L4.3499 2.2629C4.67208 1.10206 5.73654 0.25 7 0.25H9C10.2635 0.25 11.3279 1.10206 11.6501 2.2629ZM4.25 3.78693L3.72193 3.8527C2.59552 3.99299 1.75 4.95034 1.75 6.08545V16.5749C1.75 17.655 2.55458 18.566 3.62645 18.6995C6.53093 19.0612 9.46907 19.0612 12.3735 18.6995C13.4454 18.566 14.25 17.655 14.25 16.5749V6.08545C14.25 4.95034 13.4045 3.99299 12.2781 3.8527L11.75 3.78693V5C11.75 5.41421 11.4142 5.75 11 5.75H5C4.58579 5.75 4.25 5.41421 4.25 5V3.78693ZM5.75 3C5.75 2.30964 6.30964 1.75 7 1.75H9C9.69036 1.75 10.25 2.30964 10.25 3V4.25H5.75V3Z" fill="#94A3B8"/>
                    //   <path d="M11.75 9.75C11.75 9.33579 11.4142 9 11 9H5C4.58579 9 4.25 9.33579 4.25 9.75C4.25 10.1642 4.58579 10.5 5 10.5H11C11.4142 10.5 11.75 10.1642 11.75 9.75Z" fill="#94A3B8"/>
                    //   <path d="M10.75 12.75C10.75 12.3358 10.4142 12 10 12H5C4.58579 12 4.25 12.3358 4.25 12.75C4.25 13.1642 4.58579 13.5 5 13.5H10C10.4142 13.5 10.75 13.1642 10.75 12.75Z" fill="#94A3B8"/>
                    //   </svg>
                    //   `,
                    //   route: '/drivers',
                    // },
                  ],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
                  class: 'app-content ',
                  id: 'kt_app_content',
                  style: {
                    'flex-grow': 1,
                    'flex-basis': 'auto',
                  },
                },
                  m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' },
                    [
                      state.success && m('div', { class: 'text-success text-center fs-5 py-2' }, state.successMessage),
                      state.error && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage),
                      state.errors.map((item) => m('div', { class: `${item.type === 0 ? 'text-success' : 'text-danger'} text-center fs-5 py-2` }, item.message) ),
                      state.loading ? m(".linear-background") : m(table_data, {
                        title: t().cards.title,
                        headerItems: [
                          (state.accountData.USERTYPE === '@CLIENT' && state.globalCardType === '@POST') &&
                           m('div', { class: 'd-flex flex-row g-3 align-items-center', style: 'min-width: 20rem;' }, [
                            m('label', { class: 'fs-5 fw-semibold px-2', style: 'min-width: 8rem;' }, 'Global Limit:'),
                            m('div', { class: 'input-group' }, [
                              m('input', {
                                class: 'form-control ', 'aria-describedby': 'btnGroupAddon2', disabled: true, value: formatAmount(state.globalLimit),
                              }),
                            ]),
                          ]),
                        ],
                        actionItems: [
                          m('div', { class: 'd-flex flex-column-reverse flex-lg-row justify-content-end align-items-end align-items-lg-center px-5', 'data-kt-customer-table-toolbar': 'base' },
                            [
                              ...(state.accountData.USERTYPE === '@CLIENT' ? [
                                m('div', { class: 'input-group mx-2', style: 'min-width: 12rem; max-width: 40rem' }, [
                                  m('input[type="text"]', {
                                    class: 'form-control',
                                    placeholder: 'Search',
                                    name: 'search',
                                    value: state.filter_data.search,
                                    oninput: e => state.handleChange(e),
                                    'aria-describedby': 'basic-addon2'
                                  }),
                                  m('label', { class: 'input-group-text', id: 'basic-addon2' }, [
                                    m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                    </svg>`)
                                  ]),
                                ])] : []),
                                m('div', { class: 'd-flex flex-wrap flex-sm-nowrap py-2 py-lg-0'}, [
                                  m('button', {
                                    class: 'btn btn-primary mx-1 mx-sm-2 mt-3 mt-sm-0 px-0',
                                    style: 'max-height: 3.4rem;min-width: 11rem;',
                                    onclick() {
                                      state.handleCardRefresh();
                                    },
                                  },
                                    'Refresh Balances'),
                                ...(state.accountData.USERTYPE === '@CARD' || !hasResource('CLI_BLK_ACTV') ? [] : [m('button', {
                                  class: 'btn btn-primary mx-1 mx-sm-2 mt-3 mt-sm-0 px-0',
                                  style: 'max-height: 3.4rem;min-width: 11rem;',
                                  disabled: !state.isActivateBtnActive,
                                  onclick() {
                                    state.handleBulkActivation();
                                  },
                                },
                                  'Activate Cards')]),
                                ...(country?.selfreg?.[window.env] && state.accountData.CUST_TYPE === 'Prepaid' ? 
                                  [m('a', {
                                    class: 'btn btn-primary mx-1 mx-sm-2 mt-3 mt-sm-0 px-0',
                                    style: 'max-height: 3.4rem;min-width: 7rem;',
                                    href: `${country?.selfreg?.[window.env]}`, target: '_blank'
                                  },
                                    'New Card')] : []),
                              ]),
                            ]),
                        ],
                        dataConfigs: {
                          sourceEndpoint: '/cards',
                          disableActions: true,
                          disableSelection: state.accountData.USERTYPE === '@CARD',
                          onselect: state.handleSelect,
                          onselectall: state.handleSelectAll,
                          actionItems: [{
                            label: t().cards.cards_request,
                          }],
                          dataMapping: [{
                            label: t().cards.card_list_table.card_number,
                            data_key: 'card_number',
                          },
                          {
                            label: t().cards.card_list_table.vehicle_reg,
                            data_key: 'vehicle_reg',
                          }, {
                            label: t().cards.card_list_table.card_name,
                            data_key: 'card_name',
                          }, 
                          ...(state.accountData.USERTYPE === '@CLIENT' ? [{
                            label: t().cards.card_list_table.mobile_no,
                            data_key: 'mobile_no',
                          }] : []),
                          ...(state.globalCardType === '@POST' ? [
                            {
                              label: 'Monthly Limit',
                              data_key: 'month_limit',
                            }
                          ] : []), {
                            label: 'Card Balance',
                            data_key: 'card_bal',
                          }, {
                            label: 'Status',
                            data_key: 'status',
                          },
                          {
                            label: 'Action',
                            data_key: 'actions',
                          },
                          ...(hasResource('CLI_ODO_MTR_RESET') && state.accountData.USERTYPE === '@CLIENT' ? [{
                            label: 'Odometer',
                            data_key: 'odometer',
                          }] : [])],
                          dataRows: state.cards.map(card => ({
                            card_number: card.MASKEDCARD || card.CARDNUMBER,
                            serial_num: card.CARD_SERIAL_NUM,
                            vehicle_reg: card.VEHREGNUM || 'N/A',
                            card_name: card.CUSTNAME || card.CARDNAME,
                            card_bal: formatAmount(card.BALANCE || card.AVLBAL || 0),
                            month_limit: formatAmount(card.MONTHLYLIMT || 0),
                            mobile_no: card.MOBILENUM,
                            odometer: m('div', {}, [
                              m('button', {
                                class: 'btn border border-warning-subtle btn-sm p-2 btn-odometer', style: 'width: 100%; --bs-btn-hover-bg: #157347;', type: 'button',
                                onclick: () => state.handleOdometerReset(card)
                              }, 'Reset')
                            ]),
                            status: m('span', {
                               class: `rounded ${card.STATUS === '07' ? 'text-success border-success' : 'text-danger border-danger'}` }, 
                               card.STATUSDESC),
                            actions: m('div', [
                              card.ALLOWED_ACTIONS?.length > 0 ? m('select.form-select-sm', {
                                style: 'width: 100%; min-width: 7rem;',
                                value: state.selected_status?.name || '',
                                onchange: (e) => {
                                  state.handleActionSelect(e.target.value, card);
                                },
                              },
                                m('option', { value: '' }, '--select--'),
                                card.ALLOWED_ACTIONS?.map(option => m('option', { value: option.CARD_STATUS }, option.CARD_STATUS_DESC))
                              ) : [],
                            ])
                          })),
                        },
                      }),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
