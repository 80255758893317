/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';
import header from '../../components/header';
import toolbar from '../../components/toolbar';
import sidebar from '../../components/sidebar';
import table_data from '../../components/table_data';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import loader from '../../components/loader';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, formatAmount, debounce, getCardStrings } from '../../utils/helper';

export default {
  async oninit({ state }) {
    const companyName = getItemLocalStorage('companyName');
    const accountData = getItemLocalStorage('accountData') || {};
    const globalCardType = getItemLocalStorage('globalCardType');
    const cardListData = getItemLocalStorage('cardListData');

    const { CARDLIST, CARD_LIST } = cardListData || {};
    state.cards = CARDLIST || (CARD_LIST || []);
    state.accountData = accountData;
    state.company = companyName || '';
    state.globalCardType = globalCardType;
    state.SEL_CARDNOLIST = '';
    state.all_statements = [];
    state.statements = [];
    state.txnTypes = [];
    state.selectedTxnType = 'all';
    state.loading = true;
    state.filter_data = {
      search: '',
      txn_type: 'all',
    };

    state.max = moment().format();
    state.cal_data = {
      start: moment().subtract(6, 'days').format().split('T')[0],
      end: moment().format().split('T')[0],
    };
    state.handleCalChange = (e) => {
      state.cal_data[e.target.name] = e.target.value;
      if (accountData.USERTYPE === '@CARD') {
        state.fetchTopups();
      } else {
        state.fetchTxns();
      }
    };
    state.handleChange = (e) => {
      state.filter_data[e.target.name] = e.target.value;
      debounce(() => state.handleFilter());
    };
    state.handleSelect = (e) => {
      state.selectedTxnType = e.target.value
      state.handleFilter();
    }

    state.handleFilter = () => {
      let statements = [];
      const searchTxt = state.filter_data.search.toLowerCase();
      statements = state.all_statements.filter(({
        STATION, ENTRY_AMOUNT, RRN, CARD_NUM, PRODUCT, TXN_TYPE, OPERATION_TYPE,
      }) => STATION?.toLowerCase().includes(searchTxt)
      || ENTRY_AMOUNT?.toLowerCase().includes(searchTxt)
      || RRN?.toLowerCase().includes(searchTxt)
      || PRODUCT?.toLowerCase().includes(searchTxt)
      || CARD_NUM?.toLowerCase().includes(searchTxt)
      || TXN_TYPE?.toLowerCase().includes(searchTxt)
        || OPERATION_TYPE?.toLowerCase().includes(searchTxt));

      if (state.selectedTxnType !== 'all') {
        statements = statements.filter(({ TXN_TYPE }) => TXN_TYPE === state.selectedTxnType)
      }

      state.statements = statements;
      m.redraw();
    };

    state.handleReset = () => {
      state.filter_data = {
        search: '',
      };
      state.selectedTxnType = 'all'
      state.cal_data = {
        start: moment().subtract(6, 'days').format().split('T')[0],
        end: moment().format().split('T')[0],
      };
    
      if (accountData.USERTYPE === '@CARD') {
        state.fetchTopups();
      } else {
        state.fetchTxns();
      }
    }

    state.fetchTxns = async () => {
      try {
        // state.loading = true;
        const {
          respbody,
          respcode,
          respmsg,
        } = await apirequest(`/ClientController/detailedreport`, 'POST', {
          CARDNO: accountData.CARDNO,
          ACCTNO: accountData.ACCTNO,
          SEL_CARDNOLIST: getCardStrings(state.cards),
          FROMDATE: moment(state.cal_data.start).format('DD-MM-YYYY'),
          TODATE: moment(state.cal_data.end).format('DD-MM-YYYY'),
        });
        if (respcode === 0) {
          const { STATMENTS } = respbody;
          // const txns = STATMENTS.filter(({ TXN_TYPE, OPERATION_TYPE }) => OPERATION_TYPE === 'DR' && TXN_TYPE.toLowerCase().includes('sale'));
          const txns = STATMENTS;
          state.all_statements = txns;
          state.txnTypes = [...(new Set(STATMENTS.map(({ TXN_TYPE }) => TXN_TYPE)))]
          state.handleFilter();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
          setTimeout(() => {
            state.error = false;
          }, 30000)
        }
        state.loading = false;
        m.redraw();
        return;
      } catch (error) {
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Error fetching the data';
        console.log(error);
        m.redraw();
      }
    };

    state.fetchTopups = async () => {
      try {
        // state.loading = true;
        const {
          respbody,
          respcode,
          respmsg,
        } = await apirequest(`/CardController/detailedstmt`, 'POST', {
          CARDNO: accountData.CARDNO,
          CUSTID: accountData.USERTYPE === '@CARD' ? accountData.CUSTID : accountData.ACCTNO,
          FROMDATE: moment(state.cal_data.start).format('DD-MM-YYYY'),
          TODATE: moment(state.cal_data.end).format('DD-MM-YYYY'),
        });
        if (respcode === 0) {
          const { STATMENTS } = respbody;
          // const txns = STATMENTS.filter(({ TXN_TYPE, OPERATION_TYPE }) => OPERATION_TYPE === 'DR' && TXN_TYPE.toLowerCase().includes('sale'));
          const txns = STATMENTS;
          state.all_statements = txns;
          state.txnTypes = [...(new Set(STATMENTS.map(({ TXN_TYPE }) => TXN_TYPE)))]
          state.handleFilter();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
          setTimeout(() => {
            state.error = false;
          }, 30000)
        }
        state.loading = false;
        m.redraw();
        return;
      } catch (error) {
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Error fetching the data';
        console.log(error);
        m.redraw();
      }
    };
    
    if (accountData.USERTYPE === '@CARD') {
      state.fetchTopups();
    } else {
      state.fetchTxns();
    }
  },
  // disable due to failures on some browsers
  oncreate() { },
  view({ state }) {
    return [
      // state.loading ? m(loader) : [],
      // <!--begin::Header-->
      m(header, {
        logos: {
          dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [{
                    label: t().transactions.sidebar_labels.transactions,
                    icon: 'bi bi-book',
                    svg: `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6023 16.636C10.7302 16.7128 10.8671 16.7468 11 16.7451C11.1329 16.7468 11.2698 16.7128 11.3977 16.636L12.533 15.9549C14.7595 14.619 17.4825 14.4008 19.8933 15.3651C20.7826 15.7208 21.75 15.0659 21.75 14.1081V2.44918C21.75 1.87123 21.3981 1.35151 20.8615 1.13686C17.7564 -0.105172 14.2492 0.175851 11.3815 1.89647L11 2.12539L10.6185 1.89647C7.75077 0.175851 4.24357 -0.105172 1.13848 1.13686C0.60187 1.35151 0.25 1.87123 0.25 2.44918V14.1081C0.25 15.0659 1.21739 15.7208 2.10672 15.3651C4.51752 14.4008 7.24052 14.619 9.46701 15.9549L10.6023 16.636ZM1.75 2.50801V13.8948C4.54579 12.8468 7.67179 13.1285 10.2388 14.6686L10.25 14.6754V3.42467L9.84673 3.18271C7.39774 1.71332 4.40634 1.46498 1.75 2.50801ZM11.7612 14.6686L11.75 14.6754V3.42467L12.1533 3.18271C14.6023 1.71332 17.5937 1.46498 20.25 2.50801V13.8948C17.4542 12.8468 14.3282 13.1285 11.7612 14.6686Z" fill="black"/>
                    <path d="M8.27516 17.0423C6.2513 15.8617 3.7487 15.8617 1.72484 17.0423L1.6221 17.1022C1.26431 17.3109 1.14346 17.7701 1.35217 18.1279C1.56088 18.4857 2.02011 18.6066 2.3779 18.3979L2.48065 18.3379C4.03746 17.4298 5.96254 17.4298 7.51935 18.3379L8.60661 18.9722C10.0856 19.8349 11.9144 19.8349 13.3934 18.9722L14.4806 18.3379C16.0375 17.4298 17.9625 17.4298 19.5194 18.3379L19.6221 18.3979C19.9799 18.6066 20.4391 18.4857 20.6478 18.1279C20.8565 17.7701 20.7357 17.3109 20.3779 17.1022L20.2752 17.0423C18.2513 15.8617 15.7487 15.8617 13.7248 17.0423L12.6376 17.6765C11.6257 18.2668 10.3743 18.2668 9.36242 17.6765L8.27516 17.0423Z" fill="black"/>
                    </svg>
                    `,
                    route: '/transactions',
                  }],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
                  class: 'app-content',
                  id: 'kt_app_content',
                  style: {
                    'flex-grow': 1,
                    'flex-basis': 'auto',
                  },
                },
                  m('div', { class: 'app-container', id: 'kt_app_content_container' },
                    [
                      state.loading ? m(".linear-background") : m(table_data, {
                        title: t().transactions.title,
                        actionItems: [
                          m('div', { class: 'd-flex justify-content-end', 'data-kt-customer-table-toolbar': 'base', style: { 'padding-right': '10px' } },
                            [
                              m('form', { class: 'px-2 d-flex flex-wrap', style: '' }, [
                                m('div.form-group.mx-2.mb-2.flex-grow-1', [
                                  m('label', 'Search:'),
                                  m('input.form-control[type="text"]', {
                                    placeholder: 'Search..',
                                    value: state.filter_data.search,
                                    name: 'search',
                                    oninput: e => state.handleChange(e),
                                  }),
                                ]),
                                m('div.form-group.mx-3.mb-2.flex-grow-1.flex-sm-grow-0', [
                                  m('label', 'Transaction type:'),
                                  m('select', {
                                    class: 'form-select me-2',
                                    'aria-label': 'Filter Transaction type',
                                    style: { margin: '0px' },
                                    value: state.selectedTxnType,
                                    onchange: (e) => { state.handleSelect(e); },
                                  }, [
                                    m('option', { value: 'all' }, 'All'),
                                    state.txnTypes?.map(option => m('option', { value: option }, option)),
                                  ]),
                                ]),
                                m('div.form-group.mx-2.mb-2.flex-grow-1.flex-sm-grow-0', [
                                  m('label', 'From date:'),
                                  m('input.form-control[type="date"]', {
                                    value: state.cal_data.start,
                                    name: 'start',
                                    max: state.cal_data.end,
                                    onchange: e => state.handleCalChange(e),
                                  }),
                                ]),
                                m('div.form-group.mx-2.mb-2.flex-grow-1.flex-sm-grow-0', [
                                  m('label', 'To date:'),
                                  m('input.form-control[type="date"]', {
                                    placeholder: 'select end date',
                                    value: state.cal_data.end,
                                    name: 'end',
                                    min: state.cal_data.start,
                                    max: state.max.split('T')[0],
                                    oninput: e => state.handleCalChange(e),
                                  }),
                                ]),
                                m('div.form-group.mx-2.mb-2.d-flex.align-items-end.flex-grow-1.flex-sm-grow-0', [
                                  m('button', { class: 'btn btn-primary', onclick: () => state.handleReset() }, 'Reset')
                                ])
                              ]),
                            ]),
                        ],
                        dataConfigs: {
                          sourceEndpoint: '/transactions',
                          // disableSelection:true,
                          disableDeletion: true,
                          disableActions: true,
                          disableSelection: true,
                          dataMapping: state.accountData.USERTYPE === '@CLIENT' ?  [{
                            label: 'Date & Time',
                            data_key: 'date',
                          }, {
                            label: t().transactions.transaction_table_labels.card_number,
                            data_key: 'card_number',
                          }, {
                            label: 'Card Name',
                            data_key: 'card_name',
                          }, {
                            label: 'TXN Amount',
                            data_key: 'amount',
                          }, {
                            label: 'Pump price',
                            data_key: 'price',
                          }, {
                            label: t().transactions.transaction_table_labels.station,
                            data_key: 'station',
                          }, {
                            label: 'Current Odometer',
                            data_key: 'ODOMETER',
                          },{
                            label: 'Prev Odometer',
                            data_key: 'ODOMETER_PREVS',
                          },{
                            label: 'Mileage',
                            data_key: 'Mileage',
                          },  {
                            label: 'TXN Type / Product',
                            data_key: 'txn_type',
                          }, {
                            label: 'DR/CR',
                            data_key: 'op_type',
                          }, {
                            label: 'RRN',
                            data_key: 'rrn',
                          }, {
                            label: 'Quantity',
                            data_key: 'QUANTITY',
                          }, {
                            label: 'Balance',
                            data_key: 'BALANCE',
                          }, {
                            label: 'Driver code',
                            data_key: 'DRCODE',
                          }, {
                            label: 'Vehicle Reg No',
                            data_key: 'VEH_NUM',
                          }] : [{
                            label: 'Date & Time',
                            data_key: 'date',
                          }, {
                            label: t().transactions.transaction_table_labels.card_number,
                            data_key: 'card_number',
                          }, {
                            label: t().transactions.transaction_table_labels.station,
                            data_key: 'station',
                          }, {
                            label: 'RRN',
                            data_key: 'rrn',
                          }, {
                            label: 'Product',
                            data_key: 'product',
                          }, {
                            label: 'Txn Type',
                            data_key: 'txn_type',
                          }, {
                            label: 'DR/CR',
                            data_key: 'op_type',
                          }, {
                            label: 'Amount',
                            data_key: 'amount',
                          }
                            ],
                          dataRows: state.accountData.USERTYPE === '@CLIENT' ? state.statements.map(({
                            TRAN_DATE, STATION, TRAN_TIME, ENTRY_AMOUNT, PRICE, ODOMETER, ODOMETER_PREVS, RRN, CARD_NUM, CARD_NAME, TXN_TYPE, OPERATION_TYPE, QUANTITY, BALANCE, DRCODE, VEH_NUM
                          }) => ({
                            date: `${TRAN_DATE} : ${TRAN_TIME}`,
                            card_number: CARD_NUM,
                            card_name: CARD_NAME,
                            station: STATION,
                            amount: formatAmount(ENTRY_AMOUNT),
                            price: formatAmount(PRICE),
                            ODOMETER,
                            ODOMETER_PREVS,
                            Mileage: isNaN(Number(ODOMETER) - Number(ODOMETER_PREVS)) ? '' : Number(ODOMETER) - Number(ODOMETER_PREVS),
                            rrn: RRN,
                            txn_type: TXN_TYPE,
                            op_type: OPERATION_TYPE === 'CR' ? 'Credit' : 'Debit',
                            QUANTITY,
                            BALANCE: formatAmount(BALANCE),
                            DRCODE,
                            VEH_NUM
                          })) : state.statements.map(({
                            TRAN_DATE, STATION, TRAN_TIME, ENTRY_AMOUNT, RRN, CARD_NUM, PRODUCT, TXN_TYPE, OPERATION_TYPE,
                          }) => ({
                            date: `${TRAN_DATE} : ${TRAN_TIME}`,
                            card_number: CARD_NUM,
                            product: PRODUCT,
                            station: STATION,
                            amount: formatAmount(ENTRY_AMOUNT),
                            rrn: RRN,
                            txn_type: TXN_TYPE,
                            op_type: OPERATION_TYPE,
                          })),
                        },
                      }),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
