import m from 'mithril';

const modal = {
  key: 'DEMO_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.loading = false;
  },
  view({ state }) {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', 'Modal Title (Probably through attrs)'),
                    m('button.close[type="button" data-dismiss="modal" aria-label="close"', {
                      onclick() {
                        modal.hide();
                      },
                    },
                    [
                      m('span[aria-hidden="true"]', '×'),
                    ]),
                  ]),
                m('.modal-body',
                  [
                    m('p', 'Modal content goes here'),
                  ]),
                m('.modal-footer',
                  [
                    m('button.btn.btn-secondary[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Close'),
                    m('button.btn.btn-brand[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Save'),
                    m(`button.btn.btn-primary${state.loading === true ? '.k-spinner.k-spinner--v2.k-spinner--right.k-spinner--lg.k-spinner--success' : ''}`, {
                      onclick() {
                        state.loading = true;

                        m.redraw();
                        // some async tings
                        setTimeout(() => {
                          state.loading = false;
                          m.redraw();
                          modal.hide();
                        }, 2000);
                      },
                    },
                    'Button',),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
