// translator.js
import translations from './translated.json';
console.log('Loaded translations:', translations);

const preferredLanguage = 'en'; // preferred language to fall back to

function setSelectedLanguage(language) {
  console.log(`Setting selected language to ${language}`);
  localStorage.setItem('selectedLanguage', language);
  location.reload();
  console.log('Language set, reloading page...');
}

function createLoggedObject(obj, language = localStorage.getItem('selectedLanguage') || 'en', fallback = false) {
  return new Proxy(obj, {
    get: (target, property) => {
      if (typeof target[property] === 'undefined') {
        console.log(`Property '${property}' not found in translations for ${language}`);
        if (!fallback && language !== preferredLanguage) {
          const fallbackObj = createLoggedObject(translations[preferredLanguage], preferredLanguage, true);
          const fallbackProperty = fallbackObj[property];
          if (typeof fallbackProperty === 'object') {
            return createLoggedObject(fallbackProperty, preferredLanguage, true);
          } else {
            return fallbackProperty;
          }
        } else {
          return ''; // or some default value
        }
      } else {
        // console.log(`Accessing translation for ${property}`);
        if (typeof target[property] === 'object') {
          return createLoggedObject(target[property], language, fallback);
        } else {
          return target[property];
        }
      }
    },
  });
}

const loggedTranslations = createLoggedObject(translations);


function t() {
  let selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
  // console.log(`Using language: ${selectedLanguage}`);

  document.documentElement.lang = selectedLanguage;
  return loggedTranslations[selectedLanguage];
}


export { t, setSelectedLanguage };
