/* eslint-disable no-param-reassign */
import m from 'mithril';

const modal = {
  key: 'SELECT_TYPE_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.selected_type = 'xl';
  },
  view({ attrs, state }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', 'Download File Type'),
                    ]),
                  m('.modal-body',
                    [

                        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                            m('form', {
                                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                              },
                              [
                                m('div', { class: 'mb-10' },
                                  [
                                      m('label', { class: 'required form-label' },
                                      'Select type'),
                                      m('select.form-select-sm', {
                                        style: 'width: 100%; min-width: 7rem;',
                                        value: state.selected_type || '',
                                        onchange: (e) => {
                                          state.selected_type = e.target.value;
                                        },
                                      },
                                      [
                                        m('option', { value: 'xl' }, 'Excel'),
                                        m('option', { value: 'pdf' }, 'PDF'),
                                      ]),
                                  ]),

                              ]),
                            m('br'),
                            m('div', { class: 'd-flex flex-column flex-sm-row' }, [
                              m('button.btn.btn-primary flex-fill mx-2', {
                                onclick() {
                                  attrs.handleAction(state.selected_type);
                                },
                              }, 'Proceed'),

                            ]),
                          ]),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
