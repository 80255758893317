import 'babel-polyfill';
import m from 'mithril';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

import pino from 'pino';
import defaultPage from './pages/home';
import reports from './pages/reports';
import dashboard from './pages/dashboard';
import stationFinder from './pages/station-finder';
import cardList from './pages/cards';
import driverCode from './pages/driver-codes';
import transactions from './pages/transactions';
import topUps from './pages/topups';
import cardToCard from './pages/card-to-card';
import cardToCardSingle from './pages/card-to-card-single';
import cardToCardBulk from './pages/card-to-card-bulk';
import auth from './pages/auth';
import signup from './pages/signup';
import cardActivation from './pages/activate-card';
import usermanagement from './pages/usermanagement';
import { getItemLocalStorage } from './utils/helper';
import stationDetails from './pages/station-details';
import dealerDashboard from './pages/dealer-dashboard';
import instituationConfig from './instituation-config';

const { ID } = instituationConfig[window.country || 'kenya'];

const logger = pino();

const accountData = getItemLocalStorage('accountData') || {};
window.logger = logger;

logger.info('app innitialized', { env: window.env }, window.config);

m.route.prefix('');
m.route(document.getElementById('app'), '/', {
  '/home': stationFinder,
  '/': auth,
  '/signup': signup,
  '/activate-card': cardActivation,
  '/stationFinder': stationFinder,
  '/cardList': cardList,
  '/driver-codes': driverCode,
  '/dashboard': dashboard,
  '/dealer-dashboard': dealerDashboard,
  '/transactions': accountData.USERTYPE === '@DEALER' ? stationDetails : transactions,
  '/cardToCard': cardToCard,
  '/cardToCard/single': cardToCardSingle,
  '/cardToCard/bulk': cardToCardBulk,
  '/reports': reports,
  '/userManagement': usermanagement,
  '/funds': ['RUBISKE', 'RUBISUG'].includes(ID) ? topUps : cardToCard,
});


// console disabling like facebook does, so that all console.logs just fail silently
