/* eslint-disable func-names */
import { formatAmount, formatTimestamp } from './helper';

const initChart = (chart, toggle, chartSelector, series, labels, initByDefault) => {
    const element = document.querySelector(chartSelector);

    if (!element) {
        return;
    }

    const height = parseInt(KTUtil.css(element, 'height'));
    const color = element.getAttribute('data-kt-chart-color');

    const labelColor = KTUtil.getCssVariableValue('--bs-gray-200');
    const borderColor = KTUtil.getCssVariableValue('--bs-border-dashed-color');
    const baseColor = KTUtil.getCssVariableValue(`--bs-${color}`);
    console.log('seriess:', series);

    const options = {
        series,
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height,
            toolbar: {
                show: true,
            },
        },
        legend: {
            show: true,
            position: 'top',
        },
        dataLabels: {
            enabled: true,
            position: 'top',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.4,
                opacityTo: 0.2,
                stops: [15, 120, 100],
            },
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [...series.map(col => col.borderColor)],
        },
        xaxis: {
            title: {
                text: 'Period',
            },
            // categories: labels,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            offsetX: 20,
            tickAmount: 4,
            labels: {
                rotate: 0,
                rotateAlways: false,
                show: true,
                style: {
                    colors: '#0f79ad',
                    fontSize: '12px',
                },
                formatter(value) {
                    return formatTimestamp(value);
                },
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: baseColor,
                    width: 1,
                    dashArray: 3,
                },
            },
        },
        yaxis: {
            labels: {
                formatter(value) {
                  return formatAmount(value);
                },
            },
            title: {
                text: 'Amount',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
            },
            axisBorder: {
              show: true,
              color: baseColor,
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter(val) {
                    return `${formatAmount(val)}`;
                },
            },
        },
        colors: [...series.map(col => col.borderColor)],
        grid: {
            borderColor,
            strokeDashArray: 3,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeColor: baseColor,
            strokeWidth: 3,
        },
    };

    chart.self = new ApexCharts(element, options);
    const tab = document.querySelector(toggle);
    console.log('tab:', tab);

    if (initByDefault === true) {
        // Set timeout to properly get the parent elements width
        setTimeout(() => {
            chart.self.render();
            chart.rendered = true;
        }, 200);
    }

    tab && tab.addEventListener('shown.bs.tab', () => {
        if (chart.rendered === false) {
            chart.self.render();
            chart.rendered = true;
        }
    });
};

export default initChart;
