// eslint-disable-next-line no-undef
// const { API_URL } = window.config;

export default {
    kenya: {
      ID: 'RUBISKE',
      CURRENCY: 'KSH',
      calling_code: '254',
      code: 'en-KE',
      contact: 'https://rubiskenya.com/contact-us/',
      selfreg: {
        development: 'https://cardapplication.rubiskenya.com',
        staging: 'https://cardapplication.rubiskenya.com',
        production: 'https://cardapplication.rubiskenya.com',
      },
    },
    uganda: {
      ID: 'RUBISUG',
      CURRENCY: 'UGX',
      calling_code: '256',
      code: 'en-UG',
      contact: 'https://rubisuganda.com/contact-us/',
      selfreg: {
        development: 'https://selfregug.volanedev.com/',
        staging: null,
        production: null,
      },
    },
    zambia: {
      ID: 'RUBISZM',
      CURRENCY: 'ZMW',
      calling_code: '260',
      code: 'en-ZK',
      contact: 'https://rubisuganda.com/contact-us/',
      selfreg: {
        development: 'https://cards.rubiszambia.com/loginLink.do',
        staging: 'https://cards.rubiszambia.com/loginLink.do',
        production: 'https://cards.rubiszambia.com/loginLink.do',
      },
    },
    rwanda: {
      ID: 'RUBISRW',
      CURRENCY: 'RWF',
      calling_code: '250',
      code: 'en-RW',
      contact: 'https://rubisrwanda.com/contact/',
      selfreg: {
        development: 'https://cards.rubisrwanda.com/loginLink.do',
        staging: 'https://cards.rubisrwanda.com/loginLink.do',
        production: 'https://cards.rubisrwanda.com/loginLink.do',
      },
    },
};
