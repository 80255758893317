/* eslint-disable no-param-reassign */
import m from 'mithril';
import { t } from '../translation-files/translator';
import { getItemLocalStorage, hasResource } from '../utils/helper';

export default {
    oninit: async ({ state, attrs }) => {
        const accountData = getItemLocalStorage('accountData');
        state.accountData = accountData;
        // m.redraw();
    },
    view({ state }) {
        // console.log(attrs)
        return m('div', { class: 'app-toolbar px-5 py-3 py-lg-6', id: 'kt_app_toolbar' },
            m('div', {
                class: 'app-container container-xxl d-flex align-items-start align-items-md-center flex-stack rounded bg-gray-100 d-flex flex-stack flex-wrap mb-9',
                id: 'kt_app_toolbar_container',
                style: {
                    'box-shadow': '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    padding: '1.2rem',
                    'background-color': '#fffff !important',
                },
            },
                [
                    // <!--begin::Title-->
                    m('div', { class: 'page-title d-flex flex-column justify-content-center flex-wrap me-3' },
                        [
                            m('h1', { class: 'page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0' },
                                `${state.accountData?.CUST_NAME || ''}`),
                            m('ul', { class: 'breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1' },
                                [
                                    m('li', { class: 'breadcrumb-item text-muted' },
                                        m('span', { class: 'text-muted text-hover-primary' },
                                            t().common.account_type)),
                                    m('li', { class: 'breadcrumb-item' },
                                        m('span', { class: 'bullet bg-gray-500 w-5px h-2px' })),
                                    m('li', { class: 'breadcrumb-item text-muted' }, state.accountData?.CUST_TYPE || ''),
                                ]),
                        ]),
                    // <!--end::Page title-->
                    // <!--begin:Tab content-->
                    m('div', {
                        id: 'kt_user_profile_nav',
                        // 'data-kt-page-scroll-position': '400',
                        // 'data-kt-sticky': 'true',
                        // 'data-kt-sticky-name': 'sticky-profile-navs',
                        // 'data-kt-sticky-offset': "{default: false, lg: '400px'}",
                        // 'data-kt-sticky-width': "{target: '#kt_user_profile_panel'}",
                        // 'data-kt-sticky-left': '50%',
                        // 'data-kt-sticky-top': '70px',
                        // 'data-kt-sticky-animation': 'false',
                        // 'data-kt-sticky-zindex': '95',
                    },
                        m('ul', { class: 'nav flex-wrap border-transparent' },
                            [
                                [{
                                    label: t().home_acc_info.subheader_labels.dashboard,
                                    route: state.accountData.USERTYPE === '@DEALER' ? '/dealer-dashboard' : '/dashboard',
                                    routesToActivate: [...(state.accountData.USERTYPE === '@DEALER' ? ['/dealer-dashboard'] : ['/dashboard'])],
                                  },
                                  ...(state.accountData.USERTYPE === '@DEALER' ? [] : [{
                                    label: t().home_acc_info.subheader_labels.home,
                                    route: '/home',
                                    routesToActivate: ['/home', '/stationFinder'],
                                  }]),
                                  ...(hasResource('CLI_CARD_LIST') || hasResource('CARD_MYCARD') ? [{
                                    label: t().home_acc_info.subheader_labels.cards,
                                    route: '/cardList',
                                    routesToActivate: ['/cardList', '/driver-codes'],
                                  }] : []),
                                //   {
                                //     label: t().home_acc_info.subheader_labels.transactions,
                                //     route: '/transactions',
                                //     routesToActivate: ['/transactions'],
                                // },
                                ...(state.accountData.USERTYPE === '@CLIENT' && state.accountData.CUST_TYPE === 'Prepaid' ? [{
                                    label: t().home_acc_info.subheader_labels.funds,
                                    route: state.globalCardType === '@POST' ? '/cardToCard' : '/funds',
                                    routesToActivate: ['/funds', '/cardToCard'],
                                }] : []),
                                ].map(({ label, route, routesToActivate = [] }) => {
                                    const isActive = routesToActivate.includes(m.route.get());
                                    const className = `nav-item my-1 ${isActive ? 'activex' : ''}`;
                                    return m('li', {
                                        class: className,
                                        style: {
                                            cursor: 'pointer',
                                        },
                                    }, [
                                        m('a', {
                                            class: `btn btn-sm btn-color-gray-600 bg-state-body btn-active-color-gray-800 fw-bolder fw-bold fs-6 fs-lg-base nav-link px-3 px-lg-4 mx-1 ${isActive ? 'activex' : ''}`,
                                            onclick: (e) => {
                                                e.preventDefault();
                                                m.route.set(route);
                                                // location.reload();
                                            },
                                        },
                                            m('span', { style: isActive ? { fontWeight: 'bold', color: '#0D9488' } : null }, label)),
                                        isActive ? m.trust(`<svg width="auto" height="5" viewBox="0 0 109 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.537598" width="108.29" height="5" rx="2.5" fill="#FACC15" />
                                        </svg>`) : null,

                                    ]);
                                }),
                            ])),
                    // <!--end:Tab content-->
                ]));
    },
};
