/* eslint-disable no-param-reassign */
import m from 'mithril';
import modalCloseButton from '../../../components/modalCloseButton';
import { isValidAmount, getItemLocalStorage } from '../../../utils/helper';
import apirequest from '../../../utils/api-service';

const modal = {
  key: 'GLOBAL_LIMIT_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state, attrs }) {
    const accountData = getItemLocalStorage('accountData') || {};
    state.loading = false;
    state.form_data = {
      amount: attrs.amount || 0,
    };
    state.form_errors = {
      amount: false,
    };
    state.form_valid = false;
    state.success = false;
    state.successMessage = '';
    state.error = false;
    state.errorMessage = '';
    state.handleInputChange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      state.handleInputValidations();
    };
    state.handleInputValidations = () => {
      state.form_errors.amount = !isValidAmount(state.form_data.amount);
      state.form_valid = !state.form_errors.amount;
    };

    if (attrs.amount) {
        state.handleInputValidations();
    }

    state.handleSubmit = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/ClientController/addThreshold`, 'POST', {
          THRESHOULD_AMT: state.form_data.amount,
          USERCODE: accountData.USERNAME,
          EMAIL: '',
          ACCTNO: accountData.ACCTNO,
          CARDNO: accountData.CARDNO,
        });
        state.loading = false;

        if (respcode === 0) {
          state.success = true;
          state.successMessage = 'Global limit set successfully';
          attrs.refresh();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to set global limit';
      }
    };

    // m.redraw();
  },
  view({ state }) {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog.modal-dialog-centered[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', 'Update global limit'),
                    m('div', { class: 'btn btn-icon btn-sm btn-active-light-primary ms-2', 'data-bs-dismiss': 'modal', 'aria-label': 'Close' },
                      m(modalCloseButton)),
                  ]),
                m('.modal-body',
                  [
                    state.success && m('div', { class: 'text-success fs-5' }, state.successMessage),
                    m('form', [
                      m('.row', [
                        m('.col-md-12.col-sm-12 py-2', [
                          m('div.form-group.mb-2', [
                            m('label', 'Amount:'),
                            m('input.form-control[type="text"]', {
                              placeholder: 'Enter amount',
                              name: 'amount',
                              value: state.form_data.amount,
                              oninput: e => state.handleInputChange(e),
                            }),
                            state.form_errors.amount && m('div', { class: 'text-danger fs-6' }, 'Enter valid amount. Should be a number more than 0'),
                          ]),
                        ]),
                      ]),
                    ]),
                    state.error && m('div', { class: 'text-danger fs-5' }, state.errorMessage),
                  ]),
                  m('.modal-footer',
                  [
                    m('button.btn.btn-secondary[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Close'),
                    m(`button.btn.btn-primary${state.loading === true ? '.k-spinner.k-spinner--v2.k-spinner--right.k-spinner--lg.k-spinner--success' : ''}`, {
                      disabled: !state.form_valid || state.loading,
                      async onclick() {
                        state.handleSubmit();
                      },
                    },
                    state.loading ? [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])] : 'Submit'),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
