// eslint-disable-next-line no-undef
// const { API_URL } = window.config;

export default {
  production: {
    apiUrl: 'API_URL',
    appUrl: 'https://braiven.io',
    log_level: 'silent',
    API_URL: 'https://rubiscard.rubiskenya.com/kenolkobil',
    SELFREG: 'https://cardapplication.rubiskenya.com',
    DEALERPORTAL: 'https://dealer.rubiskenya.com/',
  },
  staging: {
    apiUrl: 'API_URL',
    appUrl: 'http://localhost:3000',
    log_level: undefined,
    API_URL: 'https://rubis-admin-staging.volanedev.com/kenolkobil',
    SELFREG: 'https://cardapplication.rubiskenya.com',
    DEALERPORTAL: 'http://3.226.115.177:8080/',
  },
  development: {
    apiUrl: 'API_URL',
    appUrl: 'http://localhost:3000',
    log_level: undefined,
    API_URL: 'https://rubisuat.volanedev.com/rubis',
    SELFREG: 'https://cardapplication.rubiskenya.com/',
    DEALERPORTAL: 'https://dealer.rubiskenya.com/kobil/',
  },
};
