/* eslint-disable func-names */
import { runQuery } from './fetchData';

const Data = (function () {
  let instance;
  let clients;
  let selectedClient;
  let user;

  function createInstance() {
    const object = {};
    return object;
  }

  const getClientsData = async function () {
    if (!clients) {
      const { data: response } = await runQuery(``);

      // eslint-disable-next-line no-undef
      const selectedItem = localStorage.getItem('selectedItem');

      selectedClient = response.user.clients.filter(
        fil => fil.id === selectedItem,
      )[0]
        ? response.user.clients.filter(fil => fil.id === selectedItem)[0]
        : response.user.clients[0];

      const {
        user: { clients: clientData },
      } = response;
      const { user: userInfo } = response;

      user = userInfo;
      clients = clientData;

      return clientData;
    }

    return clients;
  };

  return {
    getInstance() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    async getUserData() {
      if (!user) {
        await getClientsData();
        return user;
      }
      return user;
    },
    async getClientData() {
      if (!selectedClient) {
        await getClientsData();
        return selectedClient;
      }
      return selectedClient;
    },
    getClientsData: async () => getClientsData(),
    async changeSelectedClientData({ id }) {
      selectedClient = clients.filter(c => c.id === id).pop();
    },
  };
}());

export default Data;
