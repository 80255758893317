/* eslint-disable no-param-reassign */
import m from 'mithril';
import header from '../../components/header';
import sidebar from '../../components/sidebar';
import toastComponent from '../../components/toast';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, formatAmount, isValidAmount, setToLocalStorage } from '../../utils/helper';
import loadingModal from './modals/loading';
import successModal from './modals/success';
import failModal from './modals/fail';
import instituationConfig from '../../instituation-config';

const { ID } = instituationConfig[window.country || 'kenya'];

export default {
  async oninit({ state }) {
    const accountData = getItemLocalStorage('accountData') || {};
    const globalCardType = getItemLocalStorage('globalCardType');
    const cardListData = getItemLocalStorage('cardListData');
    
    const { CARDLIST, CARD_LIST } = cardListData || {};
    state.globalCardType = globalCardType || '';
    state.cards = CARDLIST ? CARDLIST : (CARD_LIST ? CARD_LIST : []);
    state.selectedFromCard = null;
    state.selectedToCard = null;
    state.amount = 0;
    state.toCardNewBalance = 0,
    state.fromCardNewBalance = 0;
    state.formErrors = {},
    state.toastType = 1;
    state.toastMessage = '';
    state.statements = [];
    state.loading = true;
    state.handlechange = (e) => {
      state[e.target.name] = e.target.value;
      if (e.target.name === 'amount') state.calculateNewBalances()
      state.validateFormItems()
    }
    state.validateFormItems = () => {
      state.formErrors = {
        amount: !isValidAmount(state.amount)
      }
    }
    state.calculateNewBalances = () => {
      if (Number(state.selectedFromCard.BALANCE) > state.amount) {
        state.toCardNewBalance = Number(state.selectedToCard?.BALANCE || 0) + Number(state.amount)
        state.fromCardNewBalance = Number(state.selectedFromCard?.BALANCE || 0) - Number(state.amount)
      } else {
        state.toCardNewBalance = Number(state.selectedToCard?.BALANCE || 0)
        state.fromCardNewBalance = Number(state.selectedFromCard?.BALANCE || 0)
      }
    }

    state.handleSubmit = async () => {
      try {
        loadingModal.show();

        const {
          respmsg, respcode, respbody
        } = await apirequest(`/ClientController/cardtocard`, 'POST', {
          CUSTID: accountData.ACCTNO, 
          CARDNO: state.selectedFromCard.ENCCARDNUMBER,
          TOCARDNO: state.selectedToCard.ENCCARDNUMBER,
          AMOUNT: state.amount,
          USERNAME: accountData.USERNAME,
        });
        loadingModal.hide();

        if (respcode === 0) {
          state.cards = state.cards.map(card => {
            if (card.CARD_SERIAL_NUM === state.selectedFromCard.CARD_SERIAL_NUM) {
              return {...card, BALANCE: state.fromCardNewBalance }
            }
            if (card.CARD_SERIAL_NUM === state.selectedToCard.CARD_SERIAL_NUM) {
              return {...card, BALANCE: state.toCardNewBalance }
            }

            return card;
          })

          setToLocalStorage('cardListData', { CARDLIST: state.cards })
          state.toastType = 1;
          state.toastMessage = `Transfer processed successfully. Refrence no ${respbody.REFNO}`
          toastComponent.show()
          localStorage.removeItem('cardStatements');
          setTimeout(() => {
            m.route.set('/cardToCard');
          }, 4000)
          m.redraw();
        } else {
          state.toastType = 0;
          state.toastMessage = respmsg
          failModal.show()
          m.redraw();
        }
      } catch (error) {
        console.log(error)
        loadingModal.hide();
        failModal.show()
        m.redraw();
      }
    }

    state.fetchCardList = async () => {
      try {
        state.loading = state.cards.length == 0 ? true : false;
        const {
          respbody,
          respcode,
          respmsg
        } = await apirequest(`/ClientController/getCardList`, 'POST', {
            ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });
        if (respcode === 0) {
          state.cards = respbody?.CARDLIST?.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16') || [];
        }
        state.loading = false;
        m.redraw();
        return;
      } catch (error) {
        state.loading = false;
        throw error;
      }
    };

    if (CARDLIST) {
      state.cards = CARDLIST?.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16') || [];
      state.loading = false;
    } else {
      state.fetchCardList();
    }

    m.redraw();
  },
  // disable due to failures on some browsers
  oncreate() {},
  view({ state }) {
    return [
      m(toastComponent, { type: state.toastType, message: state.toastMessage}),
      m(loadingModal),
      m(successModal),
      m(failModal, { message: state.toastMessage }),
      state.loading ? m(loader) : [],
      // <!--begin::Header-->
      m(header, {
        logos: {
                  dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            m('div', { class: 'separator my-10' }),

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [
                    ...(state.globalCardType === '@PRE' && ID === 'RUBISKE' ? [{
                    label: t().card_to_card.sidebar_labels.top_up,
                    icon: 'bi bi-book',
                    route: '/funds',
                  }] :[]),
                  {
                    label: t().card_to_card.sidebar_labels.card_to_card,
                    icon: 'bi bi-book',
                    route: '/cardToCard',
                    routesToActivate: ['/funds', '/cardToCard/single', '/cardToCard/bulk'],
                  }],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', { class: 'd-flex flex-column container-xxl' },
                  m('div', {
                    class: 'app-content',
id: 'kt_app_content',
style: {
                      'flex-grow': 1,
                      'flex-basis': 'auto',
                    },
                  },
                    m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' },
                      [
                        state.success && m('div', { class: 'text-success text-center fs-5 py-2' }, state.successMessage),
                        state.error && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage),
                        m('div', { class: 'row' }, [
                          m('div', { class: 'col-12' }, [
                            m('div', { class: 'card mb-3' }, [
                              m('div', { class: 'card-header' }, [
                                m('h1', { class: 'card-title' }, 'Card To Card Transaction'),
                              ]),

                              m('div', { class: 'card-body' }, [
                                m('div', { class: 'row' }, [
                                  m('div', { class: 'col-sm-6 col-md-6 col-lg-6' }, [
                                    m('div', { class: 'd-flex justify-content-start', 'data-kt-customer-table-toolbar': 'base', style: { 'padding-top': '10px' } }, [
                                      m('div', { class: 'form-check form-check-inline' }, [
                                        m('div', { class: 'form-check form-check-custom form-check-solid' },
                                          [
                                            m('input', {
                                              class: 'form-check-input',
type: 'checkbox',
value: '1',
checked: true,
id: 'flexCheckDefault',
onchange: () => {
                                                m.route.set('/cardToCard/single');
                                                location.reload();
                                              },
                                            }),
                                            m('label', { class: 'form-check-label', for: 'flexCheckDefault' },
                                              t().single_card_to_card_complete.toolbar_actions.single),
                                          ]),
                                      ]),
                                      m('div', { class: 'form-check form-check-inline' }, [
                                        m('div', { class: 'form-check form-check-custom form-check-solid' },
                                          [
                                            m('input', {
                                              class: 'form-check-input',
                                              type: 'checkbox',
                                              value: '1',
                                              id: 'flexCheckDefault',
                                              onchange: () => {
                                                m.route.set('/cardToCard/bulk');
                                              },
                                            }),
                                            m('label', { class: 'form-check-label', for: 'flexCheckDefault' },
                                              t().single_card_to_card_complete.toolbar_actions.bulk),
                                          ]),
                                      ]),
                                    ]),
                                  ]),
                                  m('div', { class: 'col-sm-12 col-md-6 col-lg-6' }, [
                                    m('div', { class: 'd-flex justify-content-end', 'data-kt-customer-table-toolbar': 'base' },
                                      m('button', { class: 'btn btn-primary', onclick: () => {
                                        m.route.set('/cardToCard');
                                      } }, [
                                        m('i', { class: 'bi bi-arrow-left' }),
                                        'Back',
                                      ])),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          m('div', { class: 'col-md-6' }, [
                            m('div', {
                              class: 'card mb-3',
                            }, [
                              m('div', { class: 'card-body' }, [
                                m('h5', { class: 'card-title' }, 'From Card'),
                                m('select', {
                                  class: 'form-select',
'aria-label': 'Select Card',
onchange: (e) => {
  const card = state.cards.find(item => item.ENCCARDNUMBER === e.target.value);
 state.selectedFromCard = card || null;
 state.fromCardNewBalance = Number(card?.BALANCE || 0)
 card && state.calculateNewBalances();
 m.redraw();
},
value: state.selectedFromCard?.ENCCARDNUMBER || null,
                                },
                                m('option', { value: null, disabled: false }, 'Select from card'),
                                  state.cards.map(card => 
                                    m('option', {
                                       value: card.ENCCARDNUMBER, 
                                       disabled: state.selectedToCard && state.selectedToCard.ENCCARDNUMBER === card.ENCCARDNUMBER
                                       }, `${card.CARDNUMBER} - ${card.CARDNAME}${card.VEHREGNUM ? ' - ' + card.VEHREGNUM : ''}`))),
                                state.selectedFromCard == null ? null : [
                                  m('div', { class: 'separator my-5' }),
                                  m('div', { class: 'table-responsive' },
                                    m('table', { class: 'table table-row-dashed table-row-gray-300' },
                                      [
                                        m('tbody',
                                          [
                                            m('tr',
                                              [
                                                m('td', m('b', 'Name')),
                                                m('td', state.selectedFromCard.CARDNAME),
                                              ]),
                                            m('tr',
                                              [
                                                m('td', m('b', 'Card Number')),
                                                m('td', state.selectedFromCard.CARDNUMBER),
                                              ]),
                                            m('tr',
                                              [
                                                m('td', m('b', 'Balance')),
                                                m('td', formatAmount(state.selectedFromCard.BALANCE)),
                                              ]),
                                          ]),
                                      ])),
                                      state.selectedToCard == null ? null : [m('div', { class: 'mb-10' },
                                        [
                                          m('label', { class: 'form-label' },
                                            'New Balance'),
                                          m('input', {
          class: 'form-control', type: 'text', placeholder: '40560', value: formatAmount(state.fromCardNewBalance), disabled: true
          }),
                                          m('div', {
          'data-lastpass-icon-root': '',
          style: {
          position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important',
          },
          }),
                                        ])],
                                ]]),
                            ]),

                          ]),
                          m('div', { class: 'col-md-6' }, [
                            m('div', {
                              class: 'card mb-3',
                            }, [
                              m('div', { class: 'card-body' }, [
                                m('h5', { class: 'card-title' }, 'To Card'),
                                m('select', {
                                  class: 'form-select',
'aria-label': 'Select Card',
onchange: (e) => {
  const card = state.cards.find(item => item.ENCCARDNUMBER === e.target.value);
  state.selectedToCard = card || null;
  state.toCardNewBalance = Number(card?.BALANCE || 0)
  card && state.calculateNewBalances()
  m.redraw();
},
value: state.selectedToCard?.ENCCARDNUMBER || null,
disabled: !state.selectedFromCard
                                },
                                  m('option', { value: null, disabled: false }, 'Select to card'),
                                  state.cards.map(card => m('option', {
                                     value: card.ENCCARDNUMBER,
                                     disabled: state.selectedFromCard && state.selectedFromCard.ENCCARDNUMBER === card.ENCCARDNUMBER
                                    }, `${card.CARDNUMBER} - ${card.CARDNAME}${card.VEHREGNUM ? ' - ' + card.VEHREGNUM : ''}`))),
                                !state.selectedToCard ? null : [m('div', { class: 'separator my-5' }),
                                m('div', { class: 'table-responsive' },
                                  m('table', { class: 'table table-row-dashed table-row-gray-300' },
                                    [
                                      m('tbody',
                                        [
                                          m('tr',
                                            [
                                              m('td', m('b', 'Name')),
                                              m('td', state.selectedToCard.CARDNAME),
                                            ]),
                                          m('tr',
                                            [
                                              m('td', m('b', 'Card Number')),
                                              m('td', state.selectedToCard.CARDNUMBER),
                                            ]),
                                          m('tr',
                                            [
                                              m('td', m('b', 'Balance')),
                                              m('td', formatAmount(state.selectedToCard.BALANCE)),
                                            ]),
                                        ]),
                                    ])),
                                m('div', { class: 'mb-10' },
                                  [
                                    m('label', { class: 'form-label' },
                                      'New Balance'),
                                    m('input', {
 class: 'form-control', type: 'text', placeholder: '20,000', value: formatAmount(state.toCardNewBalance), disabled: true
}),
                                    m('div', {
 'data-lastpass-icon-root': '',
style: {
 position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important',
},
}),
                                  ])],
                              ]),
                            ]),

                          ]),
                          !state.selectedToCard ? null : [m('div', { class: 'row align-items-end gx-5 col-md-12' }, [
                            m('div', { class: 'col-md-6 mb-2' },
                              [
                                m('label', { class: 'form-label' },
                                  'Enter Amount'),
                                m('input', {
class: 'form-control', type: 'number', placeholder: 'ksh.100', name: 'amount', value: state.amount, oninput: (e) => state.handlechange(e)
}),
(state.formErrors.amount || Number(state.selectedFromCard.BALANCE) < state.amount) ? m('div', {class: 'text-danger py-2 fs-5'}, 'Enter valid amount. Should be a number more than 0 and less than From Card balance') : [],
                                m('div', { 'data-lastpass-icon-root': '',
                                  style: {
                                    position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important',
                                  },
                                }),
                              ]),
                            m('div', { class: 'col-md-6' }, [
                              m('button', { class: 'btn w-100', style: { backgroundColor: 'teal', color: 'white' }, disabled: state.formErrors.amount || Number(state.selectedFromCard.BALANCE) < state.amount || state.amount === 0, onclick: () => { state.handleSubmit()} },
                                'Complete'),
                            ])
                          ])],
                        ]),

                      ]))),
                // <!--end::Content-->


              ]),

              // <!--end::Inbox App - Messages -->

            ]),

            // <!--end::Content-->
          ]),
          m(footer),

        ]),

      ]),

    ];
  },
};
