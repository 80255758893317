import m from 'mithril';

const modal = {
  key: 'DEMO_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.loading = false;
  },
  oncreate() {
    // modal.show()

    $('#k_form_1').validate({
      // define validation rules
      rules: {
        email: {
          required: true,
          email: true,
        },
        url: {
          required: true,
        },
        digits: {
          required: true,
          digits: true,
        },
        creditcard: {
          required: true,
          creditcard: true,
        },
        phone: {
          required: true,
          phoneUS: true,
        },
        option: {
          required: true,
        },
        options: {
          required: true,
          minlength: 2,
          maxlength: 4,
        },
        memo: {
          required: true,
          minlength: 10,
          maxlength: 100,
        },

        checkbox: {
          required: true,
        },
        checkboxes: {
          required: true,
          minlength: 1,
          maxlength: 2,
        },
        radio: {
          required: true,
        },
      },

      // display error alert on form submit
      invalidHandler(event, validator) {
        const alert = $('#k_form_1_msg');
        alert.parent().removeClass('k-hidden');
        KUtil.scrollTo('k_form_1', -200);
      },

      submitHandler(form) {
        // form[0].submit(); // submit the form
        alert('submitting the glorious form');
      },
    });
  },
  view({ state, attrs: { title } }) {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog.modal-lg[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', title),
                    m('button.close[type="button" data-dismiss="modal" aria-label="close"', {
                      onclick() {
                        modal.hide();
                      },
                    },
                    [
                      m('span[aria-hidden="true"]', '×'),
                    ]),
                  ]),
                m('.modal-body',
                  [
                    m("form.k-form.k-form--label-right[id='k_form_1']",
                      [
                        m('.k-portlet__body',
                          [
                            m('.form-group.form-group-last.k-hide',
                              m(".alert.alert-danger[id='k_form_1_msg'][role='alert']",
                                [
                                  m('.alert-icon',
                                    m('i.flaticon-warning')),
                                  m('.alert-text',
                                    'Oh snap! Change a few things up and try submitting again.'),
                                  m('.alert-close',
                                    m("button.close[aria-label='Close'][data-dismiss='alert'][type='button']",
                                      m("span[aria-hidden='true']",
                                        m('i.la.la-close')))),
                                ])),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Email *'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m("input.form-control[name='email'][placeholder='Enter your email'][type='text']"),
                                    m('span.form-text.text-muted',
                                      "We'll never share your email with anyone else."),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'URL *'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m('.input-group',
                                      [
                                        m("input.form-control[name='url'][placeholder='Enter your url'][type='text']"),
                                        m('.input-group-append',
                                          m('span.input-group-text',
                                            '.via.com')),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please enter your website URL.'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Digits'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m('.k-input-icon.k-input-icon--left',
                                      [
                                        m("input.form-control[name='digits'][placeholder='Enter digits'][type='text']"),
                                        m('span.k-input-icon__icon.k-input-icon__icon--left',
                                          m('span',
                                            m('i.la.la-calculator'))),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please enter only digits'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Credit Card'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m('.input-group',
                                      [
                                        m("input.form-control[name='creditcard'][placeholder='Enter card number'][type='text']"),
                                        m('.input-group-append',
                                          m('span.input-group-text',
                                            m('i.la.la-credit-card'))),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please enter your credit card number'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'US Phone'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m('.input-group',
                                      [
                                        m("input.form-control[name='phone'][placeholder='Enter phone'][type='text']"),
                                        m('.input-group-append',
                                          m("a.btn.btn-brand.btn-icon[href='#']",
                                            m('i.la.la-phone'))),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please enter your US phone number'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Option *'),
                                m('.col-lg-9.col-md-9.col-sm-12.form-group-sub',
                                  [
                                    m("select.form-control[name='option']",
                                      [
                                        m("option[value='']",
                                          'Select'),
                                        m('option',
                                          '1'),
                                        m('option',
                                          '2'),
                                        m('option',
                                          '3'),
                                        m('option',
                                          '4'),
                                        m('option',
                                          '5'),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please select an option.'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Options *'),
                                m('.col-lg-9.col-md-9.col-sm-12.form-group-sub',
                                  [
                                    m("select.form-control[multiple=''][name='options'][size='5']",
                                      [
                                        m('option',
                                          '1'),
                                        m('option',
                                          '2'),
                                        m('option',
                                          '3'),
                                        m('option',
                                          '4'),
                                        m('option',
                                          '5'),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please select at least one or maximum 4 options'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Memo *'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m("textarea.form-control[name='memo'][placeholder='Enter a menu'][rows='8']"),
                                    m('span.form-text.text-muted',
                                      'Please enter a menu within text length range 10 and 100.'),
                                  ]),
                              ]),
                            m('.k-separator.k-separator--border-dashed.k-separator--space-xl'),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Checkbox *'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m('.k-checkbox-inline',
                                      m('label.k-checkbox',
                                        [
                                          m("input[name='checkbox'][type='checkbox']"),
                                          'Tick me',
                                          m('span'),
                                        ])),
                                    m('span.form-text.text-muted',
                                      'Please tick the checkbox'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Checkboxes *'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m('.k-checkbox-list',
                                      [
                                        m('label.k-checkbox',
                                          [
                                            m("input[name='checkboxes'][type='checkbox']"),
                                            'Option 1',
                                            m('span'),
                                          ]),
                                        m('label.k-checkbox',
                                          [
                                            m("input[name='checkboxes'][type='checkbox']"),
                                            'Option 2',
                                            m('span'),
                                          ]),
                                        m('label.k-checkbox',
                                          [
                                            m("input[name='checkboxes'][type='checkbox']"),
                                            'Option 3',
                                            m('span'),
                                          ]),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please select at lease 1 and maximum 2 options'),
                                  ]),
                              ]),
                            m('.form-group.row',
                              [
                                m('label.col-form-label.col-lg-3.col-sm-12',
                                  'Radios *'),
                                m('.col-lg-9.col-md-9.col-sm-12',
                                  [
                                    m('.k-radio-inline',
                                      [
                                        m('label.k-radio',
                                          [
                                            m("input[name='radio'][type='checkbox']"),
                                            'Option 1',
                                            m('span'),
                                          ]),
                                        m('label.k-radio',
                                          [
                                            m("input[name='radio'][type='checkbox']"),
                                            'Option 2',
                                            m('span'),
                                          ]),
                                        m('label.k-radio',
                                          [
                                            m("input[name='radio'][type='radio']"),
                                            'Option 3',
                                            m('span'),
                                          ]),
                                      ]),
                                    m('span.form-text.text-muted',
                                      'Please select an option'),
                                  ]),
                              ]),
                          ]),
                        m('.k-portlet__foot',
                          m('.k-form__actions',
                            m('.row',
                              m('.col-lg-9.ml-lg-auto',
                                [
                                  m("button.btn.btn-accent[type='submit']",
                                    'Validate'),
                                  m("button.btn.btn-secondary[type='reset']",
                                    'Cancel'),
                                ])))),
                      ]),
                  ]),
                m('.modal-footer',
                  [
                    m('button.btn.btn-secondary[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Close'),
                    m('button.btn.btn-brand[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Save'),
                    m(`button.btn.btn-primary${state.loading === true ? '.k-spinner.k-spinner--v2.k-spinner--right.k-spinner--lg.k-spinner--success' : ''}`, {
                      onclick() {
                        state.loading = true;

                        m.redraw();
                        // some async tings
                        setTimeout(() => {
                          state.loading = false;
                          m.redraw();
                          modal.hide();
                        }, 2000);
                      },
                    },
                    'Button'),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
