import m from 'mithril';
import modalCloseButton from '../../../components/modalCloseButton';

const modal = {
  key: 'EDIT_USER_AUTH_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.loading = false;
  },
  view({ state }) {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog.modal-dialog-centered[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', 'Edit User'),
                    m("div", { "class": "btn btn-icon btn-sm btn-active-light-primary ms-2", "data-bs-dismiss": "modal", "aria-label": "Close" },
                      m(modalCloseButton)
                    )
                  ]),
                m('.modal-body',
                  [
                    m('form', [
                      m('.row', [
                        m('.col-md-6.col-sm-12', [
                          m('div.form-group', [
                            m('label', 'First Name:'),
                            m('input.form-control[type="text"]', {
                              placeholder: 'Enter first name',
                              oninput: (e) => state.driverFirstName = e.target.value,
                            }),
                          ]),
                          m('div.form-group', [
                            m('label', 'Mobile Number:'),
                            m('input.form-control[type="text"]', {
                              placeholder: 'Enter mobile number',
                              oninput: (e) => state.driverMobileNumber = e.target.value,
                            }),
                          ]),
                        ]),
                        m('.col-md-6.col-sm-12', [
                          m('div.form-group', [
                            m('label', 'Last Name:'),
                            m('input.form-control[type="text"]', {
                              placeholder: 'Enter last name',
                              oninput: (e) => state.driverLastName = e.target.value,
                            }),
                          ]),
                          m('div.form-group', [
                            m('label', 'Email:'),
                            m('input.form-control[type="text"]', {
                              placeholder: 'Enter email',
                              oninput: (e) => state.driverEmail = e.target.value,
                            }),
                          ]),
                        ]),
                      ]),
                      m("br"),
                      m('div.form-group', [
                        m('label', 'Profile:'),
                        m('select.form-control', {
                          onchange: (e) => state.driverPhoneNumber = e.target.value,
                        }, [
                          m('option', { value: '' }, 'Select a profile'),
                          [
                            { id: Math.random(), name: `Profile ${Math.floor(Math.random() * 100)}` },
                            { id: Math.random(), name: `Profile ${Math.floor(Math.random() * 100)}` },
                            { id: Math.random(), name: `Profile ${Math.floor(Math.random() * 100)}` }
                          ].map((profile) => m('option', { value: profile.id }, profile.name)),
                        ]),
                      ]),
                      m("br"),
                      m('div.form-group', [
                        m('label', 'Channel:'),
                        m('select.form-control', {
                          oninput: (e) => state.driverName = e.target.value,
                        }, [
                          m('option', { value: '' }, 'Select a channel'),
                          [
                            { id: Math.random(), name: `Channel ${Math.floor(Math.random() * 100)}` },
                            { id: Math.random(), name: `Channel ${Math.floor(Math.random() * 100)}` },
                            { id: Math.random(), name: `Channel ${Math.floor(Math.random() * 100)}` },
                            { id: Math.random(), name: `Channel ${Math.floor(Math.random() * 100)}` },
                            { id: Math.random(), name: `Channel ${Math.floor(Math.random() * 100)}` },
                          ].map((channel) => m('option', { value: channel.id }, channel.name)),
                        ]),
                      ]),
                      m("br"),
                    ]),
                  ]),
                  m('.modal-footer',
                  [
                    m('button.btn.btn-secondary[type="button]', {
                      onclick() {
                        modal.hide();
                      },
                    }, 'Close'),
                    m(`button.btn.btn-primary${state.loading === true ? '.k-spinner.k-spinner--v2.k-spinner--right.k-spinner--lg.k-spinner--success' : ''}`, {
                      async onclick() {
                        modal.hide();
                      },
                    },
                    'Submit'),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
