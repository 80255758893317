import m from 'mithril';

export default {
  view: () => {
    const isDarkMode = localStorage.getItem('data-bs-theme') == 'dark';
    const fillColor = isDarkMode ? 'white' : 'black';

    return m.trust(`
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 19.25L13 14.75L17.5 19.25L19.25 17.5L14.75 13L19.25 8.5L17.5 6.75L13 11.25L8.5 6.75L6.75 8.5L11.25 13L6.75 17.5L8.5 19.25ZM13 25.5C11.2708 25.5 9.64583 25.1717 8.125 24.515C6.60417 23.8583 5.28125 22.9679 4.15625 21.8438C3.03125 20.7188 2.14083 19.3958 1.485 17.875C0.829167 16.3542 0.500833 14.7292 0.5 13C0.5 11.2708 0.828333 9.64583 1.485 8.125C2.14167 6.60417 3.03208 5.28125 4.15625 4.15625C5.28125 3.03125 6.60417 2.14083 8.125 1.485C9.64583 0.829167 11.2708 0.500833 13 0.5C14.7292 0.5 16.3542 0.828333 17.875 1.485C19.3958 2.14167 20.7188 3.03208 21.8438 4.15625C22.9688 5.28125 23.8596 6.60417 24.5162 8.125C25.1729 9.64583 25.5008 11.2708 25.5 13C25.5 14.7292 25.1717 16.3542 24.515 17.875C23.8583 19.3958 22.9679 20.7188 21.8438 21.8438C20.7188 22.9688 19.3958 23.8596 17.875 24.5162C16.3542 25.1729 14.7292 25.5008 13 25.5ZM13 23C15.7917 23 18.1562 22.0312 20.0938 20.0938C22.0312 18.1562 23 15.7917 23 13C23 10.2083 22.0312 7.84375 20.0938 5.90625C18.1562 3.96875 15.7917 3 13 3C10.2083 3 7.84375 3.96875 5.90625 5.90625C3.96875 7.84375 3 10.2083 3 13C3 15.7917 3.96875 18.1562 5.90625 20.0938C7.84375 22.0312 10.2083 23 13 23Z" fill="${fillColor}"/>
      </svg>
    `);
  }
};
