/* eslint-disable no-param-reassign */
import m from 'mithril';
import header from '../../components/header';
import toolbar from '../../components/toolbar';
import sidebar from '../../components/sidebar';
import table_data from '../../components/table_data';
import loader from '../../components/loader';

import { t } from '../../translation-files/translator';
import userManagementModal from './modals/addUser';
import userManagementEditModal from './modals/editUser';
import deleteUser from './modals/deleteUser';
import footer from '../../components/footer';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage } from '../../utils/helper';

export default {
  async oninit({ state }) {
    const accountData = getItemLocalStorage('accountData') || {};
    state.users = [];
    state.loading = false;
    state.selectedUser = null;
    state.refresh = () => {
      setTimeout(() => {
        location.reload();
      }, 2000);
    };
    state.handleDelete = async () => {
      try {
        state.loading = true;
        deleteUser.hide();
        const {
          respmsg,
          respcode,
        } = await apirequest(`/ClientController/userDeleteAction`, 'POST', {
            USERNAME: accountData.USERNAME,
            CLIUSERNAME: state.selectedUser.username,
        });

        if (respcode === 0) {
          state.refresh();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        state.loading = false;
      } catch (error) {
        console.log('error', error);
        state.loading = false;
      }
    };
    state.handleReset = () => {
      state.selectedUser = null;
      deleteUser.hide();
    };
    const {
      respbody,
    } = await apirequest(`/ClientController/getUserList`, 'POST', {
        USERNAME: accountData.USERNAME,
    });

    if (respbody) {
      state.users = respbody.USERLIST;
    }
    state.loading = false;
    m.redraw();
  },
  // disable due to failures on some browsers
  oncreate() {},
  view({ state }) {
    return [
      m(userManagementModal, { refresh: state.refresh }),
      m(userManagementEditModal),
      state.selectedUser === null ? [] : m(deleteUser, { selectedUser: state.selectedUser, reset: state.handleReset, handleDelete: state.handleDelete }),
      state.loading ? m(loader) : [],
      // <!--begin::Header-->
      m(header, {
        logos: {
                  dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [
                    // {
                    //   label: t().usermanagement.sidebar_labels.profiles,
                    //   icon: "bi bi-bounding-box-circles",
                    //   route: "/profiles"
                    // },
                    {
                      label: t().usermanagement.sidebar_labels.usermanagement,
                      icon: 'bi bi-person-video',
                      route: '/userManagement',
                    },

                  ],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
 class: 'app-content',
id: 'kt_app_content',
style: {
                  'flex-grow': 1,
                  'flex-basis': 'auto',
                },
},
                  m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' },
                    [
                      state.loading ? m(".linear-background") : m(table_data, {
                        title: t().usermanagement.sidebar_labels.usermanagement,
                        actionItems: [
                          m('div', { class: 'd-flex justify-content-end', 'data-kt-customer-table-toolbar': 'base' },
                            [
                              m('button', { class: 'btn btn-primary', onclick: () => userManagementModal.show() },
                                t().user_management.user_management.users_table.add_new_user_button),
                            ]),
                        ],
                        dataConfigs: {
                          sourceEndpoint: '/users',
                          actionItems: [{
                            label: t().usermanagement.management_table_labels.firstname,
                          }, {
                            label: t().journey_plan.table_action_items.advanced_search,
                          }],
                          dataMapping: [{
                            label: t().usermanagement.management_table_labels.firstname,
                            data_key: 'firstname',
                          }, {
                            label: t().usermanagement.management_table_labels.lastname,
                            data_key: 'lastname',
                          }, {
                            label: t().usermanagement.management_table_labels.email,
                            data_key: 'email',
                          }],
                          dataRows: state.users.map(user => ({
                            firstname: user.FIRSTNAME,
                            lastname: user.LASTNAME,
                            email: user.EMAIL,
                            username: user.USERNAME,
                          })),
                          disableView: true,
                          disabeEditing: true,
                        },
                        delete: (data) => {
                          state.selectedUser = data;
                          m.redraw();
                          deleteUser.show();
                        },
                      }),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),

        ]),
        m(footer),
      ]),

    ];
  },
};
