/* eslint-disable no-param-reassign */
import m from 'mithril';

import { t } from '../../translation-files/translator';
import apirequest from '../../utils/api-service';
import { setToLocalStorage } from '../../utils/helper';
import config from '../../config';
import instituationConfig from '../../instituation-config';

const country = instituationConfig[window.country || 'kenya'];
const INSTID = country.ID;

export default {
  oncreate() {
    window.KTComponents.init();
    document.body.className = '';
    document.body.style.backgroundImage = "url('assets/media/auth/bgnew.webp')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundPosition = 'center';
    document.body.style.display = 'flex';
    document.body.style.alignItems = 'flex-end';
    document.body.style.justifyContent = 'center';
    document.querySelector('.app-page').style.display = 'flex';

    // center in page vertically
    document.querySelector('.app-page').style.justifyContent = 'flex-start';
    document.getElementById('kt_app_root').style.paddingRight = '5%';
    document.getElementById('kt_app_root').style.paddingLeft = '5%';
    document.getElementById('kt_app_root').style.paddingTop = '5%';

    const appDiv = document.getElementById('app');
    if (appDiv) {
      // appDiv.style.width = '450px';
      // appDiv.style.minWidth = '450px';
      // appDiv.style.maxWidth = '450px';
    }

    document.body.style.backgroundSize = '105vw'; // set background size to 100% of viewport width
    document.body.style.backgroundPosition = 'center -13vw'; // adjust the vertical position of the background image
  },
  async oninit({ state }) {
    state.cleardata = () => {
      localStorage.removeItem('cardStatements');
      localStorage.removeItem('thresholdData');
      localStorage.removeItem('topupStatements');
      localStorage.removeItem('hasAuthenticated');
      localStorage.removeItem('accountData');
      localStorage.removeItem('globalCardType');
      localStorage.removeItem('companyName');
      localStorage.removeItem('statements');
      localStorage.removeItem('miniStatements');
      localStorage.removeItem('cardListData');
      localStorage.removeItem('stationList');
      localStorage.removeItem('stations');
      localStorage.removeItem('globalLimit');
      localStorage.removeItem('dealerMinistatement');
      localStorage.removeItem('paymentMethods');
    };
    state.logos = {
      dark: 'assets/media/logos/bill-logo.svg',
      light: 'assets/media/logos/bill-logo.svg',
    };

    state.form_data = {
      username: '',
      password: '',
    };
    state.loading = true;
    state.error = false;
    state.success = false;
    state.successMessage = '';
    state.errorMessage = '';
    state.customer_types = [];
    state.section = 'login';
    state.customer_type = {};
    state.form_data_valid = false;
    state.form_valid = false;
    state.account_data = {};
    state.quiz_data = {
      npassword: '',
    };
    state.quiz_form_errors = {
      npassword: false,
    };
    state.quiz_form_valid = false;
    state.pass_data = {
      password: '',
    };
    state.questions = [];
    state.onchange = (e) => {
      state.form_data[e.target.name] = e.target.value;
    };
    state.onquizinput = (e) => {
      state.quiz_data[e.target.name] = e.target.value;
      state.handleInputValidations();
    };
    state.handleInputValidations = () => {
      state.quiz_form_errors.npassword = state.quiz_data.npassword.length < 5;
      state.questions.forEach((quiz) => {
        state.quiz_form_errors[quiz.QID] = state.quiz_data[quiz.QID] === '';
      });
      state.quiz_form_valid = !state.quiz_form_errors.npassword
      && state.questions.every(quiz => !state.quiz_form_errors[quiz.QID]);
      m.redraw()
    };

    state.authenticate = async () => {
      try {
          state.loading = true;
          const { respbody, respcode, respmsg } = await apirequest('/UserController/validateUser', 'POST', {
              PASSWD: state.form_data.password,
              USERTYPE: state.customer_type.PRODID,
              USERNAME: state.form_data.username,
          });
          if (respcode === 0) {
              state.account_data = { ...respbody };
              setToLocalStorage('accountData', respbody);
              setToLocalStorage('authenticationTime', new Date().getTime());
              setToLocalStorage('hasAuthenticated', 'true');
              if (respbody.USERTYPE === '@DEALER') {
                m.route.set('/dashboard');
              } else {
                m.route.set('/dashboard');
              }
          } else if (respcode === 2 && respbody) {
            state.questions = respbody.QNLIST;
            state.quiz_data['npassword'] = '';
            
            state.questions.forEach((quiz) => {
              state.quiz_data[quiz.QID] = '';
            });
            state.section = 'set-pin';
            state.userData = respbody;
            state.error = false;
            console.log('state.qd', state.quiz_data)
            state.handleInputValidations()
          } else if (respcode === 3) {
            state.section = 'update-pass';
            state.error = false;
          } else {
              state.errorMessage = respmsg;
              state.error = true;
          }
          state.loading = false;
          m.redraw();
      } catch (error) {
          state.loading = false;
          console.log(error);
          state.errorMessage = 'Something went wrong.';
          state.error = true;
          m.redraw();
      }
    };

    state.initResetPassword = async () => {
      try {
          state.loading = true;
          const { respbody, respcode, respmsg } = await apirequest('/UserController/initResetPassword', 'POST', {
              USERTYPE: '',
              USERNAME: state.form_data.username,
          });
          state.loading = false;
          if (respcode === 0) {
            state.subSection = 'quiz';
            state.questions = respbody.QNLIST;
            state.error = false;
          } else {
              state.errorMessage = respmsg;
              state.error = true;
          }
          m.redraw();
      } catch (error) {
          state.loading = false;
          console.log(error);
      }
    };

    state.setPassword = async () => {
      try {
          state.loading = true;
          const quizes = state.questions.map(quiz => ({
            ANS: state.quiz_data[quiz.QID],
            QUESTION: quiz.QID,
          }));
          const { respcode, respmsg } = await apirequest('/UserController/setPassword', 'POST', {
              PASSWD: state.quiz_data.npassword,
              USERTYPE: state.customer_type.PRODID,
              USERNAME: state.form_data.username,
              SECQNLIST: [...quizes],
          });
          if (respcode === 0) {
            state.success = true;
            state.successMessage = respmsg;
            state.section = 'login';
            state.error = false;
          } else {
            state.errorMessage = respmsg;
            state.error = true;
          }
          state.loading = false;
          m.redraw();
      } catch (error) {
          state.loading = false;
          console.log(error);
      }
    };

    state.validateSecurity = async () => {
      try {
          state.loading = true;
          const quizes = state.questions.map(quiz => ({
            ANSWER: state.quiz_data[quiz.QID],
            QID: quiz.QID,
          }));
          const { respcode, respmsg } = await apirequest('/UserController/validateSecurityAns', 'POST', {
              USERTYPE: '',
              USERNAME: state.form_data.username,
              QNLIST: [...quizes],
          });
          if (respcode === 0) {
            state.success = true;
            state.error = false;
            state.successMessage = respmsg;
            state.section = 'login';
          } else {
            state.errorMessage = respmsg;
            state.error = true;
          }
          state.loading = false;
          m.redraw();
      } catch (error) {
          state.loading = false;
          console.log(error);
      }
    };

    state.updatepassword = async () => {
      try {
          state.loading = true;
          const { respcode, respmsg } = await apirequest('/UserController/updatePassword', 'POST', {
              USERTYPE: state.customer_type.PRODID,
              USERNAME: state.form_data.username,
              NEWPWD: state.pass_data.password,
          });
          if (respcode === 0) {
            state.success = true;
            state.successMessage = respmsg;
            state.section = 'login';
            state.error = false;
          } else {
            state.errorMessage = respmsg;
            state.error = true;
          }
          state.loading = false;
          m.redraw();
      } catch (error) {
          state.loading = false;
          console.log(error);
      }
    };

    state.loadCustomerTypes = async () => {
      try {
          state.loading = true;
          const res = await apirequest('/UserController/getProductTypes', 'get');
          if (res.respbody) {
              const list = res.respbody.PRODLIST;
              state.customer_types = [...list];
              state.customer_type = { ...list[0] };
          }
          state.loading = false;
          m.redraw();
      } catch (error) {
          state.loading = false;
          console.log('error', error);
          m.redraw();
      }
    };

    
    state.cleardata();
    state.loadCustomerTypes();

    m.redraw();
  },
  onremove() {
    document.body.className = '';
    document.body.style.backgroundImage = '';
    document.body.style.backgroundSize = '';
    document.body.style.backgroundRepeat = '';
    document.body.style.backgroundPosition = '';
    document.body.style.display = '';
    document.body.style.alignItems = '';
    document.body.style.justifyContent = '';

    document.querySelector('.app-page').style.display = '';
    document.querySelector('.app-page').style.justifyContent = '';

    document.getElementById('kt_app_root').style.paddingLeft = '';
    document.getElementById('kt_app_root').style.paddingTop = '';

    const appDiv = document.getElementById('app');
    if (appDiv) {
      appDiv.style.width = '';
      appDiv.style.minWidth = '';
      appDiv.style.maxWidth = '';
    }
  },
  view({ state }) {
    return [
      m('div', {
        class: 'd-flex align-items-center justify-content-center flex-column',
      },
      m('div', { class: 'card shadow-sm' },
        [
          m('div', { class: 'card-body' }, [
            // m("iframe", { "src": "http://localhost:3000/kkportal/loginLink.do", "width": "100%", "height": "500", "frameBorder": "0" }),

            m('label', {
              class: 'card-title text-center',
              style: {
                'font-size': '2rem',
                'font-weight': '850',
                display: 'block',
                'text-align': 'center',
              },
            },
            t().auth_login.login.label),
            state.section === 'login' && [
              m('label', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                t().auth_login.login.sublabel)),
                (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('div', { class: 'mb-10' }, [
                m('label', { class: 'required form-label', for: 'exampleFormControlInput1' },
                  t().auth_login.login.account_type.label),
                m('select', {
                  class: 'form-select',
                  'aria-label': 'Select example',
                  onchange: (e) => {
                    state.customer_type = state.customer_types.find(type => type.PRODID === e.target.value);
                    m.redraw();
                  },
                  value: state.customer_type.PRODID,
                },
                [
                  state.customer_types.map(type => m('option', { value: type.PRODID },
                    type.PRODNAME)),
                ]),
              ]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                    m('label', { class: 'required form-label', for: '' },
                      t().auth_login.login.username.label),
                    m('input', {
                      class: 'form-control',
                      type: 'text',
                      name: 'username',
                      required: true,
                      placeholder: t().auth_login.login.username.placeholder,
                      value: state.form_data.username,
                      oninput: e => this.onchange(e),
                    }),
                  ]),
                m('div', { class: 'mb-10' },
                  [
                    m('label', { class: 'required form-label', for: 'exampleFormControlInput1' },
                      t().auth_login.login.password.label),
                    m('input', {
                      class: 'form-control',
                      type: 'password',
                      name: 'password',
                      required: true,
                      placeholder: t().auth_login.login.password.placeholder,
                      value: state.form_data.password,
                      oninput: e => this.onchange(e),
                    }),
                  ]),
                m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8' },
                  [
                    m('a', { class: 'link-primary', href: '/signup' },
                      'Sign Up'),
                    m('button', {
class: 'btn btn-light link-primary',
onclick: () => {
                      state.section = 'forgot-pass';
                      state.subSection = 'username';
                    },
},
                      t().auth_login.login.forgot_pass_text),
                  ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || state.form_data.password === '' || state.form_data.username === '',
                    onclick(e) {
                      e.preventDefault();
                      if (state.form_data.password !== '' && state.form_data.username !== '') {
                        state.authenticate();
                      }
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      t().auth_login.login.login_button_text)]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-4' }, state.errorMessage)]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    t().auth_login.login.quick_actions_text,
                  ]),
                  country?.selfreg?.[window.env] ? m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [

                    m('a', { class: 'link-primary', href: `${country?.selfreg?.[window.env]}`, target: '_blank' },
                      'Get a RUBiS Card'),
                  ]) : [],
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: '/activate-card' },
                      t().auth_login.login.activate_card_text),
                  ]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: `${country.contact}`, target: '_blank' },
                      t().auth_login.login.contact_us_text),
                  ]),

              ]),
            ],
            state.section === 'set-pin' && [
              m('label', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'Set you new password')),
                (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                    m('label', { class: 'required form-label', for: '' },
                      t().auth_login.login.password.label),
                    m('input', {
                      class: 'form-control',
                      type: 'password',
                      name: 'npassword',
                      required: true,
                      autocomplete: "off",
                      placeholder: t().auth_login.login.password.placeholder,
                      value: state.quiz_data.npassword,
                      oninput: e => state.onquizinput(e),
                    }),
                    state.quiz_form_errors.npassword && [
                      m('div', { class: 'text-danger fs-6 py-2' }, 'Password should be at least 5 characters'),
                    ],
                  ]),
                  state.questions?.map(({ QID, QUESTION }) => m('div', { class: 'mb-10' },
                    [
                      m('label', { class: 'required form-label', for: '' },
                        QUESTION),
                      m('input', {
                        class: 'form-control',
                        type: 'text',
                        name: QID,
                        required: true,
                        placeholder: '',
                        value: state.quiz_data[QID],
                        oninput: e => state.onquizinput(e),
                      }),
                      state.quiz_form_errors[QID] && [
                        m('div', { class: 'text-danger fs-6 py-2' }, 'Answer required'),
                      ],
                    ])),

                    m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-3' },
                      [
                        m('button', {
     class: 'btn btn-link link-primary',
    onclick: () => {
                          state.section = 'login';
                        },
    },
                          'Back'),
                      ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || !state.quiz_form_valid,
                    onclick(e) {
                      e.preventDefault();
                      if (state.quiz_form_valid) {
                        state.setPassword();
                      }
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Submit')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-4' }, state.errorMessage)]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    t().auth_login.login.quick_actions_text,
                  ]),
                  country?.selfreg?.[window.env] ? m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [

                    m('a', { class: 'link-primary', href: `${country?.selfreg?.[window.env]}`, target: '_blank' },
                      'Get a RUBiS Card'),
                  ]) : [],
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: '/activate-card' },
                      t().auth_login.login.activate_card_text),
                  ]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: `${country.contact}`, target: '_blank' },
                      t().auth_login.login.contact_us_text),
                  ]),

              ]),
            ],
            state.section === 'update-pass' && [
              m('label', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'Set you new password')),
                (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                    m('label', { class: 'required form-label', for: '' },
                      t().auth_login.login.password.label),
                    m('input', {
                      class: 'form-control',
                      type: 'password',
                      name: 'password',
                      required: true,
                      placeholder: t().auth_login.login.password.placeholder,
                      value: state.pass_data.password,
                      oninput: (e) => { state.pass_data.password = e.target.value; },
                    }),
                    state.pass_data.password.length < 5 && [
                      m('div', { class: 'text-danger fs-6 py-2' }, 'Password should be at least 5 characters'),
                    ],
                  ]),

                  m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-3' },
                    [
                      m('button', {
   class: 'btn btn-link link-primary',
  onclick: () => {
                        state.section = 'login';
                      },
  },
                        'Back'),
                    ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || state.pass_data.password.length < 5,
                    onclick(e) {
                      e.preventDefault();
                      state.updatepassword();
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Submit')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-4' }, state.errorMessage)]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    t().auth_login.login.quick_actions_text,
                  ]),
                  country?.selfreg?.[window.env] ? m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [

                    m('a', { class: 'link-primary', href: `${country?.selfreg?.[window.env]}`, target: '_blank' },
                      'Get a RUBiS Card'),
                  ]) : [],
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: '/activate-card' },
                      t().auth_login.login.activate_card_text),
                  ]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: `${country.contact}`, target: '_blank' },
                      t().auth_login.login.contact_us_text),
                  ]),

              ]),
            ],
            state.section === 'forgot-pass' && [
              m('label', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'Forgot password')),
                (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                state.subSection === 'username' && [

                m('div', { class: 'mb-10' },
                  [
                    m('label', { class: 'required form-label', for: '' },
                      t().auth_login.login.username.label),
                    m('input', {
                      class: 'form-control',
                      type: 'text',
                      name: 'username',
                      required: true,
                      placeholder: t().auth_login.login.username.placeholder,
                      value: state.form_data.username,
                      oninput: e => this.onchange(e),
                    }),
                  ]),
                ],
                state.subSection === 'quiz' && state.questions.map(({ QID, QNAME }) => m('div', { class: 'mb-2' },
                  [
                    m('label', { class: 'required form-label', for: '' },
                      QNAME),
                    m('input', {
                      class: 'form-control',
                      type: 'text',
                      name: QID,
                      required: true,
                      placeholder: '',
                      value: state.quiz_data[QID],
                      oninput: e => state.onquizinput(e),
                    }),
                  ])),

                  m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-3' },
                    [
                      m('button', {
   class: 'btn btn-link link-primary',
  onclick: () => {
                        state.section = 'login';
                      },
  },
                        [m('i', { class: 'bi bi-arrow-left text-primary' }), 'Back']),
                    ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading,
                    onclick(e) {
                      e.preventDefault();
                      if (state.subSection === 'username') {
                        state.initResetPassword();
                      } else {
                        state.validateSecurity();
                      }
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Submit')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-4' }, state.errorMessage)]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    t().auth_login.login.quick_actions_text,
                  ]),
                  country?.selfreg?.[window.env] ? m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [

                    m('a', { class: 'link-primary', href: `${country?.selfreg?.[window.env]}`, target: '_blank' },
                      'Get a RUBiS Card'),
                  ]) : [],
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: '/activate-card' },
                      t().auth_login.login.activate_card_text),
                  ]),
                m('div', { class: 'text-gray-500 text-center fw-semibold fs-6' },
                  [
                    m('a', { class: 'link-primary', href: `${country.contact}`, target: '_blank' },
                      t().auth_login.login.contact_us_text),
                  ]),

              ]),
            ],
          ]),

        ])),
    ];
  },
};
