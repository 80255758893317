import m from 'mithril';

const modal = {
  key: 'SUCCESS_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.loading = false;
  },
  view() {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    m('h1.modal-title', ''),
                  ]),
                m('.modal-body',
                  [

                    m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                        m('h4', { class: 'fw-bold py-2 text-success' }, 'Transfer processed successfully.'),
                        m('div', { class: 'text-success mt-3' }, [
                          m('i', { class: 'fa-regular fa-circle-check text-success fw-bold', style: 'font-size: 6rem;' }),
                        ]),
                      ]),
                  ]),
              ]),
          ]),
      ]);
  },
};


export default modal;
