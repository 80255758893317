/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import m from 'mithril';

import { t } from '../translation-files/translator';
import { getPagination } from '../utils/helper';

export default {
    oninit: ({ state, attrs }) => {
        state.size = attrs?.dataConfigs?.dataRows?.length || 0;
        state.pageSize = 100;
        state.rows = attrs?.dataConfigs?.dataRows || [];
        state.pages = Array.from({ length: Math.ceil((state.size || state.pageSize) / state.pageSize) }, (_, index) => index + 1);
        state.pageCount = state.pages.length;
        state.currentPages = [1, 2, 3];
        state.page = 1;
        state.data = state.rows || [];

        state.inputValue = '';

        state.handleNav = (position) => {
            try {
                if (position === 0) {
                    state.page -= 1;
                } else if (position === -1) {
                    state.page += 1;
                } else {
                    state.page = position;
                }
                const start = state.page * state.pageSize - state.pageSize;
                const end = start + state.pageSize < state.size - 1 ? start + state.pageSize : state.size;
                state.data = (state.rows || []).slice(start, end);
                m.redraw();
            } catch (error) {
                console.log("errror>==", error)
            }
        }

        state?.handleNav(state?.page);
    },
    onupdate: ({ state, attrs }) => {
        if (state?.size !== attrs.dataConfigs?.dataRows.length) {
            state.size = attrs.dataConfigs?.dataRows.length;
            state.rows = attrs.dataConfigs?.dataRows;
            state.pages = Array.from({ length: Math.ceil((state.size || state.pageSize) / state.pageSize) }, (_, index) => index + 1);
            console.log('about tot onupdate')
           state?.handleNav(1);
        }
    },
    view({ attrs, state }) {
        return [
            m('div', {
 class: 'modal fade', id: 'kt_customers_export_modal', tabindex: '-1', 'aria-hidden': 'true', style: { display: 'none' },
},
                m('div', { class: 'modal-dialog modal-dialog-centered mw-650px' },
                    m('div', { class: 'modal-content' },
                        [
                            m('div', { class: 'modal-header' },
                                [
                                    m('h2', { class: 'fw-bold' },
                                        'Export Customers'),
                                    m('div', { class: 'btn btn-icon btn-sm btn-active-icon-primary', id: 'kt_customers_export_close' },
                                        m('i', { class: 'ki-duotone ki-cross fs-1' },
                                            [
                                                m('span', { class: 'path1' }),
                                                m('span', { class: 'path2' }),
                                            ])),
                                ]),
                            m('div', { class: 'modal-body scroll-y mx-5 mx-xl-15 my-7' },
                                m('form', { class: 'form fv-plugins-bootstrap5 fv-plugins-framework', id: 'kt_customers_export_form', action: '#' })),
                        ]))),
            m('div', {
                class: 'card',
                style: {
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    // transform: 'translateX(0%)', // initial translate to the right
                },
            },
                //     id: 'table-id',
                // },
                [

                    m('div', { class: 'card-header border-0 pt-6' }, [
                        [
                            m('div', { class: 'card-title' }, [
                                m('p.fw-bold.text-grey-900.fs-2x.mb-3.w-100', { id: 'table-header' }, attrs.title),
                            ]),
                            attrs.headerItems,
                        ],

                    ]),
                    attrs.actionItems.length == 0 ? [] : m('div', { class: 'card-toolbar px-3 py-2' },
                        [
                            attrs.actionItems,
                        ]),
                    m('div', { class: 'card-body pt-0' }, [
                        attrs.overideHeader == true ? [
                            ...attrs.customHeader,
                        ] : null,
                        m('.table-responsive.table-loading', [
                            // m("div", { "class": "table-loading-message" },
                            //     " Loading... "
                            // ),
                            m('table', { class: 'table align-middle table-row-dashed fs-6 gy-5', id: 'table' },
                                [
                                    m('thead',
                                        m('tr', { class: 'text-start text-gray-800 fw-bold fs-7 text-uppercase gs-0' },
                                            [
                                                attrs.dataConfigs.disableSelection === true ? null : m('th', { class: 'w-10px pe-2' },
                                                    m('div', { class: 'form-check form-check-sm form-check-custom form-check-solid me-3' },
                                                        m('input', {
                                                            class: 'form-check-input', type: 'checkbox', 'data-kt-check': 'true', 'data-kt-check-target': '#kt_customers_table .form-check-input', value: '1', oninput: e => attrs.dataConfigs.onselectall(e),
                                                        }))),
                                                attrs.dataConfigs.dataMapping.map(({ label }) => m('th', { class: 'min-w-10px' }, label)),
                                                attrs.dataConfigs.disableActions === true ? null : m('th', { class: 'text-end min-w-70px' },
                                                    t().journey_plan.plans_table_labels.actions),
                                            ])),
                                    m('tbody', { class: 'fw-semibold text-gray-800' },
                                        attrs?.dataConfigs?.dataRows.length === 0 ? [
                                            m('tr',
                                                m('td', { class: 'dt-empty text-center', colspan: '10' }, 'No table data')),
                                        ]
                                            : state.data?.map(data => m('tr',
                                                [
                                                    attrs.dataConfigs.disableSelection === true ? null : m('td',
                                                        m('div', { class: 'form-check form-check-sm form-check-custom form-check-solid' },
                                                            m('input', {
                                                                class: 'form-check-input', type: 'checkbox', value: '1', oninput: e => attrs.dataConfigs.onselect(e, data),
                                                            }))),
                                                    ...attrs.dataConfigs.dataMapping.map((config) => {
                                                        const { style, render } = config;
                                                        return m('td', { style }, typeof render === 'function' ? render(data) : data[config.data_key]);
                                                    }),
                                                    attrs.dataConfigs.disableActions === true ? null : m('td', { class: 'text-end' },
                                                        m('div', { class: 'btn-group', role: 'group', 'aria-label': 'Basic example' }, [
                                                            attrs.dataConfigs.disableView === true ? null : m('a', {
                                                                class: 'btn btn-sm btn-light btn-flex btn-center btn-active-light-primary',
                                                                onclick(e) {
                                                                    e.preventDefault();
                                                                    attrs.view(data);
                                                                },
                                                            },
                                                                [
                                                                    // "Actions ",
                                                                    m('i', { class: 'bi bi-eye fs-5 ms-1' }),
                                                                ]),

                                                            attrs.dataConfigs.disabeEditing === true ? null : m('a', {
                                                                class: 'btn btn-sm btn-light btn-flex btn-center btn-active-light-primary',
                                                                onclick(e) {
                                                                    e.preventDefault();
                                                                    attrs.edit(data);
                                                                },
                                                            },
                                                                [
                                                                    // "Actions ",
                                                                    m('i', { class: 'bi bi-pen fs-5 ms-1' }),
                                                                ]),

                                                            attrs.dataConfigs.disableDeletion === true ? null : m('a', {
                                                                class: 'btn btn-sm btn-light btn-flex btn-center btn-active-light-primary',
                                                                onclick(e) {
                                                                    e.preventDefault();
                                                                    attrs.delete(data);
                                                                },
                                                            },
                                                                [
                                                                    // "Actions ",
                                                                    m('i', { class: 'bi bi-x fs-3', style: { color: 'red' } }),
                                                                ]),
                                                        ])),
                                                ]))),
                                ]),
                                m('div', {class: 'd-flex flex-column flex-lg-row align-items-center justify-content-between'}, [
                                    m('div', {}, `Page ${state.page} of ${state.pageCount}`),
                                    m('nav', { 'aria-label': 'Table pagination', class: 'd-flex flex-column flex-sm-row justify-content-center my-3 my-lg-0' },[
                                        m('ul', { class: 'pagination mb-3 mb-sm-0' }, [
                                            m('li', { class: 'page-item rounded-start border border-end-0 mx-0' },
                                                m('button', {
                                                     class: 'page-link rounded-0', 'aria-label': 'Previous',
                                                     disabled: state.page === 1,
                                                     onclick: () => state.handleNav(0) },
                                                    m('i', { class: 'fa fa-angles-left', 'aria-hidden': true }))),
                                            ...((state.pages.length > 4 ? state.currentPages : state.pages).map(item =>
                                                m('li', { class: 'page-item rounded-0 border border-start-0 mx-0' },
                                                    m('button', { 
                                                        class: `page-link rounded-0 ${item === state.page && 'active'}`,
                                                        disabled: state.page === item, 
                                                        onclick: () => state.handleNav(item) }, item)))),
                                            ...(state.pages.length > 4 ? [
                                                m('li', { class: 'page-item rounded-0 border border-start-0 mx-0' },
                                                    m('button', { 
                                                        class: `page-link rounded-0 ${[...state.currentPages, state.pageCount].includes(state.page) ? '' : 'active'}`,
                                                        disabled: true }, '...')), 
                                                m('li', { class: 'page-item rounded-0 border border-start-0 mx-0' },
                                                    m('button', { 
                                                        class: `page-link rounded-0 ${state.pageCount === state.page && 'active'}`,
                                                        disabled: state.page === state.pageCount, 
                                                        onclick: () => state.handleNav(state.pageCount) }, state.pageCount)),
                                            ] : []),
                                            m('li', { class: 'page-item rounded-end border border-start-0 mx-0' },
                                                m('button', {
                                                     class: 'page-link rounded-0', 'aria-label': 'Next',
                                                     disabled: state.page === state.pages.length, 
                                                     onclick: () => state.handleNav(-1)
                                                     },
                                                    m('i', { class: 'fa fa-angles-right', 'aria-hidden': true }))),
                                            ]), 
                                            m('div', { class: 'd-none d-sm-block', style: 'height: 35px; width: 1px; background: gray; margin: 0px 20px;' }, ''),
                                            m('ul', { class: 'pagination d-flext', style: 'align-items: center' }, [
                                                m('label', { class: 'fs-4', style: 'padding-right: 10px' }, 'Go to page'),
                                                m('li', {}, [
                                                    m('input', { type: 'number', class: 'border border-secondary', style: 'width: 5rem; height: 35px; background: #fff;', 
                                                        oninput: (e) => { state.inputValue = e.target.value }
                                                     })
                                                ]),
                                                m('li', {}, [
                                                    m('button', { type: 'number', class: 'page-link rounded-0 fs-3 fw-bolder border mx-4', style: '',
                                                        disabled: state.inputValue == '' || state.inputValue < 1 || state.inputValue > state.pageCount,
                                                        onclick: () => state.handleNav(Number(state.inputValue))
                                                     }, 'Go')
                                                ]),
                                        ])
                                    ]
                                    ),
                                ])
                            ]),
                    ]),
                ])];
    },
};
