import m from 'mithril';

const toast = {
  key: 'GLOBAL_TOAST',

  show() {
    $(`#${toast.key}`).show();
  },
  hide() {
    $(`#${toast.key}`).hide();
  },
  oninit() {},
  view({ attrs }) {
    return m('div', { class: 'toast-container position-fixed bottom-0 end-0 p-3' }, [
        m('.toast[role="alert"', {
            id: toast.key, role: 'alert', 'aria-live': 'assertive', 'aria-atomic': 'true',
        },
            [
                m('.toast-header', { class: 'py-3' },
                    [
                        m('i', { class: `fa-solid fa-circle-info ${attrs.type === 1 ? 'text-success' : 'text-danger'}`, style: 'font-size: 2rem;' }),
                        m('strong', { class: 'me-auto px-5 fs-2' }, `${attrs.type === 1 ? 'Success' : 'Error'}`),
                        m('button', {
 class: 'btn-close', type: 'button', 'data-bs-dismiss': 'toast', 'aria-label': 'Close',
}),
                    ]),
                m('div', { class: 'toast-body fs-3 py-3' }, `${attrs.message}`),
            ]),
        ]);
  },
};


export default toast;
