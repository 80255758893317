/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';
import header from '../../components/header';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, setToLocalStorage, formatAmount, getCurrentWeek, serializeDealerMinistatement, serializeDealerChartData, generateDatesForWeek } from '../../utils/helper';
import initChart from '../../utils/chart';
import { t } from '../../translation-files/translator';

import footer from '../../components/footer';
import loader from '../../components/loader';

export default {
  oninit: async ({ state }) => {
    const accountData = await getItemLocalStorage('accountData');
    
    if (accountData.USERTYPE !== '@DEALER') {
      m.route.set('/dashboard');
    }
    const stationList = getItemLocalStorage('stationList');
    const statements = getItemLocalStorage('dealerMinistatement');
    state.station = stationList?.[0] || {};
    state.accountData = accountData;
    state.loading = false;
    state.loading_data = false;
    state.labels = []
    state.form_errors = {
      start: false,
      end: false,
    };
    state.active_cards = { count: 0, amount: 0 };
    state.inactive_cards = { count: 0, amount: 0 };
    state.total_cards_balance = 0;
    state.company = '';   
    state.statements = [];
    state.chartData = {};
    state.datasets = [];
    state.selectedCard = null;
    state.error = false;
    state.errorMessage = false;
    state.success = false;
    state.successMessage = '';
    state.handleInputChange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      const labels = generateDatesForWeek(e.target.value)
      state.labels = labels;
      state.fetchdata()
    };

    state.create_el = () => {
      document.getElementById("kt_charts_widget_35_trns")?.remove()
      const node = document.createElement("div");
      node.className = 'min-h-auto h-350px ps-3 pe-6';
      node.id = 'kt_charts_widget_35_trns';

      document.getElementById("chart-cont")?.appendChild(node)
    }

    state.fetchdata = async () => {
      try {
        state.loading_data = true;
        state.create_el();
        m.redraw();
        const {
          respbody,
          respcode
        } = await apirequest(`/DealerController/getMiniStmt`, 'POST', {
            MERCHANTID: accountData.ACCTNO,
            CUST_ID: accountData.CUSTID,
        });
        if (respcode === 0) {
          const txns = respbody?.TXNLIST;
          state.statements = txns;
          if (txns.length > 0) {
            setToLocalStorage('dealerMinistatement', txns);
          }
          console.log(txns);
          const txn_s = serializeDealerMinistatement(txns);
          const datasets = serializeDealerChartData(txn_s, state.labels);
          state.chartData = datasets;
          state.datasets = datasets.datasets
        }
        state.loading_data = false;
        state.updateChart();
        m.redraw()
        return;
      } catch (error) {
        state.loading_data = false;
        console.log(error);
      }
    };

    state.updateChart = () => {
      try {
        // window.KTChartsWidget35.init();
        var chart1 = {
            self: null,
            rendered: false
        };
        const el = document.getElementById('kt_charts_widget_35_trns');
        while (el?.hasChildNodes()) {
          el.removeChild(el.firstChild);
        }
        if (state.statements.length > 0) {
          initChart(chart1, '#kt_charts_widget_35_tab_1', '#kt_charts_widget_35_trns', state.datasets, state.labels, true);
        }
        m.redraw();
      } catch (error) {
        console.log(error);
      }
    }
    
    state.handleSelect = (e) => {
      console.log(e);
      state.datasets = e.target.value === 'all' ? state.chartData.datasets : state.chartData.datasets.filter(card => card.name === e.target.value)
      console.log('datas', state.datasets)
      state.create_el();
      state.updateChart();
      m.redraw();
    }

    state.refresh = () => {
      setTimeout(() => {
        state.success = false;
        state.successMessage = '';
        state.error = false;
        state.errorMessage = ''
        m.redraw()
      }, 3000);
    }; 
    
    state.fetchStations = async () => {
      try {
        state.loading = true;
        const {
          respbody: {
            STATIONLIST,
          },
          respcode,
        } = await apirequest(`/DealerController/getStationDetails`, 'POST', {
          MERCHANTID: state.accountData.CARDNO,
        });
        if (respcode === 0) {
          state.station = STATIONLIST[0] || {};
          setToLocalStorage('companyName', STATIONLIST[0]?.STATIONNAME);
          setToLocalStorage('globalCardType', 'na');
          setToLocalStorage('stationList', STATIONLIST);
          state.fetchdata();
        } else {
          state.errorMessage = respmsg;
          state.error = true;
        }
        state.loading = false;
        m.redraw();
        return;
      } catch (error) {
        state.error = true;
        state.errorMessage = 'Something went wrong'
        throw error;
      }
    };

    if (stationList) {

      state.statements = statements || []
      const txn_s = serializeDealerMinistatement(state.statements);
      const datasets = serializeDealerChartData(txn_s, state.labels);
      state.chartData = datasets;
      state.datasets = datasets.datasets;
      state.updateChart();
      m.redraw()
    } else {
      state.fetchStations();
    }
  },
  oncreate: () => {},
  // disable due to failures on some browsers
  view({ state }) {
    return [
      state.loading ? m(loader) : [],
      // <!--begin::Header-->
      m(header, {
        logos: {
          dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              state.success && m('div', { class: 'text-success text-center fs-5 py-2' }, state.successMessage),
              state.error && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage),
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-md-row' }, [
                // cards here
                [{
                  label: t().dashboard.summary.total_balance,
                  value: `${formatAmount(state?.station?.CARDBALANCE || 0)}`,
                  icon: `assets/media/icons/total-balance.png`,
                }].map(({
                  label, value, value2, icon, isButton
                }) => m('div', {
                  class: 'col col-md-4 pe-2 ps-2 pb-2 pt-2',
                },
                  m('div', {
                    class: 'card card-flush h-md-100',
                    style: {
                      // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)"
                    },
                  },
                    m('div', { class: 'card-header px-5 pt-5 mb-6 d-flex', style: { 'flex-direction': 'column', height: '100%' } },
                      m('div', { class: 'card-title justify-content-between align-items-start' },
                        [
                          m('div', { class: 'd-flex flex-column me-2' }, [
                              m('span', { class: 'fs-6 fw-semibold text-gray-500' },
                                m('b', label)),
                              m('div', { class: 'd-flex align-items-center mb-2 ' },
                                [
                                  m('span', { class: 'fs-1 fw-bold text-gray-800 me-2 lh-1 ls-n2' },
                                    value),
                                ]),
                              value2 && m('div', { class: 'd-flex align-items-center mb-2' },
                                [
                                  m('span', { class: 'fs-3 fw-bold text-gray-800 me-2 lh-1 ls-n2' },
                                    value2),
                                ]),
                              ]),
                        ]),
                      m('div', { class: 'd-flex align-items-center justify-content-between mb-2' },
                          [

                            m('span', { class: 'fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2' },
                              m('img', { class: '', style: 'height: 10rem;', alt: '', src: icon }))
                          ]),
                      )))),
                m('div', {
                  class: 'col col-md-8 pe-2 ps-2 pb-2 pt-2',
                },
                  m('div', {
                    class: 'card card-flush h-md-100',
                    style: {
                      // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)"
                    },
                  },
                    m('div', { class: 'card-header px-5 pt-5 mb-6 d-flex', style: { 'flex-direction': 'column', height: '100%' } },
                      m('div', { class: 'card-title justify-content-between align-items-start' },
                        [
                          m('div', { class: 'd-flex flex-column' }, [
                            m('div', { class: 'fs-1 fw-bold mb-5' }, 'Station details'),
                            m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                              m('div', { class: 'col col-md-8 d-flex pb-2 pt-2' }, [
                                m('label', { class: 'fs-4 fw-bold me-3' }, 'Station Name:'),
                                m('span', { class: 'px-5 fw-light' }, state.station?.STATIONNAME || '')
                              ]),
                              m('div', { class: 'col col-md-8 d-flex pb-2 pt-2' }, [
                                m('label', { class: 'fs-4 fw-bold me-3' }, 'Station Id:'),
                                m('span', { class: 'px-5 fw-light' }, state.station?.STATIONID || '')
                              ])
                            ]),
                            m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                              m('div', { class: 'col col-md-8 d-flex pb-2 pt-2' }, [
                                m('label', { class: 'fs-4 fw-bold me-3' }, 'Card Number:'),
                                m('span', { class: 'px-5 fw-light' }, state.station?.STATIONCARDNUMBER || '')
                              ]),
                              m('div', { class: 'col col-md-8 d-flex pb-2 pt-2' }, [
                                m('label', { class: 'fs-4 fw-bold me-3' }, 'Card Status:'),
                                m('span', { class: 'px-5 fw-light' }, state.station?.CARDSTATUS || '')
                              ])
                            ])
                          ]),
                        ]),
                      )))
                // <!--end::Content-->
              ]),
              m('div', { class: 'separator my-10' }),

              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                m('div', {
                  class: 'col-md-12 col-lg-12 col-sm-12 p-3 p-md-5',
                  style: {
                    // padding: '20px',
                  },
                }, [
                  m('div', {
                    class: 'card card-flush h-md-100',
                    style: {
                      // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
                    },
                  }, [
                    m('div', { class: 'card-header flex-column flex-md-row' }, [
                      m('h2', { class: 'card-title text-center my-4 my-md-0' }, 'Sales Summary Graph'),
                      m('div', { class: 'd-flex flex-column flex-sm-row float-end' }, [
                        m('select', {
                          class: 'form-select me-2',
                          'aria-label': 'Select example 1',
                          style: { margin: '20px' },
                          onchange: (e) => { state.handleSelect(e)}
                        }, [
                          m('option', { value: 'all', disabled: false }, 'Select Product'),
                          // m('option', { value: 'all' }, 'All'),
                          state.chartData?.datasets?.map(card => m('option', { value: card.name }, card.name)),
                        ]),
                        // m('select', {
                        //   class: 'form-select',
                        //   'aria-label': 'Select example 2',
                        //   style: { margin: '20px' },
                        // }, [
                        //   m('option', t().dashboard.diesel),
                        //   m('option', t().dashboard.super),
                        //   m('option', t().dashboard.services),
                        //   m('option', t().dashboard.lubricants),
                        // ]),
                      ]),
                    ]),
                    m('div', { class: 'ms-10 fs-5 fw-bold'}, 'Recent Transactions'),
                    m('div', { class: 'card-body', style: 'padding:5px !important; overflow-x: auto;' }, [
                      m('div', { style: 'width: 100%; min-width: 900px;'}, [
                      state.loading_data ? [
                        m('div', { class: 'py-3 text-center text-success' }, [
                          'Preparing graph data... ',
                          m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2 text-success' }),
                        ])
                      ] : [
                      state.statements?.length === 0 ? 
                       m('div', { class: 'd-flex justify-content-start justify-content-md-center align-items-center px-5', 
                            style: 'height: 100%; width: 100%' }, 'No transaction data found') : [],
                      ],
                      m('div', { id: 'chart-cont'},
                        m('div', { class: 'min-h-auto h-350px ps-3 pe-6', id: 'kt_charts_widget_35_trns', 'data-kt-chart-color': 'primary' }),
                      ),
                    ]),
                    ]),
                  ]),
                ]),
                // m('div', {
                //   class: 'col-md-5 col-lg-5 col-sm-12',
                //   style: {
                //     padding: '20px',
                //   },
                // }, [
                //   m('div', {
                //     class: 'card card-flush h-md-100',
                //     style: {
                //       // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
                //     },
                //   }, [
                //     m('div', { class: 'card-header' }, [
                //       m('h3', { class: 'card-title text-center' }, t().dashboard.product_sales_analysis),
                //       m('div', { class: 'd-flex float-end' }, [
                //         m('select', {
                //           class: 'form-select me-2',
                //           'aria-label': 'Select example 1',
                //           style: { margin: '20px' },
                //         }, [
                //           m('option', { value: '1' }, 'January'),
                //           m('option', { value: '2' }, 'February'),
                //           m('option', { value: '3' }, 'March'),
                //           m('option', { value: '4' }, 'April'),
                //           m('option', { value: '5' }, 'May'),
                //           m('option', { value: '6' }, 'June'),
                //           m('option', { value: '7' }, 'July'),
                //           m('option', { value: '8' }, 'August'),
                //           m('option', { value: '9' }, 'September'),
                //           m('option', { value: '10' }, 'October'),
                //           m('option', { value: '11' }, 'November'),
                //           m('option', { value: '12' }, 'December'),
                //         ]),
                //         m('select', {
                //           class: 'form-select',
                //           'aria-label': 'Select example 2',
                //           style: { margin: '20px' },
                //         }, [
                //           m('option', '2024'),
                //           m('option', '2023'),
                //           m('option', '2022'),
                //           m('option', '2021'),
                //           m('option', '2020'),
                //           m('option', '2019'),
                //           m('option', '2018'),
                //           m('option', '2017'),
                //           m('option', '2016'),
                //           m('option', '2015'),
                //         ]),
                //       ]),
                //     ]),
                //     m('div', { class: 'card-body' }, [
                //       m('div', { class: 'min-h-auto h-450px ps-3 pe-6', id: 'kt_charts_widget_17_chart', 'data-kt-chart-color': 'primary' }),
                //     ]),
                //   ]),
                // ]),

                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
