/* eslint-disable no-param-reassign */
import m from 'mithril';

import { t } from '../../../translation-files/translator';
import apirequest from '../../../utils/api-service';
import { isValidPhoneNumber } from '../../../utils/helper';

const modal = {
  key: 'ACTIVATE_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state, attrs }) {
    state.card = attrs.card;
    state.loading = false;
    state.error = false;
    state.success = false;
    state.successMessage = '';
    state.errorMessage = '';
    state.step = 1;
    state.cardno = '';
    state.form_data_valid = false;
    state.form_valid = false;
    state.form_data = {
      serial: attrs.card.CARD_SERIAL_NUM,
      mobile: '',
      phone: '',
    };
    state.form_pin = {
      pin: '',
      repin: '',
    };
    state.form_errors = {
      serial: false,
      mobile: false,
      phone: false,
    };
    state.form_pin_errors = {
      pin: false,
      repin: false,
    };
    state.otp = '';

    state.onchange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      state.validateOnChange();
    };

    state.validateOnChange = () => {
      state.form_errors = {
        mobile: !isValidPhoneNumber(state.form_data.mobile),
        phone: state.form_data.phone !== state.form_data.mobile,
        serial: state.form_data.serial.length === 0,
      };
      state.form_valid = !state.form_errors.serial && !state.form_errors.phone && !state.mobile;
      m.redraw();
    };
    state.handleInputValidations = () => {
      const pattern = /^\d{4}$/;
      state.form_pin_errors.pin = !pattern.test(state.form_pin.pin);
      state.form_pin_errors.repin = state.form_pin.pin !== state.form_pin.repin;
      state.form_data_valid = !state.form_pin_errors.pin && !state.form_pin_errors.repin;
    };

    state.onpinchange = (e) => {
      state.form_pin[e.target.name] = e.target.value;
      state.handleInputValidations();
    };

    state.initCardActivation = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/initCardActivation`, 'POST', {
          MOBNUMBER: state.form_data.mobile,
          SLNUMBER: state.form_data.serial,
        });
        state.loading = false;

        if (respcode === 0) {
          state.step = 2;
          state.error = false;
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to initialize card activation';
      }
    };

    state.validateCardActivation = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
          respbody,
        } = await apirequest(`/UserController/validateCardActivation`, 'POST', {
          MOBNUMBER: state.form_data.mobile,
          SLNUMBER: state.form_data.serial,
          OTP: state.otp,
        });
        state.loading = false;

        if (respcode === 0) {
          state.cardno = respbody.CARDNUMBER;
          state.step = 3;
          state.error = false;
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to validate OTP';
      }
    };

    state.doCardActivation = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/doCardActivation`, 'POST', {
          MOBNUMBER: state.form_data.mobile,
          SLNUMBER: state.form_data.serial,
          PIN: state.form_pin.pin,
          CARDNUMBER: state.cardno,
        });
        state.loading = false;

        if (respcode === 0) {
          state.success = true;
          state.successMessage = respmsg;
          state.error = false;
          setTimeout(() => {
            attrs.refresh();
            modal.hide();
          }, 2000);
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to activate Card';
      }
    };
    m.redraw();
  },
  view({ state }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', ''),
                    ]),
                  m('.modal-body',
                    [

                        m('div', {
                            class: 'd-flex align-items-center justify-content-center flex-column',
                          },
                          m('div', { class: 'card shadow-sm', style: 'width: 100%;' },
                            [
                              m('div', { class: 'card-body' }, [
                                state.step === 1 && [
                                  m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                                    m('span', {
                                      class: 'text-truncate',
                                      style: {
                                        'text-overflow': 'ellipsis',
                                        overflow: 'hidden',
                                        'white-space': 'wrap',
                                        'max-width': '100px', // adjust the width to change the truncation limit
                                      },
                                    },
                                    'Card Activation')),
                                  (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

                                  m('form', {
                                    class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                                  },
                                  [
                                    m('div', { class: 'mb-10' },
                                      [
                                          m('label', { class: 'required form-label' },
                                          'Serial No'),
                                          m('input', {
                                            class: 'form-control',
                                            type: 'text',
                                            name: 'serial',
                                            required: true,
                                            disabled: true,
                                            placeholder: 'Serial No',
                                            value: state.form_data.serial,
                                            oninput: e => state.onchange(e),
                                          }),
                                          state.form_errors.serial && [
                                            m('div', { class: 'text-danger fs-6 py-2' }, 'Serial No is required'),
                                          ],

                                          m('label', { class: 'required form-label', for: '' },
                                          'Mobile Number'),
                                            m('input', {
                                              class: 'form-control',
                                              type: 'text',
                                              name: 'mobile',
                                              required: true,
                                              placeholder: '254712345678',
                                              value: state.form_data.mobile,
                                              oninput: e => state.onchange(e),
                                            }),
                                            state.form_errors.mobile && [
                                              m('div', { class: 'text-danger fs-6 py-2' }, 'Enter valid Mobile No 254...'),
                                            ],

                                          m('label', { class: 'required form-label', for: '' },
                                          'Confirm Mobile Number'),
                                          m('input', {
                                            class: 'form-control',
                                            type: 'text',
                                            name: 'phone',
                                            required: true,
                                            placeholder: '254712345678',
                                            value: state.form_data.phone,
                                            oninput: e => state.onchange(e),
                                          }),
                                          state.form_errors.phone && [
                                            m('div', { class: 'text-danger fs-6 py-2' }, 'Mobile and Confirm mobile number should match'),
                                          ],
                                      ]),

                                    m('div', { class: 'd-grid mb-5' },
                                      m('button', {
                                        class: 'btn btn-primary',
                                        type: 'submit',
                                        id: 'kt_sign_in_submit',
                                        disabled: state.loading || !state.form_valid,
                                        onclick(e) {
                                          e.preventDefault();
                                          state.initCardActivation();
                                        },
                                      },
                                      [
                                        ...(!state.loading ? [m('span', { class: 'indicator-label' },
                                          'Submit')]
                                        : [m('span', { class: '' },
                                          [
                                            ' Please wait... ',
                                            m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                                          ])]),
                                      ])),
                                    (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

                                  ]),
                                ],
                                state.step === 2 && [
                                  m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                                    m('span', {
                                      class: 'text-truncate',
                                      style: {
                                        'text-overflow': 'ellipsis',
                                        overflow: 'hidden',
                                        'white-space': 'wrap',
                                        'max-width': '100px', // adjust the width to change the truncation limit
                                      },
                                    },
                                    'Card Activation')),
                                  [m('div', { class: 'text-success text-center fs-5 py-4' }, 'An OTP has been sent to your registered mobile number')],

                                  m('form', {
                                    class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                                  },
                                  [
                                    m('div', { class: 'mb-10' },
                                      [
                                        m('input', {
                                          class: 'form-control',
                                          type: 'text',
                                          name: 'otp',
                                          required: true,
                                          placeholder: '',
                                          value: state.otp,
                                          oninput: (e) => { state.otp = e.target.value; },
                                        }),
                                        state.otp.length < 4 && m('div', { class: 'text-danger fs-6' }, 'OTP should be at least 4 digit code'),
                                      ]),
                                    m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8' },
                                      [
                                        m('button', { class: 'btn btn-light link-primary', onclick: () => { state.step = 1; } },
                                          'Back'),
                                      ]),

                                    m('div', { class: 'd-grid mb-5' },
                                      m('button', {
                                        class: 'btn btn-primary',
                                        type: 'submit',
                                        id: 'kt_sign_in_submit',
                                        disabled: state.loading || state.otp.length < 4,
                                        onclick(e) {
                                          e.preventDefault();
                                          state.validateCardActivation();
                                        },
                                      },
                                      [
                                        ...(!state.loading ? [m('span', { class: 'indicator-label' },
                                          'Submit')]
                                        : [m('span', { class: '' },
                                          [
                                            ' Please wait... ',
                                            m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                                          ])]),
                                      ])),
                                    (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

                                  ]),

                                ],
                                state.step === 3 && [
                                  m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                                    m('span', {
                                      class: 'text-truncate',
                                      style: {
                                        'text-overflow': 'ellipsis',
                                        overflow: 'hidden',
                                        'white-space': 'wrap',
                                        'max-width': '100px', // adjust the width to change the truncation limit
                                      },
                                    },
                                    'Card Activation Pin')),
                                  (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

                                  m('form', {
                                    class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                                  },
                                  [
                                    m('div', { class: 'mb-10' },
                                      [
                                          m('label', { class: 'required form-label' },
                                          'Pin'),
                                          m('input', {
                                            class: 'form-control',
                                            type: 'text',
                                            name: 'pin',
                                            required: true,
                                            placeholder: 'Pin',
                                            value: state.form_pin.pin,
                                            oninput: e => state.onpinchange(e),
                                          }),
                                          state.form_pin_errors.pin && [
                                            m('div', { class: 'text-danger fs-6 py-2' }, 'Pin should be a 4 digit code'),
                                          ],

                                          m('label', { class: 'required form-label', for: '' },
                                          'Confirm Pin'),
                                            m('input', {
                                              class: 'form-control',
                                              type: 'text',
                                              name: 'repin',
                                              required: true,
                                              placeholder: 'Re-enter Pin',
                                              value: state.form_pin.repin,
                                              oninput: e => state.onpinchange(e),
                                            }),
                                            state.form_pin_errors.repin && [
                                              m('div', { class: 'text-danger fs-6 py-2' }, 'Pin does not match'),
                                            ],

                                      ]),
                                    m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8' },
                                      [
                                        m('button', { class: 'btn btn-light link-primary', onclick: () => { state.step = 1; } },
                                          'Back'),
                                      ]),

                                    m('div', { class: 'd-grid mb-5' },
                                      m('button', {
                                        class: 'btn btn-primary',
                                        type: 'submit',
                                        id: 'kt_sign_in_submit',
                                        disabled: state.loading || !state.form_data_valid,
                                        onclick(e) {
                                          e.preventDefault();
                                          state.doCardActivation();
                                        },
                                      },
                                      [
                                        ...(!state.loading ? [m('span', { class: 'indicator-label' },
                                          'Submit')]
                                        : [m('span', { class: '' },
                                          [
                                            ' Please wait... ',
                                            m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                                          ])]),
                                      ])),
                                    (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

                                  ]),
                                ],
                              ]),

                            ])),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
