import m from 'mithril';
import config from '../config';

export const headers = () => ({
  auth: localStorage.getItem('token'),
});

export const runQuery = async (query, variables, cache = true) => {
  const cleanQuery = query.replace(/(\r\n\t|\n|\r\t)/gm, "").replace(/ /g, '')

  const gqlQuery = m.request({
    method: 'POST',
    url: `${config[window.env].apiUrl}`,
    headers: headers(),
    data: {
      query: cleanQuery,
      variables
    }
  })

  gqlQuery.then(res => localStorage.setItem(`GQL.${cleanQuery}`, JSON.stringify(res)))

  if (cache) {
    return gqlQuery;
  }

  if (localStorage.getItem(cleanQuery)) {
    return new Promise.resolve(JSON.parse(localStorage.getItem(`GQL.${cleanQuery}`)))
  } else {
    return gqlQuery;
  }
}

export const runMutation = async (query, variables, cache = true) => {
  const cleanQuery = query.replace(/(\r\n\t|\n|\r\t)/gm, "").replace(/ /g, '')

  const gqlQuery = m.request({
    method: 'POST',
    url: `${config[window.env].apiUrl}`,
    headers: headers(),
    data: {
      query: cleanQuery,
      variables
    }
  })

  return gqlQuery;
}