/* eslint-disable no-param-reassign */
import m from 'mithril';
import header from '../../components/header';
import toolbar from '../../components/toolbar';
import sidebar from '../../components/sidebar';
import map from './components/google-maps';
import { getItemLocalStorage } from '../../utils/helper';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';

export default {
  async oninit({ state }) {
    const accountData = getItemLocalStorage('accountData');
    state.accountData = accountData;
    m.redraw();
  },
  // disable due to failures on some browsers
  oncreate() {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const accType = urlParams.get('u');
    //   if (accType === 'new')
    //     introJs().start();
  },
  view({ state }) {
    // console.log({ t: t() })
    return [
      // <!--begin::Header-->
      m(header, {
        logos: {
                  dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [
                  //   ...(state.accountData.USERTYPE === '@CLIENT' ? [{
                  //   label: t().home_acc_info.sidebar_labels.account_into,
                  //   icon: 'bi bi-person-video',
                  //   route: '/home',
                  // }] : []),
                  {
                    label: t().home_acc_info.sidebar_labels.station_finder,
                    icon: 'bi bi-pin-map-fill',
                    route: '/stationFinder',
                  }],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', { class: 'app-content container-xxl', id: 'kt_app_content' },
                  m('div', { class: 'app-container ', id: 'kt_app_content_container' },
                    [
                      m('div', { class: 'col' }, [

                        // Column 2: Google Map
                        m('div', { class: 'col-sm-12 col-md-12 col-lg-12' }, [
                          m(map, {
                            markerSelected: meta => console.log('marker selected', meta),
                            gpsData: [
                              // [-1.3173055786723955, 36.717464597045925]
                            ],
                          }),
                          // m("img", {
                          //   src: "/assets/media/illustrations/station-finder-map.png",
                          //   alt: "Station Finder Map",
                          //   class: "img-fluid",
                          //   style: {
                          //     width: "100%",
                          //     borderRadius: "10px",
                          //     boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"
                          //   }
                          // })
                        ]),
                      ]),

                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
