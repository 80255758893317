/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';

const modal = {
  key: 'SELECT_CARD_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state, attrs }) {
    state.max = moment().format();
    state.ids = attrs.cards.map(({ ENCCARDNUMBER }) => ENCCARDNUMBER);
    state.form_data = {
      start: moment().subtract(6, 'days').format().split('T')[0],
      end: moment().format().split('T')[0],
    };
    state.form_errors = {
      start: false,
      end: false,
    };
    state.selectedCards = [];
    state.is_all = false;
    state.handleInputChange = (e) => {
      state.form_data[e.target.name] = e.target.value;
    };

    state.handelSelection = () => {
      const selectedItems = $('#mySelect2').select2('data');
      state.selectedCards = selectedItems;
      state.is_all = false;
      if (selectedItems.length !== attrs.cards.length) {
        document.getElementById('all').checked = false;
      }
    };

    state.handleCheckbox = (e) => {
        const isChecked = e.target.checked;

        if (isChecked) {
          $('#mySelect2').val([...state.ids]).trigger('change');
        } else {
          $('#mySelect2').val([]).trigger('change');
        }
        const selectedItems = $('#mySelect2').select2('data');
        state.selectedCards = selectedItems;
        m.redraw();
    };
  },
  view({ attrs, state }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', ''),
                    ]),
                  m('.modal-body',
                    [

                        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                            m('form', {
                                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                              },
                              [
                                m('div', { class: 'mb-10' },
                                  [
                                      m('label', { class: 'required form-label' },
                                      'From Date'),
                                      m('input.form-control[type="date"]', {
                                        value: state.form_data.start,
                                        name: 'start',
                                        max: state.max.split('T')[0],
                                        onchange: e => state.handleInputChange(e),
                                      }),

                                      m('label', { class: 'required form-label', for: '' },
                                      'End Date'),
                                        m('input.form-control[type="date"]', {
                                          placeholder: 'select end date',
                                          value: state.form_data.end,
                                          name: 'end',
                                          max: state.max.split('T')[0],
                                          oninput: e => state.handleInputChange(e),
                                        }),

                                          m('label', { class: 'required form-label mt-5', for: '' },
                                          'Select cards'),
                                          m('select',
                                            {
                                              class: 'form-select rounded-0',
                                              'data-control': 'select2',
  id: 'mySelect2',
                                              'data-placeholder': 'Select card(s)',
                                              'data-allow-clear': 'false',
                                              multiple: 'multiple',
                                              onchange: () => {
                                                state.handelSelection();
                                              },
                                            }, [
                                            m('option'),
                                            attrs.cards.map(card => m('option', { value: card.ENCCARDNUMBER }, `${card.CARDNUMBER} - ${card.CARDNAME}`)),
                                            // m('option', { value: '2' }, 'Option 2'),
                                          ]),
                                          m('div', { class: 'form-check form-check-sm form-check-custom form-check-solid me-3 mt-2' },
                                            m('input', {
                                                class: 'form-check-input me-2', id: 'all', type: 'checkbox', 'data-kt-check': 'true', 'data-kt-check-target': '#kt_customers_table .form-check-input', value: '1', oninput: e => state.handleCheckbox(e),
                                            }), 'Select all cards'),
                                  ]),

                              ]),
                            m('br'),
                            m('div', { class: 'd-flex flex-column flex-sm-row' }, [
                              m('button.btn.btn-light flex-fill mx-2', {
                                onclick() {
                                  attrs.refresh();
                                },
                              }, 'Cancel'),
                              m('button.btn.btn-primary flex-fill mx-2', {
                                disabled: state.selectedCards.length === 0,
                                onclick() {
                                  attrs.handleAction({
                                    start: state.form_data.start,
                                    end: state.form_data.end,
                                    cards: state.selectedCards,
                                  });
                                },
                              }, 'Generate'),

                            ]),
                          ]),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
