/* eslint-disable no-param-reassign */
import m from 'mithril';
import header from '../../components/header';
import sidebar from '../../components/sidebar';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, setToLocalStorage } from '../../utils/helper';

export default {
  async oninit({ state }) {
    const accountData = getItemLocalStorage('accountData') || {};
    console.log('account', accountData)
    state.accountDetails = accountData;
    state.users = [];
    const {
      respbody
    } = await apirequest(`/ClientController/getCardList`, 'POST', {
      ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
    });

    if (respbody) {
      setToLocalStorage('globalCardType', respbody.GLOBALCARDTYPE);
      state.accountDetails = respbody
    }
    state.fetchUsers = async () => {
      try {

        const {
          respbody,
        } = await apirequest(`/ClientController/getUserList`, 'POST', {
          USERNAME: accountData.USERNAME,
        });

        if (respbody) {
          state.users = respbody.USERLIST;
        }

        state.loading = false;
        // m.redraw();
      } catch (error) {
        console.log('error', error)
      }
    }
    state.fetchUsers();
    m.redraw();
  },
  // disable due to failures on some browsers
  oncreate() { },
  view({ state, attrs }) {
    // console.log({t:t()})
    return [
      // <!--begin::Header-->
      m(header, {
        logos: {
          dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [{
                    label: t().home_acc_info.sidebar_labels.account_into,
                    icon: 'bi bi-person-video',
                    svg: `<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 4.5C3.75 2.15279 5.65279 0.25 8 0.25C10.3472 0.25 12.25 2.15279 12.25 4.5C12.25 6.84721 10.3472 8.75 8 8.75C5.65279 8.75 3.75 6.84721 3.75 4.5ZM8 1.75C6.48122 1.75 5.25 2.98122 5.25 4.5C5.25 6.01878 6.48122 7.25 8 7.25C9.51878 7.25 10.75 6.01878 10.75 4.5C10.75 2.98122 9.51878 1.75 8 1.75Z" fill="black"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 11.75C2.75736 11.75 1.75 12.7574 1.75 14V15.1883C1.75 15.2064 1.76311 15.2218 1.78097 15.2247C5.89972 15.8972 10.1003 15.8972 14.219 15.2247C14.2369 15.2218 14.25 15.2064 14.25 15.1883V14C14.25 12.7574 13.2426 11.75 12 11.75H11.6591C11.6328 11.75 11.6066 11.7542 11.5815 11.7623L10.716 12.045C8.95119 12.6212 7.04881 12.6212 5.28398 12.045L4.41847 11.7623C4.39342 11.7542 4.36722 11.75 4.34087 11.75H4ZM0.25 14C0.25 11.9289 1.92893 10.25 4 10.25H4.34087C4.52536 10.25 4.70869 10.2792 4.88407 10.3364L5.74959 10.6191C7.21187 11.0965 8.78813 11.0965 10.2504 10.6191L11.1159 10.3364C11.2913 10.2792 11.4746 10.25 11.6591 10.25H12C14.0711 10.25 15.75 11.9289 15.75 14V15.1883C15.75 15.9415 15.2041 16.5837 14.4607 16.7051C10.1819 17.4037 5.8181 17.4037 1.53927 16.7051C0.795884 16.5837 0.25 15.9415 0.25 15.1883V14Z" fill="black"/>
                      </svg>
                    `,
                    route: '/home',
                  },
                  {
                    label: t().home_acc_info.sidebar_labels.station_finder,
                    icon: 'bi bi-pin-map-fill',
                    svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75Z" fill="#94A3B8"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45641 8.12724C5.73017 4.80603 8.50555 2.25 11.838 2.25H12.162C15.4945 2.25 18.2698 4.80603 18.5436 8.12724C18.6903 9.90751 18.1404 11.6753 17.0097 13.0581L13.4149 17.4545C12.6836 18.3488 11.3164 18.3488 10.5851 17.4545L6.99032 13.0581C5.85958 11.6752 5.30967 9.90751 5.45641 8.12724ZM11.838 3.75C9.28621 3.75 7.16097 5.70726 6.95134 8.25046C6.83652 9.64343 7.2668 11.0266 8.15154 12.1086L11.7463 16.505C11.8774 16.6653 12.1226 16.6653 12.2537 16.505L15.8485 12.1086C16.7332 11.0266 17.1635 9.64343 17.0487 8.25046C16.839 5.70726 14.7138 3.75 12.162 3.75H11.838Z" fill="#94A3B8"/>
                    <path d="M7.67082 16.3354C7.85606 15.9649 7.70589 15.5144 7.33541 15.3292C6.96493 15.1439 6.51442 15.2941 6.32918 15.6646L4.32918 19.6646C4.21293 19.8971 4.22536 20.1732 4.36201 20.3943C4.49867 20.6154 4.74007 20.75 5 20.75H19C19.2599 20.75 19.5013 20.6154 19.638 20.3943C19.7746 20.1732 19.7871 19.8971 19.6708 19.6646L17.6708 15.6646C17.4856 15.2941 17.0351 15.1439 16.6646 15.3292C16.2941 15.5144 16.1439 15.9649 16.3292 16.3354L17.7865 19.25H6.21353L7.67082 16.3354Z" fill="#94A3B8"/>
                    </svg>
                    `,
                    route: '/stationFinder',
                  }],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
                  class: 'app-content',
                  id: 'kt_app_content',
                  style: {
                    'flex-grow': 1,
                    'flex-basis': 'auto',
                  },
                },
                  m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' },
                    [
                      m('div', { class: 'row' }, [
                        m('div', { class: 'col-sm-12 col-md-4 col-lg-4' }, [
                          m('div', { class: 'card' }, [
                            m('div', { class: 'card-header' },
                              m('h3', { class: 'card-title' }, t().home_acc_info.info_cards_labels.account_info)),

                            m('div', { class: 'card-body pt-0' }, [

                              m('div', { class: 'table-responsive' },
                                m('table', { class: 'table table-row-dashed table-row-gray-300' },
                                  [
                                    m('tbody',
                                      [
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_info_labels.account_name)),
                                            m('td', state.accountDetails?.COMPANY || ''),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_info_labels.account_number)),
                                            m('td', state.accountDetails?.COMPANY_NO),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_info_labels.registration_number)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_info_labels.pin)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_info_labels.phone_number)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_info_labels.email)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_info_labels.nature_of_business)),
                                            m('td', 'NA'),
                                          ]),
                                      ]),
                                  ])),
                            ]),
                          ]),
                        ]),
                        m('div', { class: 'col-sm-12 col-md-8 col-lg-8' }, [
                          // content for col 8 here
                          m('div', { class: 'card' }, [
                            m('div', { class: 'card-header' },
                              m('h3', { class: 'card-title' }, t().home_acc_info.info_cards_labels.contact_persons)),
                            m('div', { class: 'card-body pt-0' }, [
                              m('div', { class: 'table-responsive' },
                                m('table', { class: 'table table-row-dashed table-row-gray-300' }, [
                                  m('thead', [
                                    m('tr', [
                                      m('th', { style: { whiteSpace: 'nowrap' } }, m('b', 'First Name')),
                                      m('th', { style: { whiteSpace: 'nowrap' } }, m('b', 'Last Name')),
                                      m('th', { style: { whiteSpace: 'nowrap' } }, m('b', t().home_acc_info.contact_person_table_headers.email)),
                                    ]),
                                  ]),
                                  m('tbody', [
                                    state.users.map(user => m('tr', {}, [
                                      m('td', {}, `${user.FIRSTNAME}`),
                                      m('td', {}, `${user.LASTNAME}`),
                                      m('td', {}, `${user.EMAIL}`),
                                    ]))
                                  ]),
                                ])),

                            ]),
                          ]),
                        ]),
                      ]),
                      m('div', { class: 'separator my-10' }),

                      m('div', { class: 'row' }, [
                        m('div', { class: 'col-sm-12 col-md-4 col-lg-4' }, [
                          // content for col 4 here
                          m('div', { class: 'card' }, [
                            m('div', { class: 'card-header' },
                              m('h3', { class: 'card-title' }, 'Account Setup')),

                            m('div', { class: 'card-body pt-0' }, [

                              m('div', { class: 'table-responsive' },
                                m('table', { class: 'table table-row-dashed table-row-gray-300' },
                                  [
                                    m('tbody',
                                      [
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_setup_labels.account_type)),
                                            m('td', state.accountDetails?.GLOBALCARDTYPE === '@POST' ? 'PostPaid' : 'PrePaid'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_setup_labels.date_created)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_setup_labels.sms_alerts)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_setup_labels.security_type)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_setup_labels.expiry_date)),
                                            m('td', 'NA'),
                                          ]),
                                        m('tr',
                                          [
                                            m('td', m('b', t().home_acc_info.account_setup_labels.security_amount)),
                                            m('td', 'NA'),
                                          ]),
                                      ]),
                                  ])),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
