/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';

const modal = {
  key: 'SELECT_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit({ state }) {
    state.max = moment().format();
    state.form_data = {
      start: moment().subtract(6, 'days').format().split('T')[0],
      end: moment().format().split('T')[0],
    };
    state.form_errors = {
      start: false,
      end: false,
    };
    state.selectedCards = [];
    state.handleInputChange = (e) => {
      state.form_data[e.target.name] = e.target.value;
    };

    state.handelSelection = () => {
      const selectedItems = $('#mySelect2').select2('data');
      state.selectedCards = selectedItems;
    }
  },
  view({ attrs, state }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', ''),
                    ]),
                  m('.modal-body',
                    [

                        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                            m('form', {
                                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
                              },
                              [
                                m('div', { class: 'mb-10' },
                                  [
                                      m('label', { class: 'required form-label' },
                                      'From Date'),
                                      m('input.form-control[type="date"]', {
                                        value: state.form_data.start,
                                        name: 'start',
                                        max: state.max.split('T')[0],
                                        onchange: e => state.handleInputChange(e),
                                      }),

                                      m('label', { class: 'required form-label', for: '' },
                                      'End Date'),
                                        m('input.form-control[type="date"]', {
                                          placeholder: 'select end date',
                                          value: state.form_data.end,
                                          name: 'end',
                                          max: state.max.split('T')[0],
                                          oninput: e => state.handleInputChange(e),
                                        }),
                                  ]),

                              ]),
                            m('br'),
                            m('div', { class: 'd-flex flex-column flex-sm-row' }, [
                              m('button.btn.btn-light flex-fill mx-2', {
                                onclick() {
                                  attrs.refresh();
                                },
                              }, 'Cancel'),
                              m('button.btn.btn-primary flex-fill mx-2', {
                                onclick() {
                                  attrs.handleAction({
                                    start: state.form_data.start,
                                    end: state.form_data.end,
                                    cards: state.selectedCards,
                                  });
                                },
                              }, 'Generate'),

                            ]),
                          ]),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
