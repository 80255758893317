/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';
import header from '../../components/header';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, getCurrentWeek, setToLocalStorage, formatAmount, serializeTransactions, serializeChartData, serializeMinistatement, generateDateArray, generateDatesForWeek, hasResource, getCardStrings } from '../../utils/helper';
import initChart from '../../utils/chart';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import loader from '../../components/loader';
import thresholdModal from './modals/threshold';

export default {
  oninit: async ({ state }) => {
    const accountData = await getItemLocalStorage('accountData');
    
    if (accountData.USERTYPE === '@DEALER') {
      m.route.set('/dealer-dashboard');
    }
    const cardListData = await getItemLocalStorage('cardListData');
    const statements = await getItemLocalStorage('miniStatements');
    const thresholdData = await getItemLocalStorage('thresholdData');
    console.log('thresholdData', thresholdData)
    state.accountData = accountData;
    state.loading = false;
    state.max = moment().format('YYYY-MM');
    state.form_data = {
      start: moment().subtract(30, 'days').format().split('T')[0],
      end: moment().format().split('T')[0],
    };
    // state.labels = generateDatesForMonth(moment().format('YYYY-MM'))
    state.labels = generateDateArray(state.form_data.start, state.form_data.end)
    state.form_errors = {
      start: false,
      end: false,
    };
    state.cards = [];
    state.SEL_CARDNOLIST = [];
    state.selectedCards = [];
    state.active_cards = { count: 0, amount: 0 };
    state.inactive_cards = { count: 0, amount: 0 };
    state.total_cards_balance = 0;
    state.company = '';
    state.statements = [];
    state.loading_data = true;
    state.chartData = {};
    state.datasets = [];
    state.selectedCard = null;
    state.error = false;
    state.errorMessage = false;
    state.success = false;
    state.successMessage = '';
    state.thresholdEmail = thresholdData ? thresholdData.email || '' : '';
    state.thresholdAmount = thresholdData ? thresholdData.amount || '0' : '0';
    state.below_threshould_amount = 0;
    state.showThreshold = false;
    state.timer = undefined;
    state.handleInputChange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      const labels = generateDatesForWeek(e.target.value)
      state.labels = labels;
      state.fetchTopups()
    };

    state.fetchTopups = async () => {
      try {
        state.loading_data = true;
        
        state.create_el();
        m.redraw();
        const {
          respbody,
          respcode
        } = await apirequest(`/CardController/detailedstmt`, 'POST', {
          CARDNO: accountData.USERTYPE === '@CLIENT' ? accountData.CARDNO : accountData.CUSTID,
          CUSTID: accountData.USERTYPE === '@CLIENT' ? accountData.ACCTNO : accountData.CUSTID,
          FROMDATE: state.labels[0],
          TODATE: state.labels[state.labels.length - 1],
        });
        if (respcode === 0) {
          const txns = respbody?.STATMENTS?.filter(({ TXN_TYPE, OPERATION_TYPE }) => OPERATION_TYPE === 'DR' && TXN_TYPE.toLowerCase().includes('sale'));
          state.statements = txns;
          setToLocalStorage('statements', respbody?.STATMENTS)
          const datasets = serializeTransactions(txns, state.labels);
          state.chartData = datasets;
          state.datasets = datasets.datasets.length > 5 ? datasets.datasets.slice(0, 1) : datasets.datasets;
        }
        state.updateChart();
        return;
      } catch (error) {
        console.log(error);
      }
    };

    state.fetchMiniStatement = async () => {
      try {
        state.loading_data = true;
        
        state.create_el();
        m.redraw();
        const {
          respbody,
          respcode
        } = await apirequest(`/ClientController/ministmtnw`, 'POST', {
          CUST_ID: accountData.CUSTID,
          ...(state.SEL_CARDNOLIST.length > 0 ? {
            SEL_CARDNOLIST: state.SEL_CARDNOLIST
          } : {})
          
        });
        if (respcode === 0) {
          const txns = respbody?.STATMENTS;
          state.statements = txns;
          setToLocalStorage('miniStatements', respbody?.STATMENTS)
          const txns_s = serializeMinistatement(txns);
          const datasets = serializeChartData(txns_s, state.labels);
          state.chartData = datasets;
          state.datasets = datasets.datasets
        }
        state.updateChart();
        return;
      } catch (error) {
        console.log(error);
      }
    };

    state.updateChart = () => {
      try {
        // window.KTChartsWidget35.init();
        var chart1 = {
          self: null,
          rendered: false
        };
        state.loading_data = false;
        const el = document.getElementById('kt_charts_widget_35_trns');
        while (el?.hasChildNodes()) {
          el.removeChild(el.firstChild);
        }
        if (state.statements.length > 0) {
          initChart(chart1, '#kt_charts_widget_35_tab_1', '#kt_charts_widget_35_trns', state.datasets, state.labels, true);
        }
        m.redraw();
      } catch (error) {
        console.log(error);
      }
    }

    state.handleSelect = (e) => {
      state.datasets = e.target.value === 'all' ? state.chartData.datasets : state.chartData.datasets.filter(card => card.name === e.target.value)
      state.updateChart();
    }

    state.create_el = () => {
      document.getElementById("kt_charts_widget_35_trns")?.remove()
      const node = document.createElement("div");
      node.className = 'min-h-auto h-350px ps-3 pe-6';
      node.id = 'kt_charts_widget_35_trns';

      document.getElementById("chart-cont")?.appendChild(node)
    }

    state.updateState = () => {
      state.showThreshold = true;
      const { CARDLIST, CARD_LIST, COMPANY } = cardListData;
      let activeCards = (CARDLIST || CARD_LIST).filter(item => item.STATUS === '07');
      state.cards = CARDLIST || CARD_LIST || [];
      activeCards = {
        amount: activeCards.filter(item => !isNaN(Number(item.BALANCE || item.AVLBAL))).map(item => Number(item.BALANCE || item.AVLBAL)).reduce((a, b) => a + b, 0),
        count: activeCards.length,
      };

      let inactiveCards = (CARDLIST || CARD_LIST).filter(item => item.STATUS !== '07' && item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
      inactiveCards = {
        amount: inactiveCards.filter(item => !isNaN(Number(item.BALANCE || item.AVLBAL))).map(item => Number(item.BALANCE || item.AVLBAL)).reduce((a, b) => a + b, 0),
        count: inactiveCards.length,
      };

      let belowThresholdCards = CARDLIST?.filter(item => Number(item.BALANCE) < Number(state.thresholdAmount)) || [];

      const totalCardsBalance = (CARDLIST || CARD_LIST).filter(item => !isNaN(Number(item.BALANCE || item.AVLBAL))).map(item => Number(item.BALANCE || item.AVLBAL)).reduce((a, b) => a + b, 0);

      state.active_cards = activeCards;
      state.inactive_cards = inactiveCards;
      state.total_cards_balance = totalCardsBalance;
      state.below_threshould_amount = belowThresholdCards.length || 0;
      state.company = COMPANY;
    };
    if (cardListData) {
      state.updateState();
    }

    state.fetchdata = async () => {

      try {
        if (!cardListData) state.loading = true;

        const {
          respbody: {
            CARDLIST,
            COMPANY,
            GLOBALCARDTYPE,
            GLOBALLIMIT,
          },
          respcode,
          respmsg
        } = await apirequest(`/ClientController/getCardList`, 'POST', {
          ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });
        const {
          respcode: respcodeT,
          respbody: { THRESHOULD_AMT, EMAIL },
        } = await apirequest(`/ClientController/getThreshold`, 'POST', {
          ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });
        if (respcode == 0 && respcodeT == 0) {
          setToLocalStorage('companyName', COMPANY);
          setToLocalStorage('globalCardType', GLOBALCARDTYPE);
          setToLocalStorage('globalLimit', GLOBALLIMIT);
          setToLocalStorage('cardListData', { CARDLIST });
          state.thresholdEmail = !EMAIL || EMAIL == 'NA' ? '' : EMAIL;
          state.showThreshold = true;
          state.thresholdAmount = isNaN(THRESHOULD_AMT) ? 0 : THRESHOULD_AMT;
          setToLocalStorage('thresholdData', { amount: state.thresholdAmount, email: state.thresholdEmail })
          state.cards = CARDLIST || [];
    
          let activeCards = CARDLIST.filter(item => item.STATUS === '07');
          activeCards = {
            amount: activeCards.filter(item => !isNaN(Number(item.BALANCE))).map(item => Number(item.BALANCE)).reduce((a, b) => a + b, 0),
            count: activeCards.length,
          };
    
          let inactiveCards = CARDLIST.filter(item => item.STATUS !== '07' && item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
          inactiveCards = {
            amount: inactiveCards.filter(item => !isNaN(Number(item.BALANCE))).map(item => Number(item.BALANCE)).reduce((a, b) => a + b, 0),
            count: inactiveCards.length,
          };
    
          let belowThresholdCards = CARDLIST.filter(item => Number(item.BALANCE) < Number(state.thresholdAmount));
          // belowThresholdCards = belowThresholdCards.map(item => Number(item.BALANCE)).reduce((a, b) => a + b, 0);
    
          const totalCardsBalance = CARDLIST.filter(item => !isNaN(Number(item.BALANCE))).map(item => Number(item.BALANCE)).reduce((a, b) => a + b, 0);
    
          state.active_cards = activeCards;
          state.inactive_cards = inactiveCards;
          state.total_cards_balance = totalCardsBalance;
          state.company = COMPANY;
          state.below_threshould_amount = belowThresholdCards.length || 0;
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }

        state.loading = false;
        m.redraw();
      } catch (error) {
        state.loading = false;
        m.redraw();
      }

    };
    state.fetchIndividualData = async () => {
      try {
        if (!cardListData) state.loading = true;
        const {
          respbody,
          respcode,
          respmsg,
        } = await apirequest(`/CardController/getCardDetails`, 'POST', {
          ACCTNO: accountData.ACCTNO,
          CARDNO: accountData.CARDNO,
          CUSTID: accountData.CUSTID,
        });
        if (respcode === 0) {
          setToLocalStorage('companyName', accountData.USERNAME);
          setToLocalStorage('globalCardType', respbody.CARD_TYPE);
          setToLocalStorage('cardListData', respbody);
          state.cards = respbody.CARD_LIST || [];
          let activeCards = respbody.CARD_LIST.filter(item => item.STATUS === '07');
          activeCards = {
            amount: activeCards.filter(item => !isNaN(Number(item.BALANCE || item.AVLBAL))).map(item => Number(item.BALANCE || item.AVLBAL)).reduce((a, b) => a + b, 0),
            count: activeCards.length,
          };

          let inactiveCards = respbody.CARD_LIST.filter(item => item.STATUS !== '07' && item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
          inactiveCards = {
            amount: inactiveCards.filter(item => !isNaN(Number(item.BALANCE || item.AVLBAL))).map(item => Number(item.BALANCE || item.AVLBAL)).reduce((a, b) => a + b, 0),
            count: inactiveCards.length,
          };

          const totalCardsBalance = respbody.CARD_LIST.filter(item => !isNaN(Number(item.BALANCE || item.AVLBAL))).map(item => Number(item.BALANCE || item.AVLBAL)).reduce((a, b) => a + b, 0);

          state.active_cards = activeCards;
          state.inactive_cards = inactiveCards;
          state.total_cards_balance = totalCardsBalance;
        } else {
          state.errorMessage = respmsg;
          state.error = true;
        }
        state.loading = false;
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.errorMessage = 'Server error';
        state.error = true;
        m.redraw();
      }
    };

    state.debounce = (func, timeout = 500) => {
      return function (...args) {
        if(state.timer ) clearTimeout(state.timer);

        state.timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }

    state.refreshThreshold = async () => {
      try {
        const {
          respcode,
          respbody: { THRESHOULD_AMT, EMAIL },
        } = await apirequest(`/ClientController/getThreshold`, 'POST', {
          ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });

        if (respcode === 0) {
          setToLocalStorage('thresholdData', { amount: THRESHOULD_AMT, email: EMAIL })
          
          state.thresholdAmount = isNaN(THRESHOULD_AMT) ? 0 : THRESHOULD_AMT;
          let belowThresholdCards = state.cards?.filter(item => Number(item.BALANCE) < Number(THRESHOULD_AMT));
          state.below_threshould_amount = belowThresholdCards.length || 0;
          m.redraw();
        } 
      } catch (error) {
        
      }
    }

    state.setThreshold = async ({ email, amount }) => {
      try {
        state.loading = true;
        thresholdModal.hide();
        const {
          respcode, respmsg,
        } = await apirequest(`/ClientController/addThreshold`, 'POST', {
          ACCTNO: accountData.ACCTNO,
          CARDNO: accountData.CARDNO,
          USERCODE: accountData.USERNAME,
          EMAIL: email,
          THRESHOULD_AMT: amount,
        });
        state.loading = false;
        if (respcode === 0) {
          state.success = true;
          state.successMessage = 'Threshold set successfully';
          state.error = false;
          state.refreshThreshold();
          state.refresh();
          m.redraw();
        } else {
          state.errorMessage = respmsg;
          state.error = true;
          state.success = false;
          state.refresh();
          m.redraw();
        }
      } catch (err) {
        console.log('err', err)
        state.loading = false;
        state.errorMessage = 'Something went wrong.';
        state.error = true;
        state.success = false;
        m.redraw();
      }
    };
    state.refresh = () => {
      thresholdModal.hide();
      setTimeout(() => {
        state.success = false;
        state.successMessage = '';
        state.error = false;
        state.errorMessage = ''
        m.redraw()
      }, 3000);
    };

    state.handelCardSelection = () => {
      const selectedItems = $('#mySelect2').select2('data');
      state.selectedCards = selectedItems;
      state.SEL_CARDNOLIST = getCardStrings(selectedItems);
      const debouncedFetch = state.debounce(() => {
        state.fetchMiniStatement();
      }, 4000)
      debouncedFetch();
    };

    if (accountData.USERTYPE === '@CLIENT' && !cardListData) {
      state.fetchdata();
    }
    if (accountData.USERTYPE === '@CARD' && !cardListData) {
      state.fetchIndividualData();
    }

    if (!statements) {
      // state.SEL_CARDNOLIST = getCardStrings(state.cards.length > 4 ? state.cards.slice(0, 4) : state.cards);
      state.fetchMiniStatement();
    } else {
      const txns = statements;
      state.statements = txns;
      const txns_s = serializeMinistatement(txns);
      const datasets = serializeChartData(txns_s, state.labels);
      state.chartData = datasets;
      state.datasets = datasets.datasets;
      state.updateChart();
    }

    // if (statements) {
    //   const txns = statements.filter(({ TXN_TYPE, OPERATION_TYPE }) => OPERATION_TYPE === 'DR' && TXN_TYPE.toLowerCase().includes('sale'));
    //   state.statements = txns;
    //   const datasets = serializeTransactions(txns, state.labels);
    //   state.chartData = datasets;
    //   state.datasets = datasets.datasets.length > 5 ? datasets.datasets.slice(0, 1) : datasets.datasets;
    //   state.updateChart();
    // } else {
    //   state.fetchTopups();
    // }
  },
  oncreate: () => { },
  // disable due to failures on some browsers
  view({ state }) {
    return [
      !state.showThreshold ? [] : [m(thresholdModal, { email: state.thresholdEmail, amount: state.thresholdAmount, refresh: state.refresh, handleAction: state.setThreshold })],
      state.loading ? m(loader) : [],
      // <!--begin::Header-->
      m(header, {
        logos: {
          dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              state.success && m('div', { class: 'text-success text-center fs-5 py-2' }, state.successMessage),
              state.error && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage),
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // cards here
                [{
                  label: t().dashboard.summary.active_cards,
                  value: `${state.active_cards?.count || 0} Cards`,
                  value2: `${formatAmount(state.active_cards?.amount || 0)}`,
                  icon: `assets/media/icons/active-cards.png`,
                }, {
                  label: t().dashboard.summary.inactive_cards,
                  value: `${state.inactive_cards?.count || 0} Cards`,
                  value2: `${formatAmount(state.inactive_cards?.amount || 0)}`,
                  icon: `assets/media/icons/inactive-cards.png`,
                }, {
                  label: t().dashboard.summary.total_balance,
                  value: `${formatAmount(state.total_cards_balance || 0)}`,
                  icon: `assets/media/icons/total-balance.png`,
                },
                ...(hasResource('CLI_ADD_THRESOLD') ? [{
                  label: 'Card Balance Threshold',
                  value: `${state.below_threshould_amount || 0} Cards`,
                  value2: `${formatAmount(state.thresholdAmount || 0)}`,
                  isButton: true,
                  icon: `assets/media/icons/threshold.png`,
                }] : [])].map(({
                  label, value, value2, icon, isButton
                }) => m('div', {
                  class: 'col pe-2 ps-2 pb-2 pt-2',
                },
                  m('div', {
                    class: 'card card-flush h-md-100',
                    style: {
                      // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)"
                    },
                  },
                    m('div', { class: 'card-header px-5 pt-5 mb-6 d-flex', style: { 'flex-direction': 'column', height: '100%' } },
                      m('div', { class: 'card-title justify-content-between align-items-start' },
                        [
                          m('div', { class: 'd-flex flex-column me-2' }, [
                              m('span', { class: 'fs-6 fw-semibold text-gray-500' },
                                m('b', label)),
                              m('div', { class: 'd-flex align-items-center mb-2 ' },
                                [
                                  m('span', { class: 'fs-1 fw-bold text-gray-800 me-2 lh-1 ls-n2' },
                                    value),
                                ]),
                              value2 && m('div', { class: 'd-flex align-items-center mb-2' },
                                [
                                  m('span', { class: 'fs-3 fw-bold text-gray-800 me-2 lh-1 ls-n2' },
                                    value2),
                                ]),
                              ]),
                          isButton ? [m('button', { class: 'btn btn-odometer  shadow', onclick: () => {
                              thresholdModal.show();
                              m.redraw();
                            }
                          }, 'Set Threshold')] : [],
                        ]),
                      m('div', { class: 'd-flex align-items-center justify-content-between mb-2' },
                        [

                          m('span', { class: 'fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2' },
                            m('img', { class: '', style: 'height: 10rem;', alt: '', src: icon }))
                        ]),
                    )
                  )))
                // <!--end::Content-->
              ]),
              m('div', { class: 'separator my-10' }),

              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                m('div', {
                  class: 'col-md-12 col-lg-12 col-sm-12 p-3 p-md-5',
                  style: {
                    // padding: '20px',
                  },
                }, [
                  m('div', {
                    class: 'card card-flush h-md-100',
                    style: {
                      // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
                    },
                  }, [
                    m('div', { class: 'card-header flex-column flex-md-row' }, [
                      m('h3', { class: 'card-title text-center my-4 my-md-0' }, 'Purchase Analysis Graph'),
                      m('div', { class: 'd-flex flex-column flex-sm-row float-end' }, [
                        m('select',
                          {
                            class: 'form-select rounded-0 me-sm-2 ms-sm-2 my-4 my-sm-5',
                            'data-control': 'select2',
id: 'mySelect2',
                            'data-placeholder': 'Select upto 4 cards to compare',
                            'data-allow-clear': 'false',
                            'data-maximum-selection-length': '4',
                            multiple: 'multiple',
                            onchange: () => {
                              state.handelCardSelection();
                            },
                          }, [
                          m('option'),
                          state.cards?.map(card => m('option', { value: card.ENCCARDNUMBER }, `${card.CARDNUMBER} - ${card.CARDNAME}`)),
                          // m('option', { value: '2' }, 'Option 2'),
                        ]),
                        // m('select', {
                        //   class: 'form-select',
                        //   'aria-label': 'Select example 2',
                        //   style: { margin: '20px' },
                        // }, [
                        //   m('option', t().dashboard.diesel),
                        //   m('option', t().dashboard.super),
                        //   m('option', t().dashboard.services),
                        //   m('option', t().dashboard.lubricants),
                        // ]),
                      ]),
                    ]),
                    m('div', { class: 'ms-10 fs-5 fw-bold'}, 'Recent Transactions'),
                    m('div', { class: 'card-body', style: 'padding:5px !important; overflow-x: auto;' }, [
                      m('div', { style: 'width: 100%; min-width: 900px;'}, [
                        state.loading_data ? [
                          m('div', { class: 'py-3 text-center text-success' }, [
                            'Preparing graph data... ',
                            m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2 text-success' }),
                          ])
                        ] : [
                        state.statements?.length == 0 ?
                          m('div', { 
                            class: 'd-flex justify-content-start justify-content-md-center align-items-center px-5', 
                            style: 'height: 100%; width: 100%' 
                          }, 'No transaction data found') : [],
                        ],
                        m('div', { id: 'chart-cont'},
                          m('div', { class: 'min-h-auto h-350px ps-3 pe-6', id: 'kt_charts_widget_35_trns', 'data-kt-chart-color': 'primary' }),),
                      ])
                    ]),
                  ]),
                ]),
                // m('div', {
                //   class: 'col-md-5 col-lg-5 col-sm-12',
                //   style: {
                //     padding: '20px',
                //   },
                // }, [
                //   m('div', {
                //     class: 'card card-flush h-md-100',
                //     style: {
                //       // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
                //     },
                //   }, [
                //     m('div', { class: 'card-header' }, [
                //       m('h3', { class: 'card-title text-center' }, t().dashboard.product_sales_analysis),
                //       m('div', { class: 'd-flex float-end' }, [
                //         m('select', {
                //           class: 'form-select me-2',
                //           'aria-label': 'Select example 1',
                //           style: { margin: '20px' },
                //         }, [
                //           m('option', { value: '1' }, 'January'),
                //           m('option', { value: '2' }, 'February'),
                //           m('option', { value: '3' }, 'March'),
                //           m('option', { value: '4' }, 'April'),
                //           m('option', { value: '5' }, 'May'),
                //           m('option', { value: '6' }, 'June'),
                //           m('option', { value: '7' }, 'July'),
                //           m('option', { value: '8' }, 'August'),
                //           m('option', { value: '9' }, 'September'),
                //           m('option', { value: '10' }, 'October'),
                //           m('option', { value: '11' }, 'November'),
                //           m('option', { value: '12' }, 'December'),
                //         ]),
                //         m('select', {
                //           class: 'form-select',
                //           'aria-label': 'Select example 2',
                //           style: { margin: '20px' },
                //         }, [
                //           m('option', '2024'),
                //           m('option', '2023'),
                //           m('option', '2022'),
                //           m('option', '2021'),
                //           m('option', '2020'),
                //           m('option', '2019'),
                //           m('option', '2018'),
                //           m('option', '2017'),
                //           m('option', '2016'),
                //           m('option', '2015'),
                //         ]),
                //       ]),
                //     ]),
                //     m('div', { class: 'card-body' }, [
                //       m('div', { class: 'min-h-auto h-450px ps-3 pe-6', id: 'kt_charts_widget_17_chart', 'data-kt-chart-color': 'primary' }),
                //     ]),
                //   ]),
                // ]),

                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
