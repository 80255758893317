/* eslint-disable no-param-reassign */
import m from 'mithril';
import header from '../../components/header';
import sidebar from '../../components/sidebar';
import table_data from '../../components/table_data';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import loader from '../../components/loader'
import {
  handleXlsxUpload,
} from '../../utils/helper';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, formatAmount, isValidAmount, downloadSheetFromJson } from '../../utils/helper';
import loadingModal from './modals/loading';
import successModal from './modals/success';
import failModal from './modals/fail';
import toastComponent from '../../components/toast';
import instituationConfig from '../../instituation-config';

const { ID } = instituationConfig[window.country || 'kenya'];

export default {
  async oninit({ state }) {
    state.handleUpload = (e) => {
      handleXlsxUpload(e, state.uploadCallback);
    };
    state.uploadCallback = (data) => {
      console.log(data)
      let list = [];
      for(let i =0; i < data.length; i++) {
        let key = Object.keys(data[i])[0].toLowerCase().includes('amount') ? Object.keys(data[i])[1] : Object.keys(data[i])[0];
        const card = state.childcards.find(item => item.ORGCHN === `${data[i][key]}`);
        if (card){
            list.push(card)
            state.form_data[card.ORGCHN] = Number(data[i]['Amount'] || 0);
            state.formErrors[card.ORGCHN] = !isValidAmount(Number(data[i]['Amount'] || 0))
        }
      }
      if (list.length > 0){
        state.toCards = list;
        state.error = false;
      } else {
        state.error = true;
        state.errorMessage = 'Invalid cards'

      }
      state.calculateNewBalances();
      m.redraw();
    };
    const accountData = getItemLocalStorage('accountData') || {};
    const globalCardType = getItemLocalStorage('globalCardType');
    state.globalCardType = globalCardType || '';
    state.cards = [];
    state.childcards = [];
    state.selectedFromCard = null;
    state.selectedToCard = null;
    state.amount = 0;
    state.toCardNewBalance = 0,
    state.fromCardNewBalance = 0;
    state.form_data = {},
    state.formErrors = {},
    state.toCards = [],
    state.toastType = 1;
    state.toastMessage = '';
    state.loading = true;
    state.is_invalid_amount = false;
    state.handlechange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      // if (e.target.name === 'amount') state.calculateNewBalances()
      state.calculateNewBalances();
      state.validateFormItems()
    }
    state.validateFormItems = () => {
      state.toCards.forEach(card => {
        state.formErrors[card.ORGCHN] = !isValidAmount(state.form_data[card.ORGCHN])
      })
      m.redraw()
    }
    state.calculateNewBalances = () => {
        const toAmount = state.toCards.reduce((acc, card) => {
          const amount = Number(state.form_data[card.ORGCHN])
          return acc + amount;
        }, 0);
        state.toCardNewBalance = toAmount
        state.fromCardNewBalance = Number(state.selectedFromCard?.BALANCE || 0) - toAmount;
        state.in_valid_amount = state.fromCardNewBalance < 0;
        m.redraw();
    }

    state.downloadSample = () => {
      const data = state.childcards.map((card) => ({'Card number': card.ORGCHN, Amount: 0}))
      downloadSheetFromJson(data)
    }

    state.removecard = (card) => {
      state.toCards = state.toCards.filter(item => item.ORGCHN !== card.ORGCHN);
      state.calculateNewBalances()
      m.redraw();
    }

    state.handleSubmit = async () => {
      try {
        loadingModal.show();
        const data = state.toCards.map(card =>( {
          AMOUNT: `${state.form_data[card.ORGCHN]}`,
          TOACCNUM: card.ORGCHN
        }))

        const {
          respmsg,
          respcode
        } = await apirequest(`/ClientController/cardtocardoptbulk`, 'POST', {
          CUSTID: accountData.ACCTNO, 
          CARDNO: state.selectedFromCard.ENCCARDNUMBER,
          USERNAME: accountData.USERNAME,
          DATA: [...data]
        });
        loadingModal.hide();

        if (respcode === 0) {
          state.toastType = 1;
          state.toastMessage = 'Transfer processed successfully.'
          toastComponent.show()
          localStorage.removeItem('cardListData')
          localStorage.removeItem('cardStatements');
          setTimeout(() => {
            m.route.set('/cardToCard');
          }, 3000)
        } else {
          state.toastType = 0;
          state.toastMessage = respmsg
          failModal.show()
        }
        m.redraw();
      } catch (error) {
        console.log(error)
        loadingModal.hide();
        failModal.show()
      }
    }

    state.fetchChildCardList = async () => {
      try {
        
        const {
          respcode,
          respmsg,
          respbody: {
            CARDLIST
          },
        } = await apirequest(`/ClientController/getChildCardList`, 'POST', {
          ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });
        if (respcode === 0) {
          state.childcards = CARDLIST?.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16') || [];
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        state.loading = false;
        m.redraw()
      } catch (error) {
        state.error = true;
        state.errorMessage = 'Something went wrong retrieving child cards'
        state.loading = false;
        m.redraw();
      }

    }

    state.fetchParentCardList = async () => {
      try {
        state.loading = true;
        
        const {
          respcode,
          respmsg,
          respbody: {
            CARDLIST
          },
        } = await apirequest(`/ClientController/getParentCardList`, 'POST', {
          ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO, USERNAME: accountData.USERNAME
        });
        if (respcode === 0) {
          state.cards = CARDLIST?.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16') || [];
          state.selectedFromCard = CARDLIST?.find(item => item.CARDTYPE == 'Mother Card') || null;
          state.fromCardNewBalance = state.selectedFromCard.BALANCE;
          state.calculateNewBalances();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        state.loading = false;
        m.redraw()
      } catch (error) {
        state.error = true;
        state.errorMessage = 'Something went wrong retrieving mother card'
        state.loading = false;
        m.redraw();
      }
    }

    state.fetchParentCardList();
    state.fetchChildCardList();
  },
  // disable due to failures on some browsers
  oncreate() {},
  view({ state, attrs }) {
    return [
      m(loadingModal),
      m(successModal),
      m(failModal, { message: state.toastMessage }),
      m(toastComponent, { type: state.toastType, message: state.toastMessage}),
      // <!--begin::Header-->
      m(header, {
        logos: {
                  dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            m('div', { class: 'separator my-10' }),
            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [
                    ...(state.globalCardType === '@PRE' && ID === 'RUBISKE' ? [{
                    label: t().card_to_card.sidebar_labels.top_up,
                    icon: 'bi bi-book',
                    route: '/funds',
                  }] : []),
                  {
                    label: t().card_to_card.sidebar_labels.card_to_card,
                    icon: 'bi bi-book',
                    route: '/cardToCard',
                    routesToActivate: ['/cardToCard/single', '/cardToCard/bulk'],
                  }],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
                  class: 'app-content',
id: 'kt_app_content',
style: {
                    'flex-grow': 1,
                    'flex-basis': 'auto',
                  },
                },
                  m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' },
                    [
                      m('div', { class: 'col-12' }, [
                        m('div', { class: 'card mb-3' }, [
                          m('div', { class: 'card-header' }, [
                            m('h1', { class: 'card-title' }, 'Card To Card Transaction'),
                          ]),

                          m('div', { class: 'card-body' }, [
                            m('div', { class: 'row' }, [
                              m('div', { class: 'col-sm-6 col-md-6 col-lg-6' }, [
                                m('div', { class: 'd-flex justify-content-start', 'data-kt-customer-table-toolbar': 'base', style: { 'padding-top': '10px' } }, [
                                  m('div', { class: 'form-check form-check-inline' }, [
                                    m('div', { class: 'form-check form-check-custom form-check-solid' },
                                      [
                                        m('input', {
                                          class: 'form-check-input',
type: 'checkbox',
value: '1',
id: 'flexCheckDefault',
onchange: () => {
                                            m.route.set('/cardToCard/single');
                                          },
                                        }),
                                        m('label', { class: 'form-check-label', for: 'flexCheckDefault' },
                                          t().single_card_to_card_complete.toolbar_actions.single),
                                      ]),
                                  ]),
                                  m('div', { class: 'form-check form-check-inline' }, [
                                    m('div', { class: 'form-check form-check-custom form-check-solid' },
                                      [
                                        m('input', {
                                          class: 'form-check-input',
                                          type: 'checkbox',
                                          checked: true,
                                          value: '1',
                                          id: 'flexCheckDefault',
                                          onchange: () => {
                                            m.route.set('/cardToCard/bulk');
                                          },
                                        }),
                                        m('label', { class: 'form-check-label', for: 'flexCheckDefault' },
                                          t().single_card_to_card_complete.toolbar_actions.bulk),
                                      ]),
                                  ]),
                                ]),
                              ]),
                              m('div', { class: 'col-sm-12 col-md-6 col-lg-6' }, [
                                m('div', { class: 'd-flex justify-content-end', 'data-kt-customer-table-toolbar': 'base' },
                                  m('button', { class: 'btn btn-primary', onclick: () => {
                                    m.route.set('/cardToCard');
                                  }
                                  }, [
                                    m('i', { class: 'bi bi-arrow-left' }),
                                    'Back',
                                  ])),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      m('div', { class: 'row d-flex justify-content-center'}, [
                        state.loading ? [
                          m('div', { class: 'py-3 text-center text-success' }, [
                            'Fetching cards... ',
                            m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2 text-success' }),
                          ])
                        ] : [
                          !state.selectedFromCard && !state.loading ? [
                            m('div', { class: 'fs-4 text-center px-2 py-5' }, 'Problem encountered retrieving mother card')
                          ] : [
                          
                          m('div', { class: 'col-md-6' }, [
                            m('div', {
                              class: 'card mb-3',
                            }, [
                              m('div', { class: 'card-body' }, [
                                m('h5', { class: 'card-title' }, 'Parent Card'),
                                m('select', {
                                  class: 'form-select',
  'aria-label': 'Select Card',
  disabled: true,
  onchange: (e) => {
  const card = state.cards.find(item => item.ENCCARDNUMBER === e.target.value);
  state.selectedFromCard = card;
  },
  value: state.selectedFromCard?.ENCCARDNUMBER || null,
                                },
                                  m('option', { value: null, disabled: false }, 'Select card'),
                                  state.cards?.map(card => m('option', { value: card.ENCCARDNUMBER }, `${card.CARDNUMBER}-${card.CARDNAME}`))),
                                !state.selectedFromCard ? null : [
                                  m('div', { class: 'separator my-5' }),
                                  m('div', { class: 'table-responsive' },
                                    m('table', { class: 'table table-row-dashed table-row-gray-300' },
                                      [
                                        m('tbody',
                                          [
                                            m('tr',
                                              [
                                                m('td', m('b', 'Name')),
                                                m('td', state.selectedFromCard.CARDNAME),
                                              ]),
                                            m('tr',
                                              [
                                                m('td', m('b', 'Card Number')),
                                                m('td', state.selectedFromCard.CARDNUMBER),
                                              ]),
                                            m('tr',
                                              [
                                                m('td', m('b', 'Balance')),
                                                m('td', formatAmount(state.selectedFromCard.BALANCE)),
                                              ]),
                                          ]),
                                      ])),
                                      !state.selectedFromCard ? null : [m('div', { class: 'mb-10' },
                                        [
                                          m('label', { class: 'form-label' },
                                            'New Balance'),
                                          m('input', {
          class: 'form-control', type: 'text', placeholder: '40560', value: formatAmount(state.fromCardNewBalance), disabled: true
          }),
          state.fromCardNewBalance < 0 ? m('div', {class: 'text-danger py-2 fs-5'}, 'Amount to be transfered is more than Mother card balance.') : [],
                                          m('div', {
          'data-lastpass-icon-root': '',
          style: {
          position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important',
          },
          }),
                                        ])],
                                ]
                              ]),
                            ]),
                            state.selectedFromCard == null ? [] : m('div', { class: 'shadow d-flex justify-content-between align-items-center bg-white mt-3 px-5' }, [
                              m('form', {
                                class: 'form', action: '#', method: 'post', style: { padding: '10px' },
                              },
                                m('div.form-group.mb-2', [
                                  m('label', 'Upload Sheet file:'),
                                  m('input.form-control[type="file"]', {
                                    name: 'file',
                                    onchange: e => state.handleUpload(e),
                                  }),
                                  state.error && m('div', { class: 'text-danger text-left fs-5 py-2' }, state.errorMessage),
                                ])),
                              m('div', { class: 'card-toolbar' },
                                [
                                  m('div', { class: 'd-flex justify-content-end', 'data-kt-customer-table-toolbar': 'base' },
                                    m('button', {
                                      class: 'btn btn-primary',
                                      onclick: () => {
                                        // const a = document.createElement('a');
                                        // a.href = `assets/files/sample.xls`;
                                        // a.download = 'sample.xls';
                                        // a.click();
                                        state.downloadSample()
                                      },
                                    }, [
                                      m('i', { class: 'bi bi-cloud-download' }),
                                      t().single_card_to_card_complete.toolbar_actions.download_sample,
                                    ])),
                                ]),
                            ]),

                          ]),
                          m('div', { class: 'col-md-6' }, [
                            m('div', {
                              class: 'card mb-3',
                            }, [
                              m('div', { class: 'card-body' }, [
                                m('div', { class: 'fs-3 fw-medium py-2' }, 'To Cards'), [
                                  m('div', { class: '', style: 'overflow-y: auto; max-height: 30rem;' }, [
                                    state.toCards.map(card => m('.row.py-2', [
                                      m('.col-md-1.d-flex.flex-column.justify-content-center.px-3', [
                                        m('button', { 
                                          class: 'btn text-danger d-flex justify-content-center align-items-center',
                                          onclick: () => state.removecard(card)
                                        }, [
                                          m('i', { class: 'bi bi-trash3 text-danger'})
                                        ])
                                      ]),
                                      m('.col-md-7.d-flex.flex-column.justify-content-center.px-3', [
                                        m('div', { class: 'fs-6 d-flex flex-row'}, [
                                          m('label', { class: 'fs-6 fw-bold' }, 'Card No:'),
                                          m('label', { class: 'fs-6 fw-medium px-2' }, card.ORGCHN),
                                        ]),
                                        m('div', { class: 'fs-6 d-flex flex-row'}, [
                                          m('label', { class: 'fs-6 fw-bold' }, 'Card Name:'),
                                          m('label', { class: 'fs-6 fw-medium px-2' }, card.CARDNAME),
                                        ])
                                      ]),
                                      m('.col-md-4', [
                                        m('label', { class: 'form-label' },
                                          'Amount'),
                                        m('input', {
        class: 'form-control', type: 'text', placeholder: '100', name: card.ORGCHN,
        value: state.form_data[card.ORGCHN],
        oninput: (e) => state.handlechange(e)
        }),
        state.formErrors[card.ORGCHN] ? m('div', {class: 'text-danger py-2 fs-5'}, 'Enter valid amount. Should be a number more than 0') : [],
                                      ])
                                    ]))
                                  ])
                                ]
                              ]),
                            ]),

                            state.toCards.length === 0 ? [] : m('div', { class: '' }, [
                              m('button', {
                                class: 'btn w-100',
                                style: { backgroundColor: 'teal', color: 'white' },
                                disabled: state.toCards.some(({ ORGCHN }) => !!state.formErrors[ORGCHN]) || state.fromCardNewBalance < 0,
                                onclick: () => { state.handleSubmit()}
                               },
                                'Complete'),
                            ])
                          ]),
                        ]
                      ]
                      ]),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
